import React, { CSSProperties } from 'react';

import './LineInput.scss';

type Props = {
  onChange(e: any): void;
  placeholder?: string;
  value?: string | number;
  name?: string;
  error?: string;
  type?: 'text' | 'password' | 'number';
  style?: CSSProperties;
  className?: string;
  lineWidth?: string;
  min?: string;
};

const LineInput = ({
  onChange, placeholder, value = '', name, error, type = 'text', style,
  className = '', lineWidth = '180px', min,
}: Props) => (
  <div className="line-input">
    <input
      className={`input ${className} ${error ? 'form-error' : ''}`}
      placeholder={placeholder}
      type={type}
      style={{ ...style, width: lineWidth }}
      value={value}
      name={name}
      onChange={onChange}
      min={(type === 'number' && min) ? min : ''}
    />
    <p className="error-text">{error}</p>
  </div>
);

export default LineInput;
