import Chart from 'chart.js';

Chart.pluginService.register({
  id: 'legendCenter',
  afterUpdate: (chart: any) => {
    if (chart.config.options.plugins.legendCenter) {
      const { helpers } = Chart;
      const centerConfig = chart.config.options.plugins.legendCenter;
      const globalConfig = Chart.defaults.global;
      const { ctx } = chart.chart;

      const fontStyle = helpers.getValueOrDefault(
        centerConfig.fontStyle, globalConfig.defaultFontStyle,
      );
      const fontFamily = helpers.getValueOrDefault(
        centerConfig.fontFamily, globalConfig.defaultFontFamily,
      );
      let fontSize = 0;
      if (centerConfig.fontSize) {
        fontSize = centerConfig.fontSize;
      } else {
        ctx.save();
        fontSize = helpers.getValueOrDefault(centerConfig.minFontSize, 1);
        const maxFontSize = helpers.getValueOrDefault(centerConfig.maxFontSize, 256);
        const maxText = helpers.getValueOrDefault(centerConfig.maxText, centerConfig.primaryText);

        do {
          ctx.font = helpers.fontString(fontSize, fontStyle, fontFamily);
          const textWidth = ctx.measureText(maxText).width;

          // check if it fits, is within configured limits
          // and that we are not simply toggling back and forth
          if (textWidth < chart.innerRadius * 2 && fontSize < maxFontSize) {
            fontSize += 1;
          } else {
            // reverse last step
            fontSize -= 1;
            break;
          }
        }
        // eslint-disable-next-line no-constant-condition
        while (true);
        ctx.restore();
      }

      // save properties
      chart.center = {
        font: helpers.fontString(fontSize, fontStyle, fontFamily),
        fillStyle: helpers.getValueOrDefault(centerConfig.fontColor, globalConfig.defaultFontColor),
      };
    }
  },
  afterDraw: (chart: any) => {
    if (chart.center) {
      const centerConfig = chart.config.options.plugins.legendCenter;
      const { ctx } = chart.chart;
      ctx.save();
      ctx.font = chart.center.font;
      ctx.fillStyle = chart.center.fillStyle;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      const primaryTextCenterX = (chart.chartArea.left + chart.chartArea.right) / 2;
      const primaryTextCenterY = (chart.chartArea.top + chart.chartArea.bottom) / 2.2;
      ctx.fillText(centerConfig.primaryText, primaryTextCenterX, primaryTextCenterY);
      ctx.restore();
    }
  },
});
