/* eslint-disable no-undef */
export default function getCurrentDistCenterURL() {
  // @ts-ignore
  const distCenter = window.location.href
    .replace(/.+?distribution-centers\//g, '')
    .match(/[^/]*/)[0] as string;
  return `/api/v1/distribution-centers/${distCenter}`;
}

export function getCurrentDistCenterSlug() {
  // @ts-ignore
  const distCenter = window.location.href
    .replace(/.+?distribution-centers\//g, '')
    .match(/[^/]*/)[0] as string;
  return distCenter;
}
