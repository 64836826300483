import { fromJS } from 'immutable';

import {
  CLEAR_STATE_FOR_DIST_CENTER,
  SET_SORTERS_KPIS,
  SET_SORTERS_PRESENTATION_TOTALS,
  SET_SORTERS_SELECTION_DETAIL,
  SET_SORTERS_SUMMARY,
  SET_SORTING_GOAL_FOR_HOUR,
  SET_SORTING_HOURLY_GOALS,
  SET_TOTAL_SORTING_FOR_PRESENTATIONS,
} from '../actions/sortingDashboard.actions';

const INITIAL_STATE = fromJS({});

const sortedProductsReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_SORTERS_KPIS:
      return state.update('sortersKPIs', () => fromJS(action.kpis));
    case SET_SORTERS_SUMMARY:
      return state.update('sortersSummary', () => fromJS(action.summary));
    case SET_SORTERS_SELECTION_DETAIL:
      return state.update('sortersSelectionDetail', () => fromJS(action.detail));
    case SET_SORTING_HOURLY_GOALS:
      return state.update('sortingHourlyGoals', () => fromJS(action.payload));
    case SET_SORTING_GOAL_FOR_HOUR:
      return state.updateIn(
        ['sortingHourlyGoals', action.hourIndex],
        () => action.newGoal,
      );
    case SET_SORTERS_PRESENTATION_TOTALS:
      return state.update('sortersPresentationTotals', () => fromJS(action.detail));
    case SET_TOTAL_SORTING_FOR_PRESENTATIONS:
      return state.update('presentationTotals', () => fromJS(action.presentations));
    case CLEAR_STATE_FOR_DIST_CENTER:
      if (state.get('currentDistCenter') !== action.distCenter) {
        return INITIAL_STATE.set('currentDistCenter', action.distCenter);
      }
      return state;
    default:
      return state;
  }
};

export default sortedProductsReducer;
