/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, useEffect, useState, Fragment } from 'react';
import deepClone from 'lodash.clonedeep';
import ContentDivisionHeader from '../../../../components/Layout/ContentDivisionHeader';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import useComponentVisible from '../../../../utils/hooks/useComponentVisible';
import request from '../../../../utils/request';
import './QuotaSize.scss';

let refs: (HTMLDivElement | null)[] = [];
let timmer: NodeJS.Timeout;

type Props = {
  distCenter: string;
}

const QuotaSize: FC<Props> = props => {
  const [focus, setFocus] = useState<number | null>(null);
  const [state, setState] = useState<{
    name: string;
    slug: string;
    palletSizes: number[];
  }[] | null>(null);

  const [palletSizes, setPalletSizes] = useState<number[][]>();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);


  useEffect(() => {
    if (palletSizes && state && !isComponentVisible) {
      if (timmer) {
        clearTimeout(timmer);
      }

      timmer = setTimeout(async () => {
        const presentations: any = [];

        const res = deepClone(state).map((present, index) => {
          present.palletSizes.forEach((palletSize, columm) => {
            const newPalletSizes = [...present.palletSizes];
            const existPres = presentations.find((pre: { slug: string }) => pre.slug === present.slug);
            if (!existPres && present.palletSizes.length < palletSizes[index].length) {
              presentations.push({
                slug: present.slug,
                palletSizes: palletSizes[index],
              });
              present.palletSizes = palletSizes[index];
            } else
            if (palletSizes[index][columm] !== palletSize) {
              if (existPres) {
                existPres.palletSizes[columm] = palletSizes[index][columm];
                present.palletSizes = existPres.palletSizes;
              } else {
                newPalletSizes[columm] = palletSizes[index][columm];
                presentations.push({
                  slug: present.slug,
                  palletSizes: newPalletSizes,
                });
                present.palletSizes = newPalletSizes;
              }
            }

            if (existPres) {
              existPres.palletSizes = existPres.palletSizes.filter((a: number) => !!a);
            } else {
              const last = presentations.length - 1;
              if (presentations[last] && Object.keys(presentations[last]).length) {
                presentations[last].palletSizes = presentations[last].palletSizes.filter((a: number) => !!a);
              }
            }
          });

          present.palletSizes = present.palletSizes.filter((a: number) => !!a);
          return present;
        });

        try {
          await request.patch(`/api/v1/distribution-centers/${props.distCenter}/presentations/pallet-sizes`, { presentations });
          setState(res);
          setPalletSizes(palletSizes.map(palsizes => palsizes.filter((a: number) => !!a)));
        // eslint-disable-next-line no-empty
        } catch (error) {}
      }, 2000);
    } else
    if (timmer) {
      clearTimeout(timmer);
    }
  }, [isComponentVisible]);

  useEffect(() => {
    (async () => {
      const res: {
        presentations:
          {
            name: string;
            slug: string;
            palletSizes: number[];
          }[];
      } = await request.get(`/api/v1/distribution-centers/${props.distCenter}/presentations/pallet-sizes`);
      setState(deepClone(res.presentations));
      setPalletSizes(deepClone(res.presentations).map(presentation => presentation.palletSizes));
    })();
  }, []);


  const setRefs = (ele: HTMLDivElement | null) => {
    if (!ele) {
      refs = [];
      return;
    }
    refs.push(ele);
  };

  const onPresentationFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target) {
      if (Number(e.target.getAttribute('data')) !== focus) {
        setFocus(Number(e.target.getAttribute('data')));
      }
    }
  };

  const onPresentationChange = (presentation: number, columm: number, value: string) => {
    if (value === '' || value === '0' || Number(value)) {
      if (palletSizes) {
        const copyPal = deepClone(palletSizes);
        copyPal[presentation][columm] = Number(value);
        setPalletSizes(copyPal);
      }
    }
  };

  return (
    <>
      <ContentDivisionHeader title="Tamaños de cupos" />
      <div
        className="card limited-width text-smaller quota"
        style={{ padding: 14 }}
        ref={ref}
        onClick={() => setIsComponentVisible(true)}
      >
        {state && palletSizes
          ? state.map((presentation, index) => (
            <Fragment key={presentation.slug}>
              <div
                className={`d-flex flex-row justify-content-between p-2 presentation ${focus === index ? 'focus' : ''}`}
                ref={setRefs}
              >
                <h4>{presentation.name}</h4>
                <div className="d-flex flex-row">
                  <div className="mr-2">
                    <input
                      type="text"
                      className="default-input form-input d-inline small-input-field"
                      onFocus={onPresentationFocus}
                      onBlur={() => setFocus(null)}
                      {...{
                        data: index,
                        value: palletSizes[index][0] || '',
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => onPresentationChange(index, 0, e.target.value),
                      }}
                    />
                    <p className="text-center">opción 1</p>
                  </div>
                  <div>
                    <input
                      type="text"
                      className="default-input form-input d-inline small-input-field"
                      onFocus={onPresentationFocus}
                      onBlur={() => setFocus(null)}
                      {...{
                        data: index,
                        value: palletSizes[index][1] || '',
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => onPresentationChange(index, 1, e.target.value),
                      }}
                    />
                    <p className="text-center">opción 2</p>
                  </div>
                </div>
              </div>
              <span className="breaker" />
            </Fragment>
          ))

          : <LoadingSpinner height={100} />}
      </div>
    </>
  );
};

export default QuotaSize;
