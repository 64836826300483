import { fromJS } from 'immutable';
import {
  SET_EMPLOYEES_COUNT,
  SET_MOST_LEAST_PRODUCTIVE_AGENCIES,
  SET_MOST_LEAST_PRODUCTIVE_WORKERS,
  SET_PRODUCTIVITY_CHART_DATA,
  SET_ZONES,
} from '../actions/corporateHome.actions';

const INITIAL_STATE = fromJS({});

const corporateHomeReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_EMPLOYEES_COUNT:
      return state.update('employeesCount', () => fromJS(action.employeesCount));
    case SET_MOST_LEAST_PRODUCTIVE_AGENCIES:
      return state.update('mostLeastProductiveAgencies', () => fromJS(action.mostLeastProductiveAgencies));
    case SET_MOST_LEAST_PRODUCTIVE_WORKERS:
      return state.update('mostLeastProductiveWorkers', () => fromJS(action.mostLeastProductiveWorkers));
    case SET_PRODUCTIVITY_CHART_DATA:
      return state.update('productivityChartData', () => fromJS(action.productivityChartData));
    case SET_ZONES:
      return state.update('zones', () => fromJS(action.zones));
    default:
      return state;
  }
};

export default corporateHomeReducer;
