/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-shadow */
import React, { FC, useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import './PickingProductivity.scss';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { selectTimeFilterForLocation } from '../../../selectors';
import BenchBarChart from './BenchBarChart';
import PercentLineChart from './PercentLineChart';
import UnderlineButton from '../../../components/Button/UnderlineButton';
import { generateQueryDates } from '../../../actions/corporateAgencies.actions';
import Selection from '../../../components/Select/MultipleSelect';
import request from '../../../utils/request';

const PickingProductivity: FC<Props> = props => {
  const [toggle, setToggle] = useState(false);
  const [subZone, setSubZone] = useState('');
  const [state, setState] = useState<{
    data: any;
    res: any;
    level: string;
    current: { zone: string };
  }>({
    data: null,
    res: null,
    level: 'zone',
    current: {
      zone: '',
    },
  });
  const [filter, setFilter] = useState('');

  const allTrueText = 'Todos los CD’s';
  const selectionOptions = [
    {
      value: 'Sin T2P',
      label: 'Sin T2P',
    },
    {
      value: 'Con T2P',
      label: 'Con T2P',
    },
  ];

  const { data, res, level, current } = state;


  const gotoSubzone = (zoneName: string) => {
    const currentZone: any = Object.values(res.zones).find((zone: any) => zone.name === zoneName);

    const subzone = currentZone.subZones;
    setState({
      ...state,
      level: 'subZone',
      current: {
        zone: zoneName,
      },
      data: subzone,
    });

    // @ts-ignore
    setSubZone(Object.values(subzone).sort((a: any, b: any) => b.progress - a.progress)[0].name);
  };

  const selectSubzone = (subZoneName: string) => {
    setSubZone(subZoneName);
  };

  const changeSelection = (selectedVals: string[]) => {
    if (selectedVals.length === selectionOptions.length) {
      return setFilter('');
    }

    return setFilter(selectedVals.join(','));
  };

  useEffect(() => {
    (async () => {
      setState({
        ...state,
        res: null,
        data: null,
      });

      if (!props.timeFilters) return;
      const queries: any = generateQueryDates(
        props.timeFilters.get('startDate'),
        props.timeFilters.get('endDate'),
      );
      if (filter) {
        queries.clusterType = filter;
      }
      const res = await request.get('/api/v1/corporate/zone-wise-picker-productivity', queries);

      if (!Object.keys(res.zones).length) {
        setSubZone('');
        setState({
          ...state,
          level: 'zone',
          res,
          current: {
            zone: '',
          },
          data: res.zones,
        });
        return;
      }

      if (level === 'zone') {
        setState({
          ...state,
          res,
          data: res.zones,
        });
      } else {
        const tempZone: any = Object.values(res.zones)
          .find((zon: any) => zon.name === current.zone);
        if (tempZone) {
          setState({
            ...state,
            res,
            data: tempZone.subZones,
          });
        } else {
          setSubZone('');
          setState({
            ...state,
            level: 'zone',
            res,
            current: {
              zone: '',
            },
            data: res.zones,
          });
        }
      }
    })();
  }, [props.timeFilters, filter]);

  // @ts-ignore
  const benchmark = !toggle && data && res && (() => {
    if (level === 'zone') {
      return res.benchmark;
    }

    // @ts-ignore
    return (Object.values(res.zones).find((zon: any) => zon.name === current.zone)).benchmark;
  })();

  const chart = useMemo(() => ((state.res && props.timeFilters)
    ? (toggle
      ? <PercentLineChart res={data} onClick={level === 'zone' ? gotoSubzone : selectSubzone} />
      : <BenchBarChart res={data} benchmark={Number(benchmark)} onClick={level === 'zone' ? gotoSubzone : selectSubzone} />
    ) : <LoadingSpinner height={300} />),
  [data, level, toggle]);


  return (
    <div className="card pickerP">
      <div>
        <nav>
          <UnderlineButton
            text={level === 'zone' ? props.appName : current.zone}
            onClick={() => {
              setSubZone('');
              setState({
                ...state,
                level: 'zone',
                current: {
                  zone: '',
                },
                data: res.zones,
              });
            }}
            style={{ fontWeight: 'bold' }}
            className="nav"
            disabled={level !== 'subZone'}
          />
          {level === 'subZone' && (
            <>
                  &gt;
              <UnderlineButton text={`UENs ${current.zone}`} onClick={() => {}} style={{ fontWeight: 'bold' }} className="nav" disabled />
            </>
          )}
        </nav>
        <div style={{ width: '10em' }}>
          <Selection options={selectionOptions} allTrueText={allTrueText} onClose={changeSelection} />
        </div>
      </div>
      <div>
        <li className={!toggle ? 'selected' : ''}><button type="button" onClick={() => setToggle(false)}>Cajas x Picker x Hora</button></li>
        <li className={toggle ? 'selected' : ''}><button type="button" onClick={() => setToggle(true)}>% picking</button></li>
      </div>
      <div className={`card forklift ${toggle ? 'percent' : ''}`}>
        <div>
          {!toggle
            && (
            <div>
              <li><span style={{ backgroundColor: '#7b93ca' }} /> &gt;=</li>
              <li className="bench">BenchMark({Math.round(benchmark || 0)})</li>
              <li>&lt;&nbsp;<span style={{ backgroundColor: '#fb7a47' }} /></li>
            </div>
            )}
        </div>
        {chart}
      </div>

      {res && subZone && (() => {
        const { zone } = current;
        const zoneExist: any = Object.values(res.zones).find((zon: any) => zon.name === zone);

        if (zoneExist) {
          const sub: any = Object.values(zoneExist.subZones).find((dis: any) => dis.name === subZone);

          if (sub) {
            const distros = Object.values(sub.distributionCenters).map((dist: any) => (
              <button
                key={dist.slug}
                type="button"
                className="card"
                onClick={() => {}}
              >
                <h4>{dist.name}</h4>
                <p><span>{toggle ? Math.round(dist.percent || 0) : Math.round(dist.progress || 0)}</span> {toggle ? '% picking' : 'cajas seleccionadas' }</p>
              </button>
            ));

            return (
              <div className="card menus">
                {distros}
              </div>
            );
          }
        }

        return '';
      })()}
    </div>
  );
};

type Props = {
  timeFilters: any;
  appName: string;
};

const mapStateToProps = (state: any) => ({
  timeFilters: selectTimeFilterForLocation(state, 'corporate'),
});

export default connect(mapStateToProps)(PickingProductivity);
