import React, { Component } from 'react';

type Props = {
  width?: string;
  dashed?: boolean;
  style?: any;
};
type State = {};

class Separator extends Component<Props, State> {
  render() {
    const { dashed } = this.props;
    const borderShape = dashed ? 'dashed' : 'solid';

    return (
      <hr style={{
        border: `1px ${borderShape} #ECEFF4`,
        width: this.props.width || '100%',
        ...this.props.style,
      }}
      />
    );
  }
}

export default Separator;
