import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ReactComponent as BoxIcon } from '../../../../assets/icons/box.svg';

import { getTotalSortingForPresentations } from '../../../../actions/sortingDashboard.actions';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import {
  selectHomeCurrentDistCenter,
  selectSortingDashboardPresentationTotals,
} from '../../../../selectors';

class PresentationTotals extends Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.getDataForTimeFilter = this.getDataForTimeFilter.bind(this);
  }

  componentDidMount() {
    if (this.props.timeFilter) return this.getDataForTimeFilter();
    this.props.dispatch(getTotalSortingForPresentations());
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.timeFilter && prevProps.timeFilter !== this.props.timeFilter) {
      this.getDataForTimeFilter();
    }
    if (prevProps.currentDistCenter !== this.props.currentDistCenter) {
      this.getDataForTimeFilter();
    }
  }

  getDataForTimeFilter() {
    this.props.dispatch(getTotalSortingForPresentations(
      this.props.timeFilter.get('startDate'), this.props.timeFilter.get('endDate'),
    ));
  }

  render() {
    const { presentationTotals, productUnit } = this.props;
    const current = productUnit === 'boxes' ? 'currentAmount' : 'currentAmountInPallets';
    const unit = productUnit === 'boxes' ? 'cajas' : 'tarimas';

    return (
      <div className="row">
        {presentationTotals
          ? presentationTotals.map((presentation: any) => (
            <div className="col-sm" key={presentation.get('slug')} style={{ marginTop: 15 }}>
              <div className="card" style={{ marginBottom: 20 }}>
                <p className="text-light" style={{ paddingBottom: 10 }}>
                  {presentation.get('name')}
                </p>
                <div className="col">
                  <div className="row">
                    <BoxIcon style={{ width: 25, height: 25 }} className="card-image" />
                    <p><span className="font-bold">{presentation.get(current).toLocaleString()}</span> {unit}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))
          : (
            <div className="col">
              <div className="card">
                <LoadingSpinner height={59} />
              </div>
            </div>
          )}
      </div>
    );
  }
}

type Props = {
  dispatch: any;
  productUnit: string;
  timeFilter: any;
  // redux
  presentationTotals: any;
  currentDistCenter: string;
};

function mapStateToProps(state: any) {
  return {
    presentationTotals: selectSortingDashboardPresentationTotals(state),
    currentDistCenter: selectHomeCurrentDistCenter(state),
  };
}

export default connect(mapStateToProps)(PresentationTotals);
