import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import { selectTimeFilterForLocation, selectCalendarDropdownValueForLocation } from '../../selectors';
import CustomCalendar from './Calendar';
import NativeCalendar from './NativeCalendar';
import useWindowResizeWidth from '../../utils/hooks/useWindowResizeWidth';
import { FILTER_OPTIONS } from '../../types/types-enums-interfaces';


const CalendarWrapper: FC<Props> = (props: Props) => {
  const screenWidth: number = useWindowResizeWidth();
  const [timeFilters, setTimeFilter] = useState<any>();
  const [timeType, setTimeType] = useState<string>('');
  const Calendar = screenWidth > 778 ? CustomCalendar : NativeCalendar;

  useEffect(() => {
    if (props.timeFilters.get('dataFilter').size) {
      setTimeFilter(selectTimeFilterForLocation(props.timeFilters, props.stateFilterLocation));
      setTimeType(selectCalendarDropdownValueForLocation(props.timeFilters, props.stateFilterLocation));
    }
  }, [props.timeFilters.get('dataFilter')]);

  const calendarProps: any = {};
  if (timeFilters) {
    calendarProps.customFirstDate = timeFilters.get('startDate');
    calendarProps.customSecondDate = timeFilters.get('endDate');
  }
  return (
    <Calendar
      onClose={props.onClose}
      initialState={timeType || props.initialState}
      excludeLastHour={props.excludeLastHour}
      {...calendarProps}
    />

  );
};

type Props = {
  onClose?: any;
  initialState?: FILTER_OPTIONS;
  excludeLastHour?: boolean;
  customFirstDate?: string;
  customSecondDate?: string;
  timeFilters: any;
  stateFilterLocation: string;
};

const mapStateToProps = (state: any) => ({
  timeFilters: fromJS({
    dataFilter: state.get('dataFilter'),
  }),
});

export default connect(mapStateToProps)(CalendarWrapper);
