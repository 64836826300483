import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Dropdown from 'react-dropdown';
import { Collection } from 'immutable';
import { setProductUnit, setSelectedShift, setTimeFilterForPage, setZone } from '../actions/dataFilter.actions';
import Calendar from './Calendar/CalendarWrapper';
import Toggle from './Button/Toggle';
import {
  selectCurrentZoneCorporate,
  selectProductUnitForLocation,
  selectZones,
} from '../selectors';
import { FILTER_OPTIONS, Zone } from '../types/types-enums-interfaces';
import arrow from '../assets/icons/chevron-down.svg';

class DistCenterDashboardFilters extends PureComponent<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
    };
    this.handleTimeFilterChange = this.handleTimeFilterChange.bind(this);
    this.handleZoneChange = this.handleZoneChange.bind(this);
    this.handleShiftChange = this.handleShiftChange.bind(this);
  }

  componentDidMount(): void {
    // set product unit if none
    if (!this.props.productUnit) {
      this.props.dispatch(setProductUnit(this.props.stateFilterLocation,
        'boxes'));
    }
  }

  handleTimeFilterChange(startDate: Date, endDate: Date, dropdownValue: string) {
    // to avoid repeated requests!
    if (startDate.getTime() === this.state.startDate.getTime()
    && endDate.getTime() === this.state.endDate.getTime()) return;

    this.props.dispatch(setTimeFilterForPage(
      { startDate, endDate }, this.props.stateFilterLocation, dropdownValue,
    ));
    // update state
    this.setState({ startDate, endDate });
  }

  handleZoneChange(newZoneSlug: string) {
    if (newZoneSlug === this.props.currentZoneSlug) return;

    const zone = newZoneSlug === 'all' ? '' : newZoneSlug;
    this.props.dispatch(setZone(zone));
  }

  async handleShiftChange(shift: any) {
    await this.props.dispatch(setSelectedShift(shift));
  }

  render() {
    const {
      productUnit,
      stateFilterLocation,
      showCalendar,
      showUnitChoice,
      showPresentationChoice,
      showZones,
      zones,
      currentZoneSlug,
      excludeLastHour,
      selectedShift,
      showNightFilters = false,
    } = this.props;

    const unitHiddenClass = showUnitChoice ? '' : 'hidden-d-none';
    const calendarHiddenClass = showCalendar ? '' : 'hidden-d-none';
    const presentationHiddenClass = showPresentationChoice ? '' : 'hidden-d-none';
    const zonesHiddenClass = showZones ? '' : 'hidden-d-none';

    // number of properties shown
    let i = 0;
    if (showCalendar) i += 1;
    if (showUnitChoice) i += 1;
    if (showPresentationChoice) i += 1;
    if (showZones) i += 1;

    const rowJustify = i === 1 ? 'justify-content-end' : 'justify-content-between';

    return (
      <div className="card dist-center-dashboard-filters">
        <div className={`row flex-column flex-md-row ${rowJustify} align-items-center`}>
          {/* Choose unit (Boxes OR Pallets) */}
          <div
            className={`row col-12 col-sm-auto justify-content-center order-first ${unitHiddenClass}`}
            style={{
              alignItems: 'baseline',
            }}
          >
            <div className="col-auto" style={{ paddingRight: 0 }}>
              <p>viendo:</p>
            </div>
            <div className="col-auto" style={{ marginLeft: -25 }}>
              <Toggle
                options={[{ text: 'cajas', key: 'boxes' }, { text: 'tarimas', key: 'pallets' }]}
                onOptionSelect={(key: string) => this.props.dispatch(setProductUnit(
                  stateFilterLocation,
                  key,
                ))}
                selectedOption={productUnit}
              />
            </div>
          </div>

          {/* Zones */}
          <div
            className={`row col-12 col-sm-auto justify-content-center order-2 ${zonesHiddenClass}`}
          >
            <p className="col filter-description-container">de: </p>
            <Dropdown
              arrowOpen={<img src={arrow} alt="arrow" style={{ transform: 'rotate(180deg)' }} />}
              arrowClosed={<img src={arrow} alt="arrow" />}
              onChange={(option: any) => this.handleZoneChange(option.value)}
              // If you want to unify width of menu regardless of how long choice text is:
              controlClassName="dropdown-main"
              options={[{
                value: 'all',
                label: 'Todas las zonas',
              }].concat((zones && zones.map((zone: any) => (
                {
                  value: zone.get('slug'),
                  label: zone.get('name'),
                }
              )).toJS() as any) || [])}
              value={currentZoneSlug || 'all'}
            />
          </div>

          {/* Presentations */}
          <div
            className={`row col-12 col-sm-auto justify-content-center order-1 ${presentationHiddenClass}`}
          >
            <p className="col filter-description-container">viendo: </p>
            <Dropdown
              arrowOpen={<img src={arrow} alt="arrow" style={{ transform: 'rotate(180deg)' }} />}
              arrowClosed={<img src={arrow} alt="arrow" />}
              // onChange={() => ()}
              // If you want to unify width of menu regardless of how long choice text is:
              controlClassName="dropdown-main"
              options={[
                { value: 'all', label: 'Todos los cupos' },
                { value: 'Media', label: 'Media' },
                { value: 'Mega', label: 'Mega' },
                { value: 'Premium', label: 'Premium' },
                { value: 'Familiar', label: 'Familiar' },
              ]}
              value="all"
            />
          </div>
          { showNightFilters && (
            <Toggle
              options={[{ text: 'Matutino', key: 'morning-g' }, { text: 'Nocturno', key: 'night-g' }]}
              onOptionSelect={this.handleShiftChange}
              selectedOption={selectedShift}
            />
          )}

          {/* Calendar */}
          <div
            className={`row col-12 col-sm-auto justify-content-center order-last ${calendarHiddenClass}`}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div className="col-auto">
              <p>de: </p>
            </div>

            <div className="col-auto" key={this.props.calInitialState}>
              <Calendar
                onClose={(startDate: Date, endDate: Date, dropdownValue: string) => (
                  this.handleTimeFilterChange(
                    startDate,
                    endDate,
                    dropdownValue,
                  )
                )}
                stateFilterLocation={this.props.stateFilterLocation}
                initialState={this.props.calInitialState || FILTER_OPTIONS.lastDay}
                excludeLastHour={excludeLastHour}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

type Props = {
  dispatch?: any;
  stateFilterLocation: string;
  showUnitChoice?: boolean;
  showPresentationChoice?: boolean;
  showZones?: boolean;
  showCalendar?: boolean;
  excludeLastHour?: boolean;
  // store
  productUnit: string;
  zones: Collection<number, Zone>;
  currentZoneSlug: string;
  calInitialState?: FILTER_OPTIONS;
  selectedShift: any;
  showNightFilters?: any;
};

type State = {
  startDate: Date;
  endDate: Date;
};

function mapStateToProps(state: any, ownProps: any) {
  return {
    productUnit: selectProductUnitForLocation(state, ownProps.stateFilterLocation),
    zones: selectZones(state),
    currentZoneSlug: selectCurrentZoneCorporate(state),
    selectedShift: state.getIn(['dataFilter', 'selectedShift']),
  };
}

export default connect(mapStateToProps)(DistCenterDashboardFilters);
