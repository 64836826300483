import React, { PureComponent } from 'react';
import { Route, Switch, NavLink as Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { ReactComponent as LogoutIcon } from '../../assets/icons/log-out.svg';
import MainNavigationHeader from '../../components/MainNavigationHeader/MainNavigationHeader';
import FactoryNavigation from '../../components/FactoryNavigation';
import DistCenterDashboardFilters from '../../components/DistCenterDashboardFilters';
import { selectHomeCurrentDistCenter, selectUserSlug } from '../../selectors';
import CorporateHome from './CorporateHome';
import { getZones } from '../../actions/corporateHome.actions';
import CorporateAgencies from './CorporateAgencies';
import ChartsAnalyticPages from '../Analytics/ChartsComponent/ChartsComponent';
import Analytics from '../Analytics';
import { FILTER_OPTIONS } from '../../types/types-enums-interfaces';

class CorporateWrapper extends PureComponent<Props, any> {
  componentDidMount(): void {
    this.props.dispatch(getZones());
  }

  render() {
    const { match, location } = this.props;


    const configRoutes = [{
      url: '/logout',
      label: (
        /* use span since it's going to be inside of a p tag. */
        <span style={{
          display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
        }}
        >
          Salir
          <LogoutIcon style={{ width: 15 }} />
        </span>
      ),
    }];

    return (
      <>
        {/* top header */}
        <MainNavigationHeader
          configRoutes={configRoutes}
          match={match}
          pathname={this.props.location.pathname}
        />
        {/* nav */}
        <FactoryNavigation location={location} />


        <div className="container main-content-margin" style={{ maxWidth: 1225 }}>
          { location.pathname.includes('analytics') && (
            <Link
              to="/corporate/upload"
              style={{
                color: '#2574FB',
                textAlign: 'center',
                marginBottom: 10,
                paddingBottom: 10,
              }}
            ><p style={{ marginBottom: 10 }}>Cargar archivo</p>
            </Link>
          )}
          {/* filters */}
          <div style={{
            display: (this.props.location.pathname.includes('users')
              || this.props.location.pathname.includes('account')) ? 'none' : 'block',
          }}
          >
            { !location.pathname.includes('upload')
              && (
              <DistCenterDashboardFilters
                showCalendar
                showZones={!location.pathname.match(/analytics/gi)}
                stateFilterLocation="corporate"
                excludeLastHour
                calInitialState={location.pathname.match(/analytics/gi) && FILTER_OPTIONS.lastMonth}
              />
              ) }
          </div>

          <Switch>
            <Route path={match.path} exact component={CorporateHome} />
            <Route path={`${match.path}/agencies`} exact component={CorporateAgencies} />
            <Route path={`${match.path}/analytics`} exact component={ChartsAnalyticPages} />
            <Route path={`${match.path}/upload`} exact component={Analytics} />
            {/* <Route path={`${match.path}/users/:user`} exact component={UserDetail}/> */}
            {/* <Route path={`${match.path}/devices`} exact component={DevicesConfiguration}/> */}
            {/* <Route path={`${match.path}/users`} exact render={(props: any) =>
              <ConfigurationDashboard {...props} distCenter={distCenterSlug}/>}/> */}
            {/* <Route path={`${match.path}/account/:me`} exact component={AccountDetail}/> */}
          </Switch>
        </div>
      </>
    );
  }
}

type Props = {
  dispatch: any;
  match: any;
  location: any;
  // store
  userSlug: string;
  currentDistCenter: string;
};

function mapStateToProps(state: any) {
  return {
    userSlug: selectUserSlug(state),
    currentDistCenter: selectHomeCurrentDistCenter(state),
  };
}

export default connect(mapStateToProps)(CorporateWrapper);
