import React, { Fragment, Component } from 'react';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { Collection } from 'immutable';
import Dropdown from 'react-dropdown';

import ContentDivisionHeader from '../../../components/Layout/ContentDivisionHeader';
import Input from '../../../components/Input';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { selectDistributionCenterInfo, selectWorkersCompensation } from '../../../selectors';
import {
  DistributionCenterInfoMap,
  WorkersCompensationMap,
} from '../../../types/types-enums-interfaces';
import {
  getWorkersCompensation,
  postWorkersCompensation,
} from '../../../actions/configuration.actions';
import Separator from '../../../components/Separator';
import arrow from '../../../assets/icons/chevron-down-blue.svg';
import QuotaSize from './QuotaSize';
import QuotaPresentation from './QuotaPresentation';

import './DistCenterConfiguration.scss';

type Props = {
  dispatch: any;
  // store
  currentDistCenter: DistributionCenterInfoMap;
  workersCompensation: Collection<number, WorkersCompensationMap>;
  match: {
    params: { distCenter: string };
  };
};
type State = {};

class DistCenterConfiguration extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.renderFactorCell = this.renderFactorCell.bind(this);
  }

  componentDidMount(): void {
    this.fetchData();
  }

  fetchData() {
    this.props.dispatch(getWorkersCompensation());
  }

  generateTableColumns(tableData: any, currency: string) {
    const columns = [];

    columns.push({
      id: 'product',
      Header: <h4>Producto</h4>,
      accessor: (data: any) => data.getIn(['presentation', 'name']),
      Cell: (row: any) => <p>{row.value}</p>,
    });

    columns.push({
      id: 'conversionFactor',
      Header: <h4>Factor de Conversión <small style={{ fontSize: 10 }}>({currency})</small></h4>,
      accessor: (data: any) => ({
        slug: data.getIn(['presentation', 'slug']),
        factorPerBox: data.get('factorPerBox'),
      }),
      Cell: this.renderFactorCell,
    });

    return columns;
  }

  renderFactorCell(row: any) {
    return (
      <div>
        <Input
          value={row.value.factorPerBox || ''}
          type="number"
          onChange={(e: any) => this.props.dispatch(postWorkersCompensation(e.target.value,
            row.value.slug))}
          className="small-input-field"
          maxFloat={999.99999}
        />
        <span className="text-smallest"> x caja</span>
      </div>
    );
  }


  render() {
    const { currentDistCenter, workersCompensation } = this.props;
    const currency = currentDistCenter && `${currentDistCenter.get('currency')} ${currentDistCenter.get(
      'currencySymbol',
    )}`;
    const columns: any[] = workersCompensation && currency && this.generateTableColumns(
      workersCompensation,
      currency,
    ) as any;

    const {
      match: {
        params: { distCenter },
      },
    } = this.props;

    return (
      <div className="container dist-center-configuration">
        <h1 className="text-tinted-muted text-smaller ml-1">CONFIGURACIÓN GENERAL</h1>
        {distCenter && <QuotaSize distCenter={distCenter} />}
        {distCenter && <QuotaPresentation distCenter={distCenter} />}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  currentDistCenter: selectDistributionCenterInfo(state),
  workersCompensation: selectWorkersCompensation(state),
});

export default connect(mapStateToProps)(DistCenterConfiguration);


//         {/*  Dia Venta */}
//         <ContentDivisionHeader title="Día Venta" containerStyle={{ marginTop: 2 }} />
//         <div className="card limited-width text-smaller" style={{ padding: 22 }}>
//           <p>
//             1 Día venta equivale a <Input
//             // value={'333'}
//               type="number"
//               onChange={() => false}
//               className="medium-input-field"
//               inactiveBorderColor="#D6E6FE"
//             /> cajas
//           </p>
//         </div>

//         {/*  Variable Compensation */}
//         <ContentDivisionHeader title="Compensación Variable" />
//         <div
//           className={`card limited-width text-smaller ${columns ? '' : 'row align-items-center'}`}
//           style={{ padding: 22, minHeight: 320 }}
//         >
//           {currency && workersCompensation && columns && columns.length
//             ? (
//               <ReactTable
//                 data={workersCompensation as any}
//                 columns={columns}
//                 pageSize={workersCompensation.count()}
//                 showPagination={false}
//                 NoDataComponent={() => null}
//               />
//             )
//             : <LoadingSpinner style={{ margin: 'auto' }} />}
//         </div>

//         {/* Working Hours */}
//         <ContentDivisionHeader title="Horario de Trabajo" />
//         <div className="card limited-width text-smaller" style={{ padding: 14 }}>
//           {['matutino', 'vespertino', 'nocturno'].map((shift: string, i: number, original: any[]) => (
//             <Fragment key={shift}>
//               {/* shift name */}
//               <div className="row align-items-center px-2">
//                 <div className="col-auto">
//                   <h4 className="text-tinted-muted">Turno {shift}</h4>
//                 </div>
//               </div>

//               {/* shift start */}
//               <div className="row justify-content-between align-items-baseline px-2">
//                 <div className="col-4">
//                   <p>Inicio de Turno</p>
//                 </div>

//                 {/* inputs */}
//                 <div className="col-auto">
//                   <div className="row justify-content-center align-items-center">
//                     <Input
//                       maxLength={2}
//                       onChange={() => false}
//                       className="smallest-input-field d-inline"
//                     />
//                     <span>:</span>
//                     <Input
//                       maxLength={2}
//                       onChange={() => false}
//                       className="smallest-input-field d-inline"
//                     />
//                   </div>
//                 </div>

//                 {/* AM : PM */}
//                 <div className="col-auto">
//                   <Dropdown
//                     arrowOpen={(
//                       <img
//                         width={10}
//                         src={arrow}
//                         alt="arrow"
//                         style={{ transform: 'rotate(180deg)' }}
//                       />
// )}
//                     arrowClosed={<img width={10} src={arrow} alt="arrow" />}
//                     onChange={(option: any) => option.value}
//                       // If you want to unify width of menu regardless of how long choice text is:
//                     controlClassName="dropdown-main"
//                     options={[{
//                       value: 'am',
//                       label: 'AM',
//                     },
//                     {
//                       value: 'pm',
//                       label: 'PM',
//                     }]}
//                     value="AM"
//                   />
//                 </div>
//               </div>

//               {/* shift end */}
//               <div className="row justify-content-between align-items-baseline px-2 mt-2">
//                 <div className="col-4">
//                   <p>Final de Turno </p>
//                 </div>

//                 {/* inputs */}
//                 <div className="col-auto">
//                   <div className="row justify-content-center align-items-center">
//                     <Input
//                       maxLength={2}
//                       onChange={() => false}
//                       className="smallest-input-field d-inline"
//                     />
//                     <span>:</span>
//                     <Input
//                       maxLength={2}
//                       onChange={() => false}
//                       className="smallest-input-field d-inline"
//                     />
//                   </div>
//                 </div>

//                 {/* AM : PM */}
//                 <div className="col-auto">
//                   <Dropdown
//                     arrowOpen={(
//                       <img
//                         width={10}
//                         src={arrow}
//                         alt="arrow"
//                         style={{ transform: 'rotate(180deg)' }}
//                       />
// )}
//                     arrowClosed={<img width={10} src={arrow} alt="arrow" />}
//                     onChange={(option: any) => option.value}
//                       // If you want to unify width of menu regardless of how long choice text is:
//                     controlClassName="dropdown-main"
//                     options={[{
//                       value: 'am',
//                       label: 'AM',
//                     },
//                     {
//                       value: 'pm',
//                       label: 'PM',
//                     }]}
//                     value="AM"
//                   />
//                 </div>
//               </div>

//               {(i !== original.length - 1)
//                 && <Separator style={{ marginTop: 12, marginBottom: 12 }} />}
//             </Fragment>
//           ))}
//         </div>
