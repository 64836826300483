import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import AuthWrapper from './pages/AuthWrapper';
import TokenAccess from './pages/TokenAccess';
import DistCenterWrapper from './pages/DistCenterWrapper';
import Register from './pages/Register';
import Login from './pages/Login';
import Logout from './pages/Logout';
import ResetPassword from './pages/ResetPassword';
import CorporateReportMailChart from './pages/CorporateReportMailChart';
import CorporateWrapper from './pages/CorporateWrapper';

export default () => (
  <Router>
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/access/:token" component={TokenAccess} />
      <Route path="/register/:token" component={Register} />
      <Route path="/logout" exact component={Logout} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/render/reports" component={CorporateReportMailChart} />
      <AuthWrapper path="/distribution-centers/:distCenter" component={DistCenterWrapper} />
      <AuthWrapper path="/corporate" component={CorporateWrapper} />
      <AuthWrapper path="/" />
    </Switch>
  </Router>
);
