import { fromJS } from 'immutable';

import { SET_DISTRIBUTION_CENTER_INFO } from '../actions/distributionCenter.actions';

const INITIAL_STATE = fromJS({});

const distributionCenterReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_DISTRIBUTION_CENTER_INFO:
      return fromJS(action.info || {});
    default:
      return state;
  }
};

export default distributionCenterReducer;
