import React, { CSSProperties, ClassicElement, useState } from 'react';

import { ReactComponent as ChevronDownBlueIcon } from '../../../assets/icons/chevron-down-blue.svg';

import OnClickDropdown from '../OnClickDropdown';

import './SelectDropdown.scss';

type Props = {
  name: string;
  value: string;
  children: ClassicElement<any>;
  dropdownContent: Array<{ value?: string | number; label: string | ClassicElement<any> }>;
  onChange(e: any): void;
  placeholder?: string;
  className?: string;
  style?: CSSProperties;
  dropdownElementsWidth?: any;
  selectWidth?: number;
  highlightSelected?: boolean;
  dropdownArrow?: boolean;
  selectUI?: boolean;
  dropdownContentHeight?: string | number; // Specifies the height of the
  // dropdown content and adds a scrollbar for the elements overflow (each element is aprox 50px).
  repositioningValue?: number; // This prop is applied to the dropdown content
  // when it is out of viewPort. The default is bottom: 42
  // (the 42 value can be customized with this prop).
};

const SelectDropdown = ({
  name, // Name of the select
  value,
  children,
  dropdownContent,
  onChange, // Function that executes when one of the select options is clicked
  placeholder, // Text that will appear on the Select when there is no selected value
  className, // Classname for the OnClickDropdown className prop
  style, // Style for the OnClickDropdown style prop
  dropdownElementsWidth,
  selectWidth, // Width of the OnClickDropdown children prop
  highlightSelected = false, // When set to true, displays a blue dot
  // on the Select option that is currently the Select value
  dropdownArrow = false,
  selectUI = true,
  dropdownContentHeight,
  repositioningValue,
}: Props) => {
  const [currentValue, setCurrentValue] = useState(dropdownContent
    .find((el: any) => el.value === value));
  const createEvent = (optionName: any, optionValue: any, selectName: string, e: any) => {
    setCurrentValue({ label: optionName, value: optionValue });
    // Because we are not using default select element, the event lacks a name and a value.
    // We add them here.
    e.target.name = selectName;
    e.target.value = optionValue;
    onChange(e);
  };
  return (
    <OnClickDropdown
      dropdownContent={
        (
          <div>
            {dropdownContent.map((element: any) => (
              <div
                className="option"
                key={element.value}
                role="button"
                onClick={(e: any) => createEvent(element.label, element.value, name, e)}
              >
                <p>{element.label}</p>
                {highlightSelected && (element.value === value)
                  ? (
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        backgroundColor: '#2574FB',
                        borderRadius: 50,
                      }}
                    />
                  )
                  : ''}
              </div>
            ))}
          </div>
        )
      }
      className={className}
      style={style}
      dropdownElementsWidth={dropdownElementsWidth}
      dropdownArrow={dropdownArrow}
      dropdownContentHeight={dropdownContentHeight}
      repositioningValue={repositioningValue}
    >
      {selectUI
        ? (
          <div
            className="select"
            style={{ minWidth: `${selectWidth}px`, color: value === '' ? '#A6A6A6' : '#000' }}
          >
            <p>{currentValue ? currentValue.label : placeholder}</p>
            <ChevronDownBlueIcon style={{ width: 10, height: 7 }} />
          </div>
        )
        : children}
    </OnClickDropdown>
  );
};

export default SelectDropdown;
