import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ContentDivisionHeader from '../../../components/Layout/ContentDivisionHeader';
import Toggle from '../../../components/Button/Toggle';
import SortersHourlySelectionMatrix from './SortersHourlySelectionMatrix';
import SortersHourlySelectionChart from './SortersHourlySelectionChart';
import SortersPresentationTable from './SortersPresentationTable';
import SortersKPITable from './SortersKPITable';
import SortersSummary from './SortersSummary';
import PresentationTotals from './PresentationTotals';
import { selectProductUnitForLocation, selectTimeFilterForLocation } from '../../../selectors';
import { clearStateForDistCenter } from '../../../actions/sortingDashboard.actions';

class SortingDashboard extends PureComponent<Props, any> {
  hourlySelectionContent = {
    matrix: 'matrix',
    chart: 'chart',
  };

  constructor(props: any) {
    super(props);
    this.state = { hourlySortingContent: this.hourlySelectionContent.matrix };
  }

  componentDidUpdate() {
    this.props.clearStateForDistCenter(this.props.match.params.distCenter);
  }

  render() {
    const { productUnit, timeFilter } = this.props;

    const distCenterSlug = this.props.match && this.props.match.params.distCenter;

    return (
      <>
        {/* Header: choose Table vs graphics */}
        <ContentDivisionHeader
          title="Selección por hora"
          centerComponent={(
            <Toggle
              options={[{ text: 'Matriz', key: 'matrix' }, { text: 'Gráfica', key: 'chart' }]}
              onOptionSelect={(key: string) => this.setState({ hourlySortingContent: key })}
              selectedOption={this.state.hourlySortingContent}
            />
          )}
        />

        {/* Table and Chart */}
        {this.state.hourlySortingContent === 'matrix'
          ? (
            <SortersHourlySelectionMatrix
              distCenterSlug={distCenterSlug}
              productUnit={productUnit}
              timeFilter={timeFilter}
            />
          )
          : null}
        {this.state.hourlySortingContent === 'chart'
          ? <SortersHourlySelectionChart timeFilter={timeFilter} productUnit={productUnit} />
          : null}

        {/* 2nd row */}
        <PresentationTotals productUnit={productUnit} timeFilter={timeFilter} />

        {/* 3rd row */}
        <ContentDivisionHeader title="Cajas por cupos" />
        <SortersPresentationTable
          distCenterSlug={distCenterSlug}
          productUnit={productUnit}
          timeFilter={timeFilter}
        />

        {/* 4th row */}
        <ContentDivisionHeader title="Seleccionadores" />
        <div className="row">
          <div className="col-12 col-lg-9">
            <SortersKPITable
              distCenterSlug={distCenterSlug}
              productUnit={productUnit}
              timeFilter={timeFilter}
            />
          </div>
          <div className="col">
            <SortersSummary productUnit={productUnit} timeFilter={timeFilter} />
          </div>
        </div>
      </>
    );
  }
}

type Props = {
  match: any;
  productUnit: string;
  timeFilter: any;
  clearStateForDistCenter(distCenter: string): void;
};

function mapStateToProps(state: any) {
  return {
    productUnit: selectProductUnitForLocation(state, 'homeAndSortingDashboard'),
    timeFilter: selectTimeFilterForLocation(state, 'homeAndSortingDashboard'),
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    clearStateForDistCenter,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SortingDashboard);
