import React, { Component } from 'react';
import Chart from 'chart.js';
import '../../lib/doughnutChartjsRoundedCorners';
import '../../lib/doughnutChartjsCenteredText';
import merge from 'lodash.merge';

class DoughnutChart extends Component<Props, any> {
  doughnutChart: any;

  defaultOptions = {
    plugins: {
      datalabels: {
        display: false,
      },
      roundedCornersDoughnut: {
        roundedCornersForArc: 0,
        color: '#004AC9',
      },
      legendCenter: {
        maxText: '100%',
        primaryText: '%',
        primaryFontColor: '#515C70',
        fontFamily: "'Nunito Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
        fontStyle: 'normal',
        minFontSize: 1,
        maxFontSize: 24,
      },
    },
    tooltips: {
      enabled: false,
    },

    cutoutPercentage: 80,
    legend: {
      display: false,
    },
  };

  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate() {
    const percentage = this.getPercentageFromProps();
    const primaryTextOption = {
      plugins: { legendCenter: { primaryText: `${percentage.toString()}%` } },
    };
    this.doughnutChart.data.datasets[0].data = [percentage, (100 - percentage)];
    this.doughnutChart.options = merge(this.defaultOptions,
      merge(primaryTextOption, this.props.options));
    this.doughnutChart.update();
  }

  getPercentageFromProps() {
    const percentage = Math.round((this.props.currentValue / this.props.totalValue) * 100);
    return isNaN(percentage) ? 0 : percentage;
  }

  renderChart() {
    const percentage = this.getPercentageFromProps();

    // @ts-ignore
    const ctx = document.getElementById('dc-goals-chart').getContext('2d');
    const primaryTextOption = {
      plugins: { legendCenter: { primaryText: `${percentage.toString()}%` } },
    };
    // console.log("the nombre :", merge(this.defaultOptions, nombre) )
    this.doughnutChart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        datasets: [{
          data: [percentage, percentage >= 100 ? 0 : (100 - percentage)],
          backgroundColor: [
            '#004AC9',
            '#D8E1E6',
          ],
        }],
      },
      options: merge(this.defaultOptions, merge(primaryTextOption, this.props.options)),
      plugins: [],
    });
  }

  render() {
    return (
      <canvas id="dc-goals-chart" />
    );
  }
}

type Props = {
  currentValue: number;
  totalValue: number;
  options?: any;
};

export default DoughnutChart;
