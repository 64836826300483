/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as ArvolutionLogo } from '../../assets/icons/arvolution-logo.svg';
import UserIcon from '../../assets/icons/user-icon.svg';
import UserWarningIcon from '../../assets/icons/user-warning.svg';
import LockIcon from '../../assets/icons/lock-icon.svg';
import LockIWarningIcon from '../../assets/icons/lock-icon-orange.svg';

import InputWithIcon from '../../components/Input/InputWithIcon';
import HighlightButton from '../../components/Button/HighlightButton';
import request from '../../utils/request';
import LoadingSpinner from '../../components/LoadingSpinner';

import './Login.scss';

type State = {
  email: string;
  password: string;
  errors: any;
  loggingIn: boolean;
};

export default class Register extends PureComponent<{}, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errors: {},
      loggingIn: false,
    };
    this.submitLogin = this.submitLogin.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.enterSubmit = this.enterSubmit.bind(this);
  }

  enterSubmit(e: React.KeyboardEvent<HTMLFormElement>) {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();
      this.submitLogin();
    }
  }

  async submitLogin(e?: any) {
    if (e && e.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.setState({ loggingIn: true, errors: {} });
    try {
      await request.post('/api/v1/users/login', this.state);
      // eslint-disable-next-line no-undef
      window.location.replace('/');
    } catch (error) {
      if ((typeof error.response.data) !== 'object') {
        if (error.response.status === 401 && error.response.statusText === 'Unauthorized') {
          return this.setState({ errors: { message: 'Credenciales no válidas' }, loggingIn: false });
        }

        this.setState({ errors: { message: 'Error de red' }, loggingIn: false });
      } else {
        this.setState({ errors: error.response.data, loggingIn: false });
      }
    }
  }

  handleInputChange(e: any) {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors } = this.state;
    // 500 error responses are not formatted within input field keys
    // but rather as a message key
    let nonValidationError = '';
    if (!errors.email && !errors.password && errors.message) {
      nonValidationError = errors.message;
    }
    return (
      <div className="login">
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3 card main-card-container">
              <ArvolutionLogo style={{ width: 50 }} />
              <p className="header-text">Sorting</p>
              <p className="welcome-text" style={{ marginBottom: 10 }}>
                Iniciar sesión para entrar al dashboard de Sorting
              </p>
              <form onSubmit={this.submitLogin} onKeyDown={this.enterSubmit}>
                {/* <Input
                  type="text"
                  placeholder="Correo"
                  onChange={this.handleInputChange}
                  name="email"
                  error={errors.email && errors.email.msg}
                /> */}
                <InputWithIcon
                  icon={UserIcon}
                  errorIcon={UserWarningIcon}
                  type="text"
                  placeholder="Correo"
                  onChange={this.handleInputChange}
                  name="email"
                  error={errors.email && errors.email.msg}
                  errorHeight
                />
                {/* <Input
                  className="mt-1"
                  type="password"
                  placeholder="Contraseña"
                  onChange={this.handleInputChange}
                  name="password"
                  error={errors.password && errors.password.msg}
                /> */}
                <InputWithIcon
                  icon={LockIcon}
                  errorIcon={LockIWarningIcon}
                  type="password"
                  placeholder="Contraseña"
                  onChange={this.handleInputChange}
                  name="password"
                  error={errors.password && errors.password.msg}
                  errorHeight
                  style={{ borderWidth: '1px' }}
                />
                <p className="recover-password-text">
                  ¿Se te olvidó tu contraseña? Recupérala <Link to="/reset-password" style={{ textDecoration: 'underline' }}>aquí.</Link>
                </p>
                <HighlightButton
                  text="INICIAR SESIÓN"
                  className="main-action"
                  onClick={this.submitLogin}
                />
                {nonValidationError ? <p className="error">{nonValidationError}</p> : null}
                {this.state.loggingIn
                  ? <LoadingSpinner text="Iniciando Sesión" className="login-spinner" />
                  : null}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
