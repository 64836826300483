/* eslint-disable no-undef */
import React, { PureComponent, createRef, RefObject } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './UserEdit.scss';
import BasicButton from '../../../../components/Button/BasicButton';
import Input from '../../../../components/Input/LineInput';
import UploadIcon from '../../../../assets/icons/upload.svg';
import DeleteIcon from '../../../../assets/icons/delete-icon.svg';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { selectUserHeaderInfo } from '../../../../selectors';
import request from '../../../../utils/request';
import { getUserHeaderInfo } from '../../../../actions/user.actions';

let count = true;
class UserEdit extends PureComponent<any, State> {
  constructor(props: any) {
    super(props);
    count = true;
    this.state = {
      name: '',
      disabled: false,
    };
  }

  componentDidMount() {
    this.props.getUserHeaderInfo(this.props.sorterSlug);
  }

  static getDerivedStateFromProps(props: any, state: State) {
    if (
      count
      && !state.name
      && props.userHeaderInfo
      && (props.userHeaderInfo.get('name') !== state.name)
    ) {
      count = false;
      return {
        name: props.userHeaderInfo.get('name'),
        picture: props.userHeaderInfo.get('picture'),
      };
    }
    return null;
  }

  disableUser = async () => {
    const { sorterSlug, distCenter } = this.props;
    this.setState(state => ({
      ...state,
      disabled: true,
    }));
    try {
      await request.post(`/api/v1/distribution-centers/${distCenter}/users/${sorterSlug}/disable`, {});
      this.props.push('/distribution-centers/san-pablo/');
    } catch (error) {
      this.setState(state => ({
        ...state,
        disabled: false,
      }));
    }
  }

  saveChange = async () => {
    const { name, picture } = this.state;
    if (
      (name && (name.trim() !== this.props.userHeaderInfo.get('name')))
      || (picture && (picture.trim() !== this.props.userHeaderInfo.get('picture')))
    ) {
      const { sorterSlug, distCenter } = this.props;
      this.setState(state => ({
        ...state,
        disabled: true,
      }));
      try {
        await request.put(`/api/v1/distribution-centers/${distCenter}/users/${sorterSlug}/edit`, { name, picture });
        this.props.showEditPage(false);
      } catch (error) {
        this.setState(state => ({
          ...state,
          disabled: false,
        }));
      }
    }
  }

  onFileUpload = async (acceptedFiles: File[]) => {
    if (acceptedFiles.length) {
      this.setState(state => ({
        ...state,
        image: acceptedFiles[0],
        disabled: true,
      }));

      const spilted = acceptedFiles[0].name.split('.');
      const fileExtension = spilted[spilted.length - 1];

      try {
        const requestImageUpload = await request.post('/api/v1/image-upload', { fileExtension });

        if (Object.keys(requestImageUpload).length) {
          try {
            const { preSignedUrl, fileName }: {
              preSignedUrl: string; fileName: string;
            } = requestImageUpload;

            const response = await fetch(preSignedUrl, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/octet-stream',
              },
              body: acceptedFiles[0],
            });

            if (response && (response.status >= 200 && response.status <= 299)) {
              return this.setState(oldState => ({
                ...oldState,
                picture: preSignedUrl.split('?')[0],
                disabled: false,
              }));
            }

            throw Error(`error disabled image. Status ${response.status}`);
          } catch (e) {
            console.log('Error disabled image to s3: ', e.message || e);
          }
        }
      } catch (e) {
        console.log('IMAGE_UPLOAD_PERMISSION_ERROR: was not able to get permission to upload image to S3');
      }

      this.setState(state => ({
        ...state,
        disabled: false,
      }));
    }
  }

  render() {
    const { userHeaderInfo } = this.props;
    const { disabled } = this.state;

    return (
      <>
        { userHeaderInfo
          ? (
            <div className="container main-content-margin">
              <div className="card editCard">
                <div className="col edit">
                  <div className="row">
                    <div>
                      <Dropzone onDrop={this.onFileUpload} accept="image/*" noDrag={false} disabled={disabled}>
                        {({ getRootProps, getInputProps }) => (
                          <div
                            {...getRootProps({
                              className: 'displayPic',
                              onDrop: event => event.stopPropagation(),
                            })}
                          >
                            <input {...getInputProps()} />
                            {this.state.picture && <img src={this.state.picture} alt=" " />}
                            <button type="button">
                              <img src={UploadIcon} alt="gg" />
                              <p>Subir foto</p>
                            </button>
                          </div>
                        )}
                      </Dropzone>
                      <div>
                        <Input
                          onChange={(e: any) => this.setState({ name: e.target.value })}
                          value={this.state.name}
                          style={{
                            fontWeight: 'bold',
                          }}
                          error={this.state.name.trim() === '' ? 'Se requiere el nombre' : undefined}
                        />
                      </div>
                    </div>
                    <div>
                      <BasicButton text="Guardar" onClick={this.saveChange} disabled={disabled} />
                    </div>
                  </div>
                  <div className="row">
                    <BasicButton text="Regresar sin guardar" onClick={() => this.props.showEditPage(false)} disabled={disabled} />
                    <button type="button" className="row" onClick={this.disableUser}>
                      <img src={DeleteIcon} alt="Delete Icon" />
                      <p>Dar de baja a usuario</p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <LoadingSpinner height={200} />
          )}
      </>
    );
  }
}

type State = {
  name: string;
  picture?: string;
  image?: File;
  disabled: boolean;
}

function mapStateToProps(state: any) {
  return {
    userHeaderInfo: selectUserHeaderInfo(state),
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    getUserHeaderInfo,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit);
