import addHours from 'date-fns/add_hours';
import startOfDay from 'date-fns/start_of_day';
import endOfDay from 'date-fns/end_of_day';
import subWeeks from 'date-fns/sub_weeks';
import { Collection } from 'immutable';
import { addMinutes, differenceInDays, format, subDays } from 'date-fns';
import * as esLocale from 'date-fns/locale/es';

// TODO: change to something from server and save in state
// (new Reducer: distCenter.shifts: {startTime, endTime}[])
export function getStartOfShift() {
  const today = new Date();
  today.setUTCHours(12, 0, 0, 0);
  return today;
}

export function getEndOfShift() {
  const today = new Date();
  today.setUTCHours(21, 0, 0, 0);
  return today;
}

export function getCurrentDate() {
  const startDate = startOfDay(new Date());
  const endDate = addHours(startDate, 24);
  return { startDate, endDate };
}

export function getCurrentWeek() {
  const endDate = endOfDay(new Date());
  const startDate = subWeeks(endDate, 1);
  return { startDate, endDate };
}

export function getCurrentMonth() {
  const endDate = endOfDay(new Date());
  const startDate = subWeeks(endDate, 4);
  return { startDate, endDate };
}

export function formatSecondsToTime(time: number) {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  let realMinutes = '';
  let realSeconds = '';
  if (minutes >= 1) {
    realMinutes = `${minutes} min`;
  } else {
    realSeconds = `${seconds} seg`;
  }
  return `${realMinutes}${realSeconds}`;
}

export function formatTimeLabel(times: Collection<any, any>,
  timeUnit: string, distributionCenterUTCOffset: number, isTable?: boolean): any[] {
  /** timeUnit: 'hour' | 'day' | 'week' | 'month'
   * a daily datum that represents a single day,
   * e.g. the whole day of the 27th of march, is represented with start date
   * a monthly datum that represents a whole month,
   * e.g. the whole of January, is represented with start date
   * everything else is represented with a range (e.g. 27th of march-27th of April, or 7PM-8PM)
   * */

  let formatFunction: Function;
  const hourFormat = 'H';
  const dayFormat = 'D[/]MM';
  const dayTableFormat = 'dddd D';
  const weekFormat = 'MMM D';
  const monthFormat = 'DD[/]M';

  // build the format function depending on period of time labels
  switch (timeUnit) {
    case 'hour':
      formatFunction = (startDate: Date, endDate: Date) => {
        // hour begins at 00?
        const hourlyFormat = (startDate.getMinutes() || endDate.getMinutes()) ? 'h[:]mmA' : hourFormat;
        return (
          `${format(startDate, hourlyFormat, { locale: esLocale })
          } - ${
            format(endDate, hourlyFormat, { locale: esLocale })}`
        );
      };
      break;

    case 'day':
      formatFunction = (startDate: Date) => (isTable
        ? format(startDate, dayTableFormat, { locale: esLocale }).split(' ')
        : format(startDate, dayFormat, { locale: esLocale }));
      break;

    case 'week':
      formatFunction = (startDate: Date, endDate: Date) => {
        let endDayEarlier;
        // for end date: take previous day when it begins at 00:00
        if (endDate.getHours() === 0 && endDate.getMinutes() === 0) {
          endDayEarlier = subDays(endDate, 1);
        }

        return (
          `${format(startDate, weekFormat, { locale: esLocale })
          } - ${
            format(endDayEarlier || endDate,
              weekFormat,
              { locale: esLocale })}`
        );
      };
      break;

    case 'month':
      formatFunction = (startDate: Date, endDate: Date) => {
        // partial months
        if (differenceInDays(endDate, startDate) < 28) {
          return (
            `${format(startDate, monthFormat, { locale: esLocale })
            } - ${
              format(endDate, monthFormat, { locale: esLocale })}`
          );
        }

        return format(startDate, 'MMM', { locale: esLocale });
      };
      break;

    default:
      formatFunction = (startDate: Date, endDate: Date) => `${startDate.toLocaleString()} - ${endDate.toLocaleString()}`;
  }

  return times.map((o: any) => {
    const startLocalDate = new Date(o.get('start'));
    const endLocalDate = new Date(o.get('end'));
    const startDateDistributionCenter = addMinutes(startLocalDate,
      startLocalDate.getTimezoneOffset() - +distributionCenterUTCOffset);
    const endDateDistributionCenter = addMinutes(endLocalDate,
      endLocalDate.getTimezoneOffset() - +distributionCenterUTCOffset);

    return formatFunction(startDateDistributionCenter, endDateDistributionCenter);
  }).toArray();
}

export const TIME_ZONE_OFFSET = new Date().getTimezoneOffset();
