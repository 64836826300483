import React, { FC, useCallback, useState } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as UploadIcon } from '../../../assets/icons/icon-upload.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg';
import request from '../../../utils/request';
import './FileUpload.scss';


const FileUpload: FC = () => {
  const [file, setFile] = useState();
  const [status, setStatus] = useState({
    rejected: false,
    uploaded: false,
    uploadingPercent: 1,
    cancelFileUpload: () => {},
  });

  const onDrop = useCallback(async acceptedFiles => {
    if (!acceptedFiles.length) {
      return setStatus({
        ...status,
        rejected: true,
      });
    }
    setStatus({
      ...status,
      rejected: false,
    });
    setFile(acceptedFiles[0]);

    // eslint-disable-next-line no-undef
    const data = new FormData();
    data.append('file', acceptedFiles[0]);

    try {
      const { CancelToken } = axios;
      await request.upload(
        '/api/v1/corporate/dist-center-excel-upload',
        data,
        {},
        {
          onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setStatus({
              ...status,
              uploadingPercent: status.uploadingPercent + percentCompleted,
            });
          },
          cancelToken: new CancelToken((c => {
            setStatus({
              ...status,
              cancelFileUpload: c,
            });
          })),
        },
      );

      setStatus({
        ...status,
        uploaded: true,
      });
    } catch (error) {
      if (error.message === 'cancel file upload') {
        console.log('Request canceled', error.message);
      }
    }
  }, []);

  const cancelUpload = () => {
    // @ts-ignore
    status.cancelFileUpload('cancel file upload');
    setStatus({
      rejected: false,
      uploaded: false,
      uploadingPercent: 1,
      cancelFileUpload: () => {},
    });

    setFile(undefined);
  };

  const {
    getRootProps, getInputProps, isDragActive, isFileDialogActive,
  } = useDropzone({
    onDrop,
    accept: ['.xlsx'],
    multiple: false,
    disabled: !!file,
  });

  const { rejected, uploaded, uploadingPercent } = status;
  return (
    <div className="card dragContainer p-4">
      <div {...getRootProps({
        className: `${
          isDragActive || isFileDialogActive ? 'dragActive' : rejected ? 'dragRejected' : file ? 'uploading' : ''}`,
      })}
      >
        <input {...getInputProps()} />
        {!file
          ? (
            <div className="uploadBoard">
              <div>
                <UploadIcon />
                <p>Cargar archivo</p>
              </div>
              <p>Arrastra tu archivo a esta pantalla</p>
            </div>
          )
          : !uploaded ? (
            <div className="uploading">
              <div className="p-3">
                <CheckIcon arial-label="excel icon" className="mr-2" />
                <p>{file.name}</p>
              </div>
              <div>
                <span><span><span style={{ width: `${uploadingPercent}%` }} /></span></span>
                <button type="button" onClick={cancelUpload}>Cancelar</button>
              </div>
            </div>
          ) : (
            <div className="uploaded">
              <div>
                <CheckIcon style={{ width: '4.5rem', height: '4.5rem' }} />
                <p>Cargado éxitosamente</p>
              </div>
              {/* <button type="button" onClick={cancelUpload}>Guardar</button> */}
            </div>
          )}
      </div>
    </div>
  );
};

export default FileUpload;
