import React, { PureComponent } from 'react';
import UserHeader from './UserHeader';
import UserBody from './UserBody';
import UserEdit from './UserEdit';
import UserTasksTable from './UserTasksTable';
import './UserDetail.scss';
import UserWeeklyCompensation from './UserWeeklyCompensation';

export default class UserDetail extends PureComponent<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      productivityView: true,
      editUserPage: false,
    };
  }

  setProductivityView = (view: any) => {
    this.setState(view);
  };

  showEditPage = (status: boolean) => this.setState({ editUserPage: status });

  render() {
    const {
      match: {
        params: { user: sorterSlug, distCenter },
      },
    } = this.props;

    if (this.state.editUserPage) {
      return (
        <UserEdit
          showEditPage={this.showEditPage}
          sorterSlug={sorterSlug}
          distCenter={distCenter}
          push={this.props.history.push}
        />
      );
    }
    return (
      <div className="container main-content-margin">
        <div className="card">
          <div className="user-detail">
            <UserHeader
              sorterSlug={sorterSlug}
              productivityView={this.state.productivityView}
              setProductivityView={this.setProductivityView}
              showEditPage={this.showEditPage}
            />
            {
              this.state.productivityView
                ? (
                  <>
                    <UserBody sorterSlug={sorterSlug} />
                    <UserTasksTable sorterSlug={sorterSlug} />
                  </>
                ) : <UserWeeklyCompensation sorterSlug={sorterSlug} />
            }
          </div>
        </div>
      </div>
    );
  }
}

type State = {
  productivityView: boolean;
  editUserPage: boolean;
}
