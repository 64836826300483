import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import arrowNext from '../../../../assets/icons/arrow-right.svg';
import arrowBack from '../../../../assets/icons/arrow-left.svg';
import {
  selectTimeFilterForLocation,
  selectUserBoxesByPresentation,
  selectUserWeeklyPayment
} from "../../../../selectors";
import ReactTable from "react-table";
import moment from 'moment';
import './styles.css';
import {bindActionCreators} from "redux";
import {
  getUserBoxesByPresentation,
  getUserHeaderInfo,
  getUserWeeklyCompensation, setUserWeeklyPayment
} from "../../../../actions/user.actions";
import {setTimeFilterForPage} from "../../../../actions/dataFilter.actions";
import LoadingSpinner from "../../../../components/LoadingSpinner";

const generateColumns = (weekDays: []) =>{
  return weekDays.map(
    (day: string, i: number) => ({
      Header: <div style={{textAlign: 'left', width: '100%'}}><span>{
        moment(day).format('dddd').toString()
      }</span><br/><span
        style={{color: '#78A9FF'}}>{moment(day).format('DD').toString()}</span>
      </div>,
      accessor: 'payment' + i,
      Cell: (props:any) => <div
        style={{textAlign: 'left', width: '100%', padding: '4px 10px'}}><span
        style={{
          color: '#78A9FF',
          fontSize: '15px',
        }}>$ {
        props.value
      }</span><br/><span style={{
        color: '#9DAECC',
      }}>Selección</span></div>,
    })
  )
};

const UserWeeklyCompensation = (props: Props) => {

  let weekDays : any = [];
  let dataTable: any = [];

  useEffect(
    ()=>{

      if(!props.userWeeklyPayment) {
        props.getUserWeeklyCompensation(props.sorterSlug,
          {
            startTime: moment().startOf('week').toDate(),
            endTime: moment().startOf('week').add(1, 'week').toDate(),
          });
      }
      return ()=> {
        // set to null on unmounting
        props.setUserWeeklyPayment(null);
      }
    }
  , []);

    if(props.userWeeklyPayment) {
        for (let i = 0; i < 7; i++)
          weekDays.push(moment(props.userWeeklyPayment.get('formattedSlot').first().get('start')).add(i, 'days'));

        let table = [];
        if (props.userWeeklyPayment.get('paymentsForTime').count() === 6){
          table.push(0);
        }
          for(let i=0; i<7; i++){
            if(!isNaN(props.userWeeklyPayment.get('paymentsForTime').get(i)))
            {
              table.push(parseFloat(props.userWeeklyPayment.get('paymentsForTime').get(i)).toFixed(2));
            }
          }
        dataTable = table.map(
          (dayData,i) => {
            const temp: any = {};
            temp['payment'+i] = dayData;
            return {...temp};
          }
        );
        dataTable = [dataTable.reduce(
          (a:{}, v:any) => ({...a, ...v}), {}
        )];
      }

  const nextWeekPossible = () => (moment(props.userWeeklyPayment.get('formattedSlot').last().get('end')).isBefore(moment(), 'day'));
  const changeWeek = (start: any, end: any) => {
    props.getUserWeeklyCompensation(props.sorterSlug,
      {
        startTime: start,
        endTime: end
      });
  };

  return (

      props.userWeeklyPayment ?
            <>
                <div className='d-flex flex-row justify-content-between align-content-center my-4'>
                  <div>
                    Salario Total: <b>$ {props.userWeeklyPayment ?
                    Array.from(props.userWeeklyPayment.get('paymentsForTime')).reduce(
                      (a: any, v:any) => (a+parseFloat(v)), 0
                    ) : 0}</b>
                  </div>
                  <div>
                    <img src={arrowBack}
                         style={{width: '15px', marginRight: '50px', cursor: 'pointer'}}
                         alt="arrow"
                          onClick={
                            ()=>changeWeek(
                              moment(props.userWeeklyPayment.get('formattedSlot').first().get('start')).subtract(1, 'week').toDate(),
                              moment(props.userWeeklyPayment.get('formattedSlot').last().get('end')).subtract(1, 'week').toDate()
                            )
                          }
                    />
                  {moment(props.userWeeklyPayment.get('formattedSlot').first().get('start')).format('dddd DD-MM-YYYY')}
                  &nbsp;-&nbsp;
                  {moment(props.userWeeklyPayment.get('formattedSlot').last().get('end')).format('dddd DD-MM-YYYY')}
                    <img src={arrowNext}
                         style={{width: '15px', marginLeft: '50px', cursor:
                             nextWeekPossible() ? 'pointer' : 'not-allowed'
                         }}
                         onClick={ nextWeekPossible() ? ()=>changeWeek(
                           moment(props.userWeeklyPayment.get('formattedSlot').first().get('start')).add(1, 'week').toDate(),
                           moment(props.userWeeklyPayment.get('formattedSlot').last().get('end')).add(1, 'week').toDate()
                         ) : ()=>{} }
                         alt="arrow"/>
                  </div>
                </div>

              <div className='user-weekly-compensation'>
                <ReactTable
                    columns={generateColumns(weekDays)}
                    data={dataTable}
                    className="-highlight"
                    showPagination={false}
                    NoDataComponent={() => null}
                    PadRowComponent= {()=>null}

                />
              </div>
            </> : <LoadingSpinner height={200} />
  )
};

function mapStateToProps(state: any) {
  return {
    userWeeklyPayment: selectUserWeeklyPayment(state),
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    dispatch,
    getUserWeeklyCompensation,
    setUserWeeklyPayment,
  }, dispatch);
}

type Props = {
  userWeeklyPayment: any,
  sorterSlug: any,
  getUserWeeklyCompensation: typeof getUserWeeklyCompensation,
  setUserWeeklyPayment: typeof setUserWeeklyPayment,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserWeeklyCompensation);
