import { fromJS } from 'immutable';

import {
  SET_CURRENT_ZONE,
  SET_PRODUCT_UNIT_FOR_PAGE,
  SET_TIME_FILTER_FOR_PAGE,
  SET_SELECTED_SHIFT,
} from '../actions/dataFilter.actions';

const INITIAL_STATE = fromJS({
  selectedShift: 'morning-g',
});

const dataFilterReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_TIME_FILTER_FOR_PAGE:
      return state.updateIn(
        [action.stateLocation, 'time'],
        () => fromJS(action.timeFilter),
      ).updateIn(
        [action.stateLocation, 'dropdownValue'],
        () => action.dropdownValue,
      );
    case SET_PRODUCT_UNIT_FOR_PAGE:
      return state.updateIn([action.stateLocation, 'productUnit'],
        () => action.productUnit);
    case SET_SELECTED_SHIFT:
      return state.updateIn(['selectedShift'], () => action.shift);
    case SET_CURRENT_ZONE:
      return state.updateIn(['corporate', 'currentZone'], () => action.zoneSlug);
    default:
      return state;
  }
};

export default dataFilterReducer;
