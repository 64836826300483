import Chart from 'chart.js';

Chart.pluginService.register({
  id: 'roundedCornersDoughnut',
  afterUpdate: (chart: any) => {
    // console.log("our option: ", chart.config);
    if (chart.config.options.plugins.roundedCornersDoughnut !== undefined) {
      const arc = chart.getDatasetMeta(0)
        .data[chart.config.options.plugins.roundedCornersDoughnut.roundedCornersForArc];
      arc.round = {
        x: (chart.chartArea.left + chart.chartArea.right) / 2,
        y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
        radius: (chart.outerRadius + chart.innerRadius) / 2,
        thickness: (chart.outerRadius - chart.innerRadius) / 2 - 1,
        backgroundColor: chart.config.options.plugins.roundedCornersDoughnut.color,
      };
    }
  },

  afterDraw: (chart: any) => {
    // console.log("the options: ", options);
    if (chart.config.options.plugins.roundedCornersDoughnut !== undefined) {
      const { ctx } = chart.chart;
      const arc = chart.getDatasetMeta(0)
        .data[chart.config.options.plugins.roundedCornersDoughnut.roundedCornersForArc];
      const startAngle = Math.PI / 2 - arc._view.startAngle;
      const endAngle = Math.PI / 2 - arc._view.endAngle;

      ctx.save();
      ctx.translate(arc.round.x, arc.round.y);
      ctx.fillStyle = arc.round.backgroundColor;
      ctx.fillRect(0, 0, 0, 100);
      ctx.beginPath();
      ctx.arc(
        Math.abs(arc.round.radius) * Math.sin(startAngle),
        Math.abs(arc.round.radius) * Math.cos(startAngle),
        Math.abs(arc.round.thickness), 0, 2 * Math.PI,
      );
      ctx.arc(
        Math.abs(arc.round.radius) * Math.sin(endAngle),
        Math.abs(arc.round.radius) * Math.cos(endAngle),
        Math.abs(arc.round.thickness), 0, 2 * Math.PI,
      );
      ctx.closePath();
      ctx.fill();
      ctx.restore();
    }
  },
});
