/* eslint-disable no-undef */
import { useLayoutEffect, useState } from 'react';
import debounce from 'lodash.debounce';

const useWindowResizeWidth = () => {
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    const updateWidth = debounce(() => {
      setWidth(window.innerWidth);
    }, 100);

    window.addEventListener('resize', updateWidth);
    updateWidth();

    return () => window.removeEventListener('resize', updateWidth);
  }, []);
  return Number(width);
};

export default useWindowResizeWidth;
