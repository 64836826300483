import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ReactComponent as BoxIcon } from '../../../assets/icons/box.svg';
import { ReactComponent as BoxLightIcon } from '../../../assets/icons/box-light.svg';
import { ReactComponent as HomeIcon } from '../../../assets/icons/home.svg';

import ContentDivisionHeader from '../../../components/Layout/ContentDivisionHeader';
import WorkersCount from './WorkersCount';
import MostLeastProductiveAgencies from './MostLeastProductiveAgencies';
import MostLeastProductiveWorkers from './MostLeastProductiveWorkers';
import ProductivityChart from './ProductivityChart';
import { selectCurrentZoneCorporate } from '../../../selectors';

import './CorporateHome.scss';

type Props = {
  dispatch?: any;
  // store
  currentZoneSlug: string;
};
type State = {};

class CorporateHome extends Component<Props, State> {
  render() {
    const { currentZoneSlug } = this.props;

    return (
      <div className="corporate-home">


        {/* productivity */}
        <ContentDivisionHeader
          title="Productividad"
          smallTitleDescription="en todas las zonas"
          endText="información de 23/05, 11PM –– 24/05, 6AM"
        />
        {/* chart + 2 cards */}
        <div className="row justify-content-center">
          {/* productivity chart */}
          <div className="col-12 col-sm-8 productivity-chart">
            <ProductivityChart currentZoneSlug={currentZoneSlug} />
          </div>

          {/* 2 cards */}
          <div
            className="col-12 col-sm-4 d-flex flex-column mt-3 mt-sm-0 justify-content-between"
          >
            <MostLeastProductiveWorkers currentZoneSlug={currentZoneSlug} />

            <WorkersCount currentZoneSlug={currentZoneSlug} />
          </div>
        </div>

        {/* last row */}
        <div
          className="row justify-content-between align-items-center mb-5 most-least-productive-agencies"
          style={{ marginTop: 22 }}
        >
          <MostLeastProductiveAgencies currentZoneSlug={currentZoneSlug} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  currentZoneSlug: selectCurrentZoneCorporate(state),
});

export default connect(mapStateToProps)(CorporateHome);
