import React, { PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { ReactComponent as LogoutIcon } from '../../assets/icons/log-out.svg';

import MainNavigationHeader from '../../components/MainNavigationHeader/MainNavigationHeader';
import FactoryNavigation from '../../components/FactoryNavigation';
import HomeDashboard from './HomeDashboard';
import SortingDashboard from './SortingDashboard';
import UserDetail from './UserDetail';
import DevicesConfiguration from './DevicesConfiguration';
import AccountDetail from './AccountDetail';
import DistCenterDashboardFilters from '../../components/DistCenterDashboardFilters';
import { selectHomeCurrentDistCenter, selectUserSlug } from '../../selectors';
import { getDistributionCenterInformation } from '../../actions/distributionCenter.actions';
import UsersConfiguration from './UsersConfiguration';
import DistCenterConfiguration from './DistCenterConfiguration';
import { PAGE } from '../../types/types-enums-interfaces';

class DistCenterWrapper extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      navigatedFrom: undefined,
    };
  }

  componentDidMount(): void {
    this.props.dispatch(getDistributionCenterInformation());

    // location.state keeps changing on every navigation. We need the one when we first navigate
    // here from another section
    const { location } = this.props;
    const navigatedFrom = location && location.state && location.state.from;

    if (navigatedFrom) this.setState({ navigatedFrom });
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.match.params.distCenter !== this.props.match.params.distCenter) {
      this.props.dispatch(getDistributionCenterInformation());
    }
  }

  render() {
    const { match, location } = this.props;
    const distCenterSlug = this.props.match.params.distCenter;
    const configRoutes = [
      {
        url: `/distribution-centers/${distCenterSlug}/configuration`,
        label: 'Configuración General',
      },
      {
        url: `/distribution-centers/${distCenterSlug}/configuration/users`,
        label: 'Usuarios App',
        insertSectionDivider: true,
      },
      {
        url: `/distribution-centers/${distCenterSlug}/configuration/account/${this.props.userSlug}`,
        label: 'Mi Perfil',
      },
      {
        url: '/logout',
        label: (
          /* use span since it's going to be inside of a p tag. */
          <span style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
          }}
          >
          Salir
            <LogoutIcon style={{ width: 15 }} />
          </span>
        ),
      }];

    return (
      <>
        {/* top header */}
        <MainNavigationHeader
          configRoutes={configRoutes}
          match={match}
          pathname={this.props.location.pathname}
          from={this.state.navigatedFrom}
        />
        {/* nav */}
        <FactoryNavigation distCenterSlug={distCenterSlug} location={location} />

        <div className="container-fluid main-content-margin" style={{ maxWidth: 1225 }}>
          {/* filters */}
          <div style={{
            display: (location.pathname.includes('users')
              || location.pathname.includes('configuration')) ? 'none' : 'block',
          }}
          >
            <DistCenterDashboardFilters
              showUnitChoice={location.pathname.includes('sorting')}
              showCalendar
              stateFilterLocation="homeAndSortingDashboard"
              showNightFilters={distCenterSlug === 'guadalajara'}
            />
          </div>

          <Switch>
            <Route path={match.path} exact component={HomeDashboard} />
            <Route path={`${match.path}/sorting`} exact component={SortingDashboard} />
            <Route path={`${match.path}/users/:user`} exact component={UserDetail} />
            <Route
              path={`${match.path}/configuration/devices`}
              exact
              component={DevicesConfiguration}
            />
            <Route path={`${match.path}/configuration/users`} component={UsersConfiguration} />
            <Route
              path={`${match.path}/configuration/account/:me`}
              exact
              component={AccountDetail}
            />
            <Route
              path={`${match.path}/configuration`}
              exact
              component={DistCenterConfiguration}
            />
          </Switch>
        </div>
      </>
    );
  }
}

type Props = {
  dispatch: any;
  match: any;
  location: any;
  // store
  userSlug: string;
  currentDistCenter: string;
};

type State = {
  navigatedFrom: PAGE | undefined;
};

function mapStateToProps(state: any) {
  return {
    userSlug: selectUserSlug(state),
    currentDistCenter: selectHomeCurrentDistCenter(state),
  };
}

export default connect(mapStateToProps)(DistCenterWrapper);
