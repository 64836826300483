import React, { Component } from 'react';
import ReactTable from 'react-table';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { ReactComponent as UserIcon } from '../../../../assets/icons/user.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete-icon.svg';

import LoadingSpinner from '../../../../components/LoadingSpinner';
// import { selectForkliftsConfig } from '../../../selectors';
// import { getForkliftsConfiguration } from '../../../actions/configuration.actions';
import { UserType } from '../../../../types/types-enums-interfaces';
import DefaultDropdown from '../../../../components/Dropdown/DefaultDropdown';
import ActiveDot from '../../../../components/ActiveDot';
import { disableUser } from '../../../../actions/configuration.actions';

import 'react-dropdown/style.css';
import './ForkliftsConfiguration.scss';

class ForkliftsConfiguration extends Component<Props, any> {
  constructor(props: any) {
    super(props);
    this.disableUser = this.disableUser.bind(this);
    this.renderConfigurationCell = this.renderConfigurationCell.bind(this);
  }

  createTableColumns() {
    const columns = [] as any;
    columns.push({
      Header: () => (
        <UserIcon style={{ width: 20, height: 20 }} />
      ),
      id: 'name',
      accessor: (dataRow: any) => dataRow.get('name'),
      Cell: (row: any) => <p className="text-left font-bold">{row.value}</p>,
    });

    /* columns.push({
      Header: '# de Empleado',
      id: 'employeeId',
      accessor: (dataRow: any) => dataRow.get('employeeNumber'),
      Cell: (row: any) => <p className="text-left">
        {row.value}
      </p>,
      style: { fontWeight: 'lighter' },
    }); */

    // columns.push({
    //   Header: 'Turno',
    //   id: 'shift',
    //   accessor: (dataRow: any) => dataRow.get('shiftId'),
    //   Cell: (row: any) => (
    //     // toDo: use modal that anchors on selected cell
    //     // https://spectrum.chat/react-table/general/
    //     <select value={row.value.toString()} onChange={() => null}>
    //       {forkliftsConfiguration.get('shiftOptions').map((shift: any) => (
    //         <option
    //           value={shift.get('id').toString()}
    //           key={shift.get('id')}
    //         >
    //           {shift.get('description')}
    //         </option>
    //       )).toArray()}
    //     </select>),
    //   style: { textAlign: 'left', fontWeight: 'lighter' },
    // });

    // columns.push({
    //   Header: 'Actividad',
    //   id: 'role',
    //   accessor: (dataRow: any) => Map(
    //     {
    //       roleId: dataRow.get('roleId'),
    //       employeeNumber: dataRow.get('employeeNumber'),
    //     },
    //   ),
    //   Cell: (row: any) => (
    //     <SelectDropdown
    //       name={'activity'}
    //       value={forkliftsConfiguration
    //       .getIn(['roleOptions', row.value.get('roleId'), 'description'])}
    //       placeholder={'Actividad'}
    //       dropdownContent={forkliftsConfiguration
    //       .get('roleOptions').valueSeq().map((role: any) => ({
    //         value: role.get('description'), // role.get('id')
    //         label: role.get('description'),
    //       }))}
    //       onChange={() => undefined}
    //       handleClick={() => this.handleChangeActivity(
    //               row.value.get('employeeNumber'),
    //               role.get('id'),
    //               row.value.get('roleId'),
    //             )}
    //       highlightSelected={true}
    //       className={'activity-dropdown'}
    //       selectWidth={130}
    //       dropdownElementsWidth={180}
    //     >
    //       <p></p>
    //     </SelectDropdown>
    //   ),
    //   style: { textAlign: 'left', fontWeight: 'lighter' },
    // });

    // columns.push({
    //   Header: 'Asistencia',
    //   id: 'asistance',
    //   accessor: (dataRow: any) => dataRow.get('attendanceId'),
    //   Cell: (row: any) => (
    //     <SelectDropdown
    //       name={'attendance'}
    //       value={forkliftsConfiguration.get('attendanceOptions')
    //       .find((v: any) => v.get('id') === row.value)
    //       .get('description')}
    //       placeholder={'Asistencia'}
    //       dropdownContent={forkliftsConfiguration.get('attendanceOptions')
    //         .map((attendance: any) => (
    //         {
    //           value: attendance.get('description'),
    //           label: attendance.get('description'),
    //         }))
    //       }
    //       onChange={() => undefined}
    //       highlightSelected={true}
    //       className={'activity-dropdown'}
    //       selectWidth={130}
    //       dropdownElementsWidth={180}
    //     >
    //       <p></p>
    //     </SelectDropdown>
    //   ),
    //   style: { textAlign: 'left', fontWeight: 'lighter' },
    // });

    // configuration menu
    columns.push({
      id: 'configuration',
      Header: '',
      accessor: (row: any) => ({
        userSlug: row.get('slug'),
      }),
      Cell: this.renderConfigurationCell,
      width: 200,
    });

    return columns;
  }

  handleChangeActivity(employeeNumber: string, newActivityId: string, oldActivityId: string) {
    if (newActivityId === oldActivityId) return;

    this.props.handleChangeActivity(employeeNumber, newActivityId, oldActivityId);
  }

  disableUser(userSlug: string) {
    this.props.disableUser(userSlug);
  }

  renderConfigurationCell(row: any) {
    return (
      // Cell content with configuration menu dropdown dots
      <DefaultDropdown
        dropdownContent={[{
          label: (
            <div className="d-flex align-items-center">
              <DeleteIcon style={{ marginRight: 10 }} />
              <p className="text-light">Dar de baja</p>
            </div>),
          value: 'delete',
          onClickFunction: () => this.disableUser(row.value.userSlug),
        }]}
        className="configuration-dropdown"
        dropdownElementsWidth={150}
        repositioningValue={30}
      >
        <div style={{ paddingLeft: 10, paddingRight: 10 }}>
          <div className="config-dots d-flex flex-column">
            <ActiveDot color="#D8D8D8" width={4} />
            <ActiveDot color="#D8D8D8" width={4} />
            <ActiveDot color="#D8D8D8" width={4} />
          </div>
        </div>
      </DefaultDropdown>
    );
  }


  render() {
    const { workersConfiguration } = this.props;
    const columns = workersConfiguration
      && this.createTableColumns();
    const tableData = workersConfiguration
      && workersConfiguration.get('users').filter((v: any) => v.get('roleId') === UserType.Forklift);

    return (
      <div className="card forklifts-configuration">
        {workersConfiguration
          ? (
            <ReactTable
              data={tableData}
              columns={columns}
              pageSize={tableData.size}
              showPagination={false}
              className="-striped"
              NoDataComponent={() => null}
            />
          )
          : <LoadingSpinner height={200} />}
      </div>
    );
  }
}

type Props = {
  workersConfiguration: any;
  handleChangeActivity: Function;
  disableUser: Function;
};

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    disableUser,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ForkliftsConfiguration);
