import { fromJS } from 'immutable';
import {
  SET_AUTHENTICATED_USER,
  SET_USER_ACTIVITY_DETAIL,
  SET_USER_BOXES_BY_PRESENTATION,
  SET_USER_HEADER_INFO, SET_USER_WEEKLY_PAYMENT,
  SET_EDIT_PALLET_INFORMATION,
  DELETE_PALLET_FROM_USER_ACTIVITY_DETAIL,
} from '../actions/user.actions';

const INITIAL_STATE = fromJS({ isAuthenticating: true });

const userReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_AUTHENTICATED_USER:
      return fromJS(action.user).set('isAuthenticating', false);
    case SET_USER_HEADER_INFO:
      return state.update('userHeaderInfo', () => fromJS(action.info));
    case SET_USER_ACTIVITY_DETAIL:
      return state.update('userActivityDetail', () => fromJS(action.detail));
    case SET_USER_BOXES_BY_PRESENTATION:
      return state.update('userBoxesByPresentation', () => fromJS(action.presentation));
    case SET_USER_WEEKLY_PAYMENT:
      return state.update('userWeeklyPayment', () => fromJS(action.payment));
    case SET_EDIT_PALLET_INFORMATION:
      return state.update('userActivityDetail', (userActivityDetail: any) => {
        const palletIndex = userActivityDetail.findIndex((pallet: any) => pallet.get('id') === action.palletId);
        return userActivityDetail.updateIn(
          [palletIndex, 'presentation'],
          () => action.palletPresentation,
        ).updateIn(
          [palletIndex, 'palletSize'],
          () => action.palletSize,
        );
      });
    case DELETE_PALLET_FROM_USER_ACTIVITY_DETAIL:
      return state.update('userActivityDetail', (userActivityDetail: any) => userActivityDetail
        .filter((pallet: any) => pallet.get('id') !== action.palletId));
    default:
      return state;
  }
};

export default userReducer;
