/* eslint-disable no-undef */
import React, { Component } from 'react';
import request from '../utils/request';

export default class TokenAccess extends Component {
  async componentDidMount() {
    // TODO: Fix this to actually use the token
    await request.get('/access/5be4dc0c-776e-4675-9e42-7b3d3796d9e');
    window.location.replace('/');
  }

  render() {
    return (
      <p style={{ textAlign: 'center', marginTop: '20px' }}>Iniciando sesión</p>
    );
  }
}
