/* eslint-disable no-undef */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import { applyMiddleware, compose, createStore } from 'redux';
import { Provider } from 'react-redux';
import ReduxThunk from 'redux-thunk';
import rootReducer from './reducers';
import * as serviceWorker from './serviceWorker';
import './styles/application.scss';
import 'react-dropdown/style.css';
import { isUserAuthenticated } from './actions/user.actions';
import Routes from './routes';
import UnsupportedBrowser from './pages/UnsupportedBrowser';
import RequestStatus from './components/RequestStatus';

let ErrorBoundary = (p: any) => p.children;
if (process.env.REACT_APP_ENABLE_BUGSNAG === 'true') {
  const bugsnagClient = bugsnag(process.env.REACT_APP_BUGSNAG_KEY as string);
  bugsnagClient.use(bugsnagReact, React);
  ErrorBoundary = bugsnagClient.getPlugin('react');
}

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// eslint-disable-next-line import/prefer-default-export
export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(ReduxThunk)));

(async () => {
  await store.dispatch(isUserAuthenticated());
})();

const App: any = () => {
  let isIe = false;
  if (navigator.userAgent.includes('Trident') || navigator.userAgent.includes('MSIE')) {
    isIe = true;
  }

  return (
    <ErrorBoundary>
      {isIe
        ? <UnsupportedBrowser />
        : (
          <Provider store={store}>
            <Routes />
            <RequestStatus />
          </Provider>
        )}
    </ErrorBoundary>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
