import React, { Component } from 'react';
import { Collection } from 'immutable';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { ReactComponent as TrophyIcon } from '../../../../assets/icons/trophy.svg';
import { ReactComponent as BoxLightIcon } from '../../../../assets/icons/box-light.svg';
import { ReactComponent as UserIcon } from '../../../../assets/icons/user.svg';
import { ReactComponent as BoxWarningIcon } from '../../../../assets/icons/box-warning.svg';

import Separator from '../../../../components/Separator';
import { getMostLeastProductiveAgencies } from '../../../../actions/corporateHome.actions';
import { AgencyInMostLeastProductiveAgencies } from '../../../../types/types-enums-interfaces';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { unitMapToSpanish } from '../../../../constants';
import {
  selectCorporateHomeLeastProductiveAgencies,
  selectCorporateHomeMostLeastProductiveAgenciesUnit,
  selectCorporateHomeMostProductiveAgencies,
  selectTimeFilterForLocation,
} from '../../../../selectors';

import './MostLeastProductiveAgencies.scss';

type Props = {
  dispatch: any;
  currentZoneSlug: string;
  // store
  mostProductiveAgencies: Collection<number, AgencyInMostLeastProductiveAgencies>;
  leastProductiveAgencies: Collection<number, AgencyInMostLeastProductiveAgencies>;
  unit: string;
  timeFilters: any;
};
type State = {
  fetchingData: boolean;
};

class MostLeastProductiveAgencies extends Component<Props, State> {
  static calculateGrowth(oldFigure: number, newFigure: number): string {
    if (!oldFigure || !newFigure) return 'N/A';

    let growth = ((newFigure / oldFigure) - 1) * 100;

    // rounding growth to two decimal places
    growth = Math.round(growth * 100) / 100;

    if (growth > 0) return `+${String(growth)}`;
    if (growth === 0) return String(growth);
    return `-${String(1 - growth)}`;
  }
  constructor(props: Props) {
    super(props);
    this.state = {
      fetchingData: false,
    };
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount(): void {
    if (this.props.mostProductiveAgencies && this.props.leastProductiveAgencies) {
      return this.fetchData(false);
    }
    this.fetchData();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.currentZoneSlug !== prevProps.currentZoneSlug) this.fetchData();

    if (this.props.timeFilters !== prevProps.timeFilters) this.fetchData();
  }

  fetchData(showSpinner = true) {
    if (showSpinner) this.setState({ fetchingData: true });

    if (this.props.timeFilters) {
      return this.props.dispatch(getMostLeastProductiveAgencies(
        this.props.currentZoneSlug,
        this.props.timeFilters.get('startDate'),
        this.props.timeFilters.get('endDate'),
      )).then(() => this.setState({ fetchingData: false }));
    }
    return this.props.dispatch(getMostLeastProductiveAgencies(this.props.currentZoneSlug))
      .then(() => {
        this.setState({ fetchingData: false });
      });
  }

  render() {
    const { mostProductiveAgencies, leastProductiveAgencies, unit } = this.props;
    const { fetchingData } = this.state;

    return (
      <>
        {/* Most productive */}
        <div className="col-12 col-lg-6">
          <div className="card" style={{ border: '1.5px solid #9CBFF5' }}>
            {/* header */}
            <div className="row align-items-center py-1 mx-3">
              <p style={{ color: '#4D607E' }} className="font-bold text-smaller">
                Top 3 Productividad
              </p>
              <TrophyIcon style={{ marginLeft: 8 }} />
            </div>

            <Separator />
            {/* Agencies */}
            {mostProductiveAgencies && !fetchingData
              ? mostProductiveAgencies.map((agency: any, i: number) => (
                <div className="row no-gutters align-items-center py-1 mx-3" key={agency}>
                  {/* col 1 */}
                  <div className="col-6 text-smallest">
                    <Link to={`/distribution-centers/${agency.getIn(['distCenter', 'slug'])}`}>
                      <p>{i + 1}. Agencia {agency.getIn(['distCenter', 'name'])}</p>
                      <p style={{ textIndent: '1em' }}>
                        <BoxLightIcon style={{ width: 10 }} />
                        <span className="font-bold ml-2">
                          {agency.get('quantity', '').toLocaleString()} {unitMapToSpanish[unit]}
                        </span> seleccionadas
                      </p>
                      <p style={{ textIndent: '1em' }}>
                        <UserIcon style={{ width: 10 }} />
                        <span
                          className="font-bold ml-2"
                        >{agency.get('numOfSorters')}
                        </span> pickers
                      </p>
                    </Link>
                  </div>

                  {/* col 2 */}
                  <div className="col-4 text-smallest">
                    <p className="text-muted">Productividad</p>
                    <p>Hoy:&nbsp;
                      <span className="font-bold">{agency.get('currentPeriodProductivity',
                        0).toLocaleString()}
                      </span>
                    </p>
                    <p className="text-muted">Ayer:&nbsp;
                      <span>{agency.get('lastPeriodProductivity', 0)
                        .toLocaleString()}
                      </span>
                    </p>
                  </div>

                  {/* growth */}
                  <div className="col-2">
                    <h5 style={{ color: '#0D63D6' }}>
                      {MostLeastProductiveAgencies.calculateGrowth(agency.get('lastPeriodProductivity'),
                        agency.get('currentPeriodProductivity'))}%
                    </h5>
                  </div>

                  {i < (mostProductiveAgencies.count() - 1) && <Separator dashed />}
                </div>
              ))
              : <LoadingSpinner style={{ marginTop: 40 }} />}
          </div>
        </div>

        {/* least productive */}
        <div className="col-12 col-lg-6 mt-3 mt-lg-0">
          <div className="card" style={{ border: '1.5px solid #F1BB97' }}>
            {/* header */}
            <div className="row align-items-center py-1 mx-3">
              <p style={{ color: '#4D607E' }} className="font-bold text-smaller">
                Últimos 3 Productividad
              </p>
            </div>

            <Separator />

            {/* agencies */}
            {leastProductiveAgencies
            && !fetchingData ? leastProductiveAgencies.map((agency: any, i: number) => (
              <div className="row no-gutters align-items-center py-1 mx-3" key={agency}>
                {/* col 1 */}
                <div className="col-6 text-smallest">
                  <Link to={`/distribution-centers/${agency.getIn(['distCenter', 'slug'])}`}>
                    <p>{i + 1}. Agencia {agency.getIn(['distCenter', 'name'])}</p>
                    <p style={{ textIndent: '1em' }}>
                      <BoxWarningIcon style={{ width: 10 }} />
                      <span className="font-bold ml-2">
                        {agency.get('quantity', 0).toLocaleString()} {unitMapToSpanish[unit]}
                      </span> seleccionadas
                    </p>
                    <p style={{ textIndent: '1em' }}>
                      <UserIcon style={{ width: 10 }} />
                      <span className="font-bold ml-2">{agency.get('numOfSorters')}</span> pickers
                    </p>
                  </Link>
                </div>

                {/* col 2 */}
                <div className="col-4 text-smallest">
                  <p className="text-muted">Productividad</p>
                  <p>Hoy:&nbsp;
                    <span className="font-bold">{agency.get('currentPeriodProductivity', 0)
                      .toLocaleString()}
                    </span>
                  </p>
                  <p className="text-muted">Ayer:&nbsp;
                    <span>{agency.get('lastPeriodProductivity', 0)
                      .toLocaleString()}
                    </span>
                  </p>
                </div>

                {/* growth */}
                <div className="col-2">
                  <h5 style={{ color: '#F28134' }}>
                    {MostLeastProductiveAgencies.calculateGrowth(agency.get('lastPeriodProductivity'),
                      agency.get('currentPeriodProductivity'))}%
                  </h5>
                </div>

                {i < (leastProductiveAgencies.count() - 1) && <Separator dashed />}
              </div>
              ))
              : <LoadingSpinner style={{ marginTop: 40 }} />}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  mostProductiveAgencies: selectCorporateHomeMostProductiveAgencies(state),
  leastProductiveAgencies: selectCorporateHomeLeastProductiveAgencies(state),
  unit: selectCorporateHomeMostLeastProductiveAgenciesUnit(state),
  timeFilters: selectTimeFilterForLocation(state, 'corporate'),
});

export default connect(mapStateToProps)(MostLeastProductiveAgencies);
