import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Map } from 'immutable';

import ContentDivisionHeader from '../../../components/Layout/ContentDivisionHeader';
import SortersConfiguration from './SortersConfiguration';
import CreateAppUser from './CreateAppUser';
import ForkliftsConfiguration from './ForkliftsConfiguration';
import {
  changeWorkerActivity,
  getWorkersConfiguration,
  setWorkersConfiguration,
} from '../../../actions/configuration.actions';
import { selectDistributionCenterInfo, selectWorkersConfiguration } from '../../../selectors';

import './UsersConfiguration.scss';

class UsersConfiguration extends PureComponent<Props, {}> {
  componentDidMount() {
    this.props.getWorkersConfiguration();
    this.handleChangeActivity = this.handleChangeActivity.bind(this);
  }

  componentDidUpdate(prevProps: any) {
    if (
      this.props.currentDistributionCenter
      && (
        !prevProps.currentDistributionCenter
        || this.props.currentDistributionCenter.get('slug') !== prevProps.currentDistributionCenter.get(
          'slug',
        )
      )
    ) {
      this.props.setWorkersConfiguration(undefined);
      this.props.getWorkersConfiguration();
    }
  }

  handleChangeActivity(employeeNumber: string, newActivityId: string, oldActivityId: string) {
    if (!employeeNumber || !newActivityId || !oldActivityId) return;
    this.props.changeWorkerActivity(employeeNumber, newActivityId, oldActivityId);
  }

  render() {
    return (
      <div className="main-content-margin">
        <CreateAppUser />

        <ContentDivisionHeader title="Seleccionadores & Estibadores" />
        <SortersConfiguration
          workersConfiguration={this.props.workersConfiguration}
          handleChangeActivity={this.handleChangeActivity}
        />

        <ContentDivisionHeader title="Montacarguistas" />
        <ForkliftsConfiguration
          workersConfiguration={this.props.workersConfiguration}
          handleChangeActivity={this.handleChangeActivity}
        />
      </div>
    );
  }
}

type Props = {
  // store
  workersConfiguration: Map<any, any>;
  currentDistributionCenter: any;
  // dispatch
  setWorkersConfiguration: Function;
  getWorkersConfiguration: Function;
  changeWorkerActivity: Function;
};

function mapStateToProps(state: any) {
  return {
    workersConfiguration: selectWorkersConfiguration(state),
    currentDistributionCenter: selectDistributionCenterInfo(state),
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    setWorkersConfiguration,
    getWorkersConfiguration,
    changeWorkerActivity,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersConfiguration);
