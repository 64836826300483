import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ReactComponent as GoalIcon } from '../../../../assets/icons/goal.svg';

import DoughnutChart from '../../../../components/Charts/DoughnutChart';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { getDCDailyGoal } from '../../../../actions/homeDashboard.actions';
import {
  selectDailyGoal,
  selectHomeCurrentDistCenter,
  selectTimeFilterForLocation,
} from '../../../../selectors';

class DCGoals extends Component<Props, any> {
  DCGoalsGraph: any;

  constructor(props: any) {
    super(props);
    // @ts-ignore
    this.state = {
      fetchingData: false,
    };
  }

  componentDidMount() {
    if (this.props.dailyProgress) return this.fetchData(false);
    this.fetchData();
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.currentDistCenter !== this.props.currentDistCenter) {
      this.fetchData();
    }

    // get data
    if (prevProps.timeFilter !== this.props.timeFilter) {
      this.fetchData();
    }

    if (prevProps.selectedShift !== this.props.selectedShift) {
      this.fetchData();
    }
  }

  fetchData(showSpinner = true) {
    if (showSpinner) this.setState({ fetchingData: true });

    if (this.props.timeFilter) {
      return this.props.dispatch(getDCDailyGoal(
        this.props.timeFilter.get('startDate'), this.props.timeFilter.get('endDate'),
      )).then(() => this.setState({ fetchingData: false }));
    }

    return this.props.dispatch(getDCDailyGoal()).then(() => this.setState({ fetchingData: false }));
  }

  render() {
    const { dailyProgress } = this.props;
    const { fetchingData } = this.state;
    const totalGoal = ((dailyProgress && (dailyProgress.get('totalGoal') * dailyProgress.get('sorters'))) || 0);
    const totalSorted = ((dailyProgress && dailyProgress.get('totalSortedBoxes')) || 0);
    return (
      dailyProgress && !fetchingData
        ? (
          <div className="card dc-goals no-gutters">
            <div className="col-sm">
              <div className="row goals-header no-gutters">
                <GoalIcon style={{ width: 20, height: 20 }} className="card-image" />
                <p className="font-bold"> Meta Total de Agencia </p>
              </div>
              <div className="goals-content">
                <div className="row">
                  <p>
                    <span className="font-bold">{totalGoal.toLocaleString()}</span> cajas
                  </p>
                </div>

                <div className="row">
                  <p> progreso:&nbsp;
                    <span className="highlight-text">
                      {totalSorted.toLocaleString()}
                    </span>
                  </p>
                </div>

                <div className="row boxes-missing">
                  <p> Cajas por seleccionar: {(totalGoal - totalSorted).toLocaleString()} </p>
                </div>
              </div>
            </div>

            {/* chart */}
            <div className="col-sm chart-container">
              <p className="subheader-chart-info"> Completo </p>
              <DoughnutChart
                currentValue={totalSorted}
                totalValue={totalGoal}
              />
            </div>
          </div>
        )
        : <div className="card"><LoadingSpinner height={300} /></div>);
  }
}

type Props = {
  dispatch: any;
  // store
  dailyProgress: any;
  currentDistCenter: string;
  timeFilter: any;
  selectedShift: any;
};

function mapStateToProps(state: any) {
  return {
    dailyProgress: selectDailyGoal(state),
    currentDistCenter: selectHomeCurrentDistCenter(state),
    timeFilter: selectTimeFilterForLocation(state, 'homeAndSortingDashboard'),
    selectedShift: state.getIn(['dataFilter', 'selectedShift']),
  };
}

export default connect(mapStateToProps)(DCGoals);
