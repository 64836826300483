import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router-dom';
import {
  selectisAuthenticating,
  selectUserDistributionCenters,
  selectUserEmail,
  selectUserType,
} from '../selectors';
import { PAGE, UserType } from '../types/types-enums-interfaces';

class AuthWrapper extends Component<any, any> {
  render() {
    const { userDistCenters, userType, user, isAuthenticating, path, location } = this.props;
    const currentPath = location.pathname;

    // still authenticating
    if (isAuthenticating) {
      return <p style={{ textAlign: 'center', marginTop: '20px' }}>Cargando...</p>;
    }

    // Redirect dist.center manager to DistCenterWrapper (1st dist. center)
    if (userType === UserType.DistributionCenterManager) {
      if (!currentPath.includes(PAGE.DIST_CENTER_HOME)) {
        return (
          <Redirect
            to={`/distribution-centers/${userDistCenters.getIn([0, 'slug'])}`}
          />
        ); // On elseif Redirect corporate manager to CorporateWrapper
      } // (unless he's trying to get to a dist center)
    } else if (userType === UserType.Corporate && !currentPath.includes(PAGE.DIST_CENTER_HOME)) {
      if (!currentPath.includes(PAGE.CORPORATE_HOME)) {
        return (<Redirect to="/corporate" />);
      }
    }

    // After redirecting to suitable AuthWrapper, TAKE THE USER TO THE ROUTE
    if (user) return <Route path={path} component={this.props.component} />;

    // in case of no user
    return (<Redirect to="/login" />);
  }
}

function mapStateToProps(state: any) {
  return {
    isAuthenticating: selectisAuthenticating(state),
    user: !!selectUserEmail(state),
    userType: selectUserType(state),
    userDistCenters: selectUserDistributionCenters(state),
  };
}

export default connect(mapStateToProps)(withRouter(AuthWrapper));
