/**
 * Naming Conventions:
 * selectYOUR_VARIABLE_HERE: a non-memoized simple getter
 * YOUR_VAR_Selector: a memoized selector created by using a createSelector from reselect
 * Decided not to use a custom createSelector that uses immutable.is to compare equality as
 * it's expensive. Reducers should not return a new state unless stuff changed; i.e. use update
 * instead of set whenever possible in the reducers.
 * */

/* Getters:
__________________________________ */
import { createSelector } from 'reselect';

// distribution center
export const selectDistributionCenterInfo = (state: any) => state.getIn(['distributionCenter']);

// user
export const selectisAuthenticating = (state: any) => state.getIn(['user', 'isAuthenticating']);

export const selectUserBoxesByPresentation = (state: any) => state.getIn(['user', 'userBoxesByPresentation']);

export const selectUserWeeklyPayment = (state: any) => state.getIn(['user', 'userWeeklyPayment']);

export const selectUserTotalCompensation = (state: any) => state.getIn(['user', 'userBoxesByPresentation', 'total-compensation']);

export const selectUserEmail = (state: any) => state.getIn(['user', 'email']);

export const selectUserName = (state: any) => state.getIn(['user', 'name']);

export const selectUserSlug = (state: any) => state.getIn(['user', 'slug']);

export const selectUserHeaderInfo = (state: any) => state.getIn(['user', 'userHeaderInfo']);

export const selectUserType = (state: any) => state.getIn(['user', 'type']);

export const selectUserActivityDetail = (state: any) => state.getIn(['user', 'userActivityDetail']);

export const selectUserDistributionCenters = (state: any) => state.getIn(['user', 'distributionCenters']);

// configuration
export const selectWorkersConfiguration = (state: any) => state.getIn(['configuration', 'workersConfiguration']);

export const selectWorkersCompensation = (state: any) => state.getIn(['configuration', 'workersCompensation']);

// home dashboard
export const selectDailyGoal = (state: any) => state.getIn(['homeDashboard', 'dailyGoal']);

export const selectHomeSortersTotalSelection = (state: any) => state.getIn(['homeDashboard', 'sortersTotalSelection']);

export const selectHomeTimeChart = (state: any) => state.getIn(['homeDashboard', 'timeChart']);

export const selectHomeCurrentDistCenter = (state: any) => state.getIn(['homeDashboard', 'currentDistCenter']);

// sorting dashboard
export const selectSortingDashboardPresentationTotals = (state: any) => state.getIn(['sortingDashboard', 'presentationTotals']);

export const selectSortingDashboardsortersSummary = (state: any) => state.getIn(['sortingDashboard', 'sortersSummary']);

export const selectSortingDashboardSortersPresentationTotals = (state: any) => state.getIn(['sortingDashboard', 'sortersPresentationTotals']);

export const selectSortingDashboardSortersSelectionDetail = (state: any) => state.getIn(['sortingDashboard', 'sortersSelectionDetail']);

export const selectSortingDashboardsortingHourlyGoals = (state: any) => state.getIn(['sortingDashboard', 'sortingHourlyGoals']);

export const selectSortingDashboardsortersKPIs = (state: any) => state.getIn(['sortingDashboard', 'sortersKPIs']);

// data filter
export const selectTimeFilterForLocation = (state: any, location: string) => state.getIn(['dataFilter', location, 'time']);

export const selectProductUnitForLocation = (state: any, location: string) => state.getIn(['dataFilter', location, 'productUnit']);

export const selectCalendarDropdownValueForLocation = (state: any, location: string) => state.getIn(
  ['dataFilter', location, 'dropdownValue'],
);

export const selectCurrentZoneCorporate = (state: any) => state.getIn([
  'dataFilter', 'corporate', 'currentZone',
]);

// corporate Home
const selectCorporateHome = (state: any) => state.get('corporateHome');

export const selectWorkersCount = createSelector(
  selectCorporateHome,
  corporateHome => corporateHome && corporateHome.get('employeesCount'),
);

export const selectProductivityChartData = createSelector(
  selectCorporateHome,
  corporateHome => corporateHome && corporateHome.get('productivityChartData'),
);

const selectMostLeastProductiveAgencies = createSelector(
  selectCorporateHome,
  corporateHome => corporateHome.get('mostLeastProductiveAgencies'),
);

export const selectCorporateHomeMostProductiveAgencies = createSelector(
  selectMostLeastProductiveAgencies,
  mostLeast => mostLeast && mostLeast.get('TopThree'),
);

export const selectCorporateHomeLeastProductiveAgencies = createSelector(
  selectMostLeastProductiveAgencies,
  mostLeast => mostLeast && mostLeast.get('WorstThree'),
);

export const selectCorporateHomeMostLeastProductiveAgenciesUnit = createSelector(
  selectMostLeastProductiveAgencies,
  mostLeast => mostLeast && mostLeast.get('unit'),
);

const selectMostLeastProductiveWorkers = createSelector(
  selectCorporateHome,
  corporateHome => corporateHome.get('mostLeastProductiveWorkers'),
);

export const selectCorporateHomeMostProductiveWorker = createSelector(
  selectMostLeastProductiveWorkers,
  mostLeast => mostLeast && mostLeast.get('mostProductive'),
);

export const selectCorporateHomeLeastProductiveWorker = createSelector(
  selectMostLeastProductiveWorkers,
  mostLeast => mostLeast && mostLeast.get('leastProductive'),
);

export const selectCorporateHomeUnit = createSelector(
  selectMostLeastProductiveWorkers,
  mostLeast => mostLeast && mostLeast.get('unit'),
);

export const selectZones = createSelector(
  selectCorporateHome,
  corporateHome => corporateHome.get('zones'),
);

// api requests:
export const selectApiRequests = (state: any) => state.get('apiRequests');

export const selectGetRequests = createSelector(
  selectApiRequests,
  apiRequests => apiRequests && apiRequests.get('GET'),
);

export const selectNonGetRequests = createSelector(
  selectApiRequests,
  apiRequests => apiRequests && apiRequests.filter((v: any, method: string) => method !== 'GET')
    .toMap()
    .flatten(1),
);

// corporate agencies
export const selectCorporateAgencies = (state: any) => state.get('corporateAgencies');

export const selectCorporateAgenciesZones = createSelector(
  selectCorporateAgencies,
  corporateAgencies => corporateAgencies && corporateAgencies.get('zonesTotals'),
);
