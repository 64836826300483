import React, { FC, useEffect, useState } from 'react';
import LineChart from '../../../../components/Charts/LineChart';
import './UserLineChart.scss';

interface Props {
  data: any;
  labels: any[];
  period?: any;
  isAreaChart: boolean;
  isCheckBox: boolean;
  // lastPeriodProgressDetail: any;
}

const UserLineChart: FC<Props> = props => {
  const colors: any[] = [
    'rgba(147,177,247, 0.8)',
    'rgba(118,226,238, 0.8)',
    'rgba(174,129,244, 0.8)',
    'rgba(217,235,240, 0.8)',
    'rgba(240, 137, 142, 0.8)',
    'rgba(240,220,138, 0.8)',
    'rgba(30,110,78, 0.8)',
    'rgba(36,50,110, 0.8)',
    'rgba(110,97,103, 0.8)',
    'rgba(110,63,19, 0.8)',
    'rgba(0,0,0, 0.8)',
    'rgba(133,133,133, 0.8)',
  ];

  const {
    data,
    period,
    labels,
    isAreaChart,
    isCheckBox,
  } = props;

  const [checkBoxes, setCheckBoxes] = useState<string[]>([]);
  const [options, setOptions] = useState<any>([]);
  const [optionColors, setOptionColors] = useState<any>([]);
  const [todos, toggleTodos] = useState<boolean>(true);
  const [lineDataSet, setDataSet] = useState<any>([]);
  const [suggestedYAxisMax, setSuggestedYAxisMax] = useState<number>(1);
  const [dataReady, setDataReady] = useState<boolean>(false);

  useEffect(() => {
    const actualOptions = data.valueSeq().map((o: any) => o.get('name'));
    setOptions(actualOptions);
    setCheckBoxes(actualOptions);
    // checkbox & chart colors
    setOptionColors(actualOptions.map((presentationName: string, i: number) => ({
      presentationName,
      color: colors[i],
    })));
  }, []);

  useEffect(() => {
    const actualOptions = data.valueSeq().map((o: any) => o.get('name'));
    setOptions(actualOptions);
    setCheckBoxes(actualOptions);
    // checkbox & chart colors
    setOptionColors(actualOptions.map((presentationName: string, i: number) => ({
      presentationName,
      color: colors[i],
    })));
  }, [data]);

  async function generateDataSets(boxes: any, presentationsToDisplay: any = []) {
    if (!boxes) return;

    // mock data if needed
    // const mockCopy = await generateMockData();

    // filter out unwanted presentations
    const filteredBoxes = boxes.filter((o: any) => presentationsToDisplay.includes(o.get('name')));

    // a dataset per area line
    return filteredBoxes.map((presentation: any) => {
      const presentationColor = optionColors.find((o: any) => o.presentationName === presentation.get('name')).color;
      return {
        lineTension: isAreaChart ? 0.55 : 0,
        fill: isAreaChart,
        pointRadius: 3,
        borderWidth: isAreaChart ? 0.1 : 2,
        // change the colors' opacity
        pointBackgroundColor: `${presentationColor.slice(0, -6)}, 0.2)`,
        pointBorderColor: `${presentationColor.slice(0, -6)}, 1)`,
        pointBorderWidth: 1,
        borderColor: presentationColor,
        backgroundColor: presentationColor,
        data: presentation.get('boxesByTime'),
      };
    });
  }

  // change checkboxes choice
  useEffect(() => {
    setDataReady(false);

    generateDataSets(data, checkBoxes).then(currentDataSet => {
      setDataSet([currentDataSet]);
      setDataReady(true);
    });
  }, [checkBoxes, data]);

  // set suggested maximum value for Y axis
  useEffect(() => {
    // set max value for Y axis to fix it across switching presentations
    const suggestedMaxMap: any = {};
    data.forEach((presentation: any) => {
      presentation.get('boxesByTime').forEach((n: number, i: number) => {
        suggestedMaxMap[i] = (suggestedMaxMap[i] || 0) + n;
      });
    });
    setSuggestedYAxisMax(Math.max.apply(null, Object.values(suggestedMaxMap)));
  }, [period, data]);

  /* async function generateMockData() {
    // generate mock data1
    const mockCopy: any = {};
    ['Mega', 'Media', 'Premium', 'Cuartito', 'Familiar'].forEach((presentationName: string) => {
      mockCopy[presentationName] = [];
      for (let i = 0; i < (1000 + Math.round(Math.random() * 1000)); i++) {
        const randomDateString = new Date(
          2019,
          Math.round(Math.random() * 8),
          Math.round(1 + Math.random() * 30),
          Math.round(Math.random() * 24),
          Math.round(Math.random() * 60),
          Math.round(Math.random() * 60),
          Math.round(Math.random() * 1000)
        ).toISOString();

        const randomXYPair = { x: randomDateString, y: 30 + Math.round(Math.random() * 123) };

        mockCopy[presentationName].push(randomXYPair);
      }
    });
    return mockCopy;
  } */

  function handleChecboxOnClick(option: string) {
    if (checkBoxes.indexOf(option) === -1) {
      setCheckBoxes([...checkBoxes, option]);
    } else {
      setCheckBoxes(checkBoxes.filter((element: string) => element !== option));
    }
  }

  function handleTodosOnClick() {
    toggleTodos(!todos);
    setCheckBoxes(options);
  }

  function renderLineOptions() {
    return options.map((option: string) => {
      const optionColor = optionColors.find((o: any) => o.presentationName === option).color;

      return (
        <div className="chechbox-container" key={option}>
          {isCheckBox ? (
            <input
              onChange={() => handleChecboxOnClick(option)}
              type="checkbox"
              checked={checkBoxes.indexOf(option) !== -1}
              disabled={todos}
            />
          )
            : (
              <div
                style={{ width: 10, height: 10, borderRadius: 3, backgroundColor: optionColor }}
              />
            )}
          <p
            className="styleText"
            style={{
              borderBottom: `solid 1.5px ${optionColor}`,
            }}
          >{option}
          </p>
        </div>
      );
    });
  }

  return (
    <>
      {/* Checkboxes */}
      <div className="checkboxes-container">
        <div className="title">
          <p className="title-string">cajas</p>
          <div style={{ height: 10, width: 1, border: '1px solid #2574FB' }} />
        </div>
        <div className="checkboxes flex-wrap">
          {isCheckBox && (
          <div className="chechbox-container">
            <input
              onChange={() => handleTodosOnClick()}
              checked={todos}
              type="checkbox"
            />
            <p className="styleText">Todos</p>
          </div>
          )}
          {renderLineOptions()}
        </div>
      </div>

      {/* chart */}
      {dataReady ? lineDataSet.map((lineData: any, i: number) => (
        <LineChart
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          areaChart={isAreaChart}
          height={300}
          data={lineData.toJS()}
          labels={labels}
          yAxisSuggestedMax={suggestedYAxisMax}
        />
      ))
        : null}
    </>
  );
};

export default UserLineChart;
