import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ReactComponent as CheckIcon } from '../../../../assets/icons/check.svg';

import LineInput from '../../../../components/Input/LineInput';
import SelectDropdown from '../../../../components/Dropdown/SelectDropdown';
import BasicButton from '../../../../components/Button/BasicButton';
import createAppUser from '../../../../actions/appUser.actions';
import { selectDistributionCenterInfo } from '../../../../selectors';

import './CreateAppUser.scss';

class CreateAppUser extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      name: '',
      employeeNumber: undefined,
      shift: '',
      activity: '',
      creating: false,
      errors: {},
      successfullyCreated: false,
      displayForm: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleEmployeeNumberChange = this.handleEmployeeNumberChange.bind(this);
    this.saveEmployee = this.saveEmployee.bind(this);
    this.changeDisplayForm = this.changeDisplayForm.bind(this);
  }

  changeDisplayForm() {
    this.setState({ displayForm: true });
  }

  handleInputChange(e: any) {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  }

  handleEmployeeNumberChange(e: any) {
    if ((/\D/ig).test(e.target.value)) return;
    this.handleInputChange(e);
  }

  async saveEmployee() {
    this.setState({ errors: {} });
    if (this.state.employeeNumber
      && (this.state.employeeNumber.toString().length !== 6 || this.state.employeeNumber < 0)) {
      this.setState({ errors: { employeeNumber: { msg: 'Número no válido' } } });
      return null;
    }
    this.setState({ creating: true, errors: {} });
    try {
      await this.props.createAppUser({
        name: this.state.name,
        employeeNumber: this.state.employeeNumber,
        activity: this.state.activity,
        shift: this.state.shift,
      });
      this.setState({
        successfullyCreated: true,
        name: '',
        employeeNumber: undefined,
        shift: '',
        activity: '',
        creating: false,
        displayForm: false,
      });
      setTimeout(() => this.setState({ successfullyCreated: false }), 3000);
    } catch (error) {
      if ((typeof error.response.data) !== 'object') {
        this.setState({ errors: { message: 'Error de red' }, creating: false });
      } else {
        this.setState({ errors: error.response.data, creating: false });
      }
    }
  }

  render() {
    const { generalInfo } = this.props;
    const { errors } = this.state;
    let nonValidationError = '';
    if (!errors.employeeNumber && !errors.user && errors.message) {
      nonValidationError = errors.message;
    }
    return (
      <div className="card create-app-user padding">
        {this.state.displayForm
          ? (
            <div>
              <div style={{ height: '24px' }}>
                {nonValidationError ? <p className="error-message">{nonValidationError}</p>
                  : null}
              </div>
              <div className="upper-section">
                <LineInput
                  onChange={this.handleInputChange}
                  placeholder="Nombre"
                  name="name"
                  lineWidth="200px"
                  error={errors.name && errors.name.msg}
                  value={this.state.name}
                />
                <LineInput
                  onChange={this.handleEmployeeNumberChange}
                  placeholder="# de Empleado"
                  name="employeeNumber"
                  error={(errors.employeeNumber && errors.employeeNumber.msg)
              || (errors.user && errors.user.msg)}
                  min="0"
                  value={this.state.employeeNumber}
                />
                {generalInfo && generalInfo.get('roles')
            && (
              <>
                <SelectDropdown
                  name="activity"
                  value={this.state.activity}
                  dropdownContent={generalInfo.get('roles')
                    .valueSeq()
                    .map((role: any) => ({
                      value: role.get('slug'),
                      label: role.get('name'),
                    }))}
                  onChange={this.handleInputChange}
                  placeholder="Elegir Actividad"
                  style={{ marginBottom: '24px' }}
                  dropdownElementsWidth={190}
                  selectWidth={190}
                  highlightSelected
                >
                  <p />
                </SelectDropdown>
                <SelectDropdown
                  name="shift"
                  value={this.state.shift}
                  dropdownContent={generalInfo.get('shifts')
                    .valueSeq()
                    .map((shift: any) => ({
                      value: shift.get('slug'),
                      label: shift.get('name'),
                    }))}
                  onChange={this.handleInputChange}
                  placeholder="Turno"
                  style={{ marginBottom: '24px' }}
                  dropdownElementsWidth={190}
                  selectWidth={190}
                  highlightSelected
                >
                  <p />
                </SelectDropdown>
              </>
            )}
                <BasicButton
                  text={this.state.creating ? 'Creando usuario' : 'Agregar'}
                  onClick={this.saveEmployee}
                  disabled={!(this.state.name && this.state.employeeNumber
                && this.state.activity && this.state.shift)}
                  style={{ fontSize: '15px', width: '180px', marginBottom: 24 }}
                />
              </div>
            </div>
          )
          : this.state.successfullyCreated
            ? (
              <div className="successfully-created">
                <CheckIcon />
                <p>Usuario creado exitosamente</p>
              </div>
            )
            : (
              <div className="new-user-button-section">
                <p>Usuarios App</p>
                <BasicButton
                  text="Nuevo usuario"
                  onClick={this.changeDisplayForm}
                />
              </div>
            )}
      </div>
    );
  }
}

type Props = {
  // store
  generalInfo: any;
  // dispatch
  createAppUser: any;
};

type State = {
  name: string;
  employeeNumber: number | undefined;
  shift: string;
  activity: string;
  creating: boolean;
  errors: any;
  successfullyCreated: boolean;
  displayForm: boolean;
};

function mapStateToProps(state: any) {
  return {
    generalInfo: selectDistributionCenterInfo(state),
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    createAppUser,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAppUser);
