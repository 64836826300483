import { combineReducers } from 'redux-immutable';

import userReducer from './users.reducer';
import homeDashboardReducer from './homeDashboard.reducer';
import sortingDashboardReducer from './sortingDashboard.reducer';
import dataFilterReducer from './dataFilter.reducer';
import configurationReducer from './configuration.reducer';
import corporateHomeReducer from './corporateHome.reducer';
import distributionCenterReducer from './distributionCenter.reducer';
import apiRequestsReducer from './apiRequests.reducer';
import corporateAgenciesReducer from './corporateAgencies.reducer';

const rootReducer = combineReducers({
  user: userReducer,
  homeDashboard: homeDashboardReducer,
  sortingDashboard: sortingDashboardReducer,
  dataFilter: dataFilterReducer,
  configuration: configurationReducer,
  distributionCenter: distributionCenterReducer,
  corporateHome: corporateHomeReducer,
  apiRequests: apiRequestsReducer,
  corporateAgencies: corporateAgenciesReducer,
});

export default rootReducer;
