/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-shadow */
import React, { FC } from 'react';
import { fromJS, List } from 'immutable';
import './BenchBarChart.scss';
import BarChart from '../../../../components/Charts/BarChart';


const BenchBarChart: FC<Props> = props => {
  const chartOptions = {
    events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
    myOnClick: (event: any, chtElement: any, ctx: any) => {
      const e = chtElement[0];
      if (e) {
        // eslint-disable-next-line no-underscore-dangle
        const xValue = ctx.data.labels[e._index];
        props.onClick(xValue);
      }
    },
    scales: {
      yAxes: [{
        ticks: {
          callback: (value: any) => Math.round(value),
        },
      }],
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem: any) => `${Math.round((Number(tooltipItem.value) + Number.EPSILON) * 100) / 100}`,
      },
    },
    plugins: {
      datalabels: {
        color: '#ffffff',
        formatter: (value: any) => Math.round(value),
      },
    },
  };

  const colors: string[][] = [
    ['rgba(147,177,247)', 'rgba(4,27,80)'],
    ['rgb(251, 220, 180)', 'rgb(255, 6, 6)'],
  ];

  const getData = (obj: object) => fromJS(Object.values(obj).map((data: any) => ({
    name: data.name,
    progress: data.progress,
  })));


  const getDatasets = (data: any, ctx: any) => [{
    data: data.map((s: any) => s.get('progress')).toArray(),
    borderColor: backgroundColors,
    backgroundColor: backgroundColors && backgroundColors.map((color: string) => {
      const gradient = ctx.createLinearGradient(0, 0, 0, 300);
      gradient.addColorStop(0, color === colors[0][0] ? colors[0][1] : colors[1][1]);
      gradient.addColorStop(1, `${color.slice(0, -1)}, 0.8)`);
      return gradient;
    }),
  }];
  const data = getData(props.res);
  const orderedSortersData = (
    data && data.sort(
      (a: any, b: any) => b.get('progress') - a.get('progress'),
    )
  );

  const backgroundColors = orderedSortersData && orderedSortersData.map(
    (dt: any) => (dt.get('progress') >= props.benchmark ? colors[0][0] : colors[1][0]),
  ).toArray();


  if ((backgroundColors && data) && data.size > backgroundColors.length) {
    let k = 0;
    const remainder = data.size - backgroundColors.length;
    for (let i = 0; i < remainder; i++) {
      if (k > backgroundColors.length - 1) { k = 0; }
      backgroundColors.push(backgroundColors[k]);
      k += 1;
    }
  }


  return (
    <BarChart
      getLabels={(data: any) => data.map((s: any) => s.get('name')).toArray()}
      getDatasets={getDatasets}
      data={orderedSortersData}
      options={chartOptions}
    />
  );
};

type Props = {
  res: any;
  onClick: any;
  benchmark: number;
};


export default BenchBarChart;
