import React, { PureComponent } from 'react';
import { NavLink as Link } from 'react-router-dom';

import { ReactComponent as ArvolutionLogo } from '../../assets/icons/arvolution-logo.svg';

import Input from '../../components/Input';
import HighlightButton from '../../components/Button/HighlightButton';
import request from '../../utils/request';
import LoadingSpinner from '../../components/LoadingSpinner';

import './Register.scss';

type State = {
  password: string;
  passwordConfirm: string;
  submitting: boolean;
  errors: any;
  email: string;
  isLoadingUserInfo: boolean;
};

export default class Register extends PureComponent<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      password: '',
      passwordConfirm: '',
      submitting: false,
      errors: {},
      email: '',
      isLoadingUserInfo: true,
    };
    this.submit = this.submit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  async componentDidMount() {
    try {
      const d = await request.get(`/api/v1/users/token/${this.props.match.params.token}`);
      this.setState({ isLoadingUserInfo: false, email: ((d.user && d.user.email) || '') });
    } catch (error) {
      if ((typeof error.response.data) !== 'object') {
        this.setState({ errors: { message: 'Error de red' }, isLoadingUserInfo: false });
      } else {
        this.setState({ errors: error.response.data, isLoadingUserInfo: false });
      }
    }
  }

  async submit() {
    this.setState({ errors: {} });
    if (this.state.password.length < 7) {
      this.setState({ errors: { password: { msg: 'Contraseña muy corta' } } });
      return null;
    }
    if (this.state.password !== this.state.passwordConfirm) {
      this.setState({ errors: { passwordConfirm: { msg: 'No es igual a contraseña' } } });
      return null;
    }
    this.setState({ submitting: true, errors: {} });
    try {
      await request.post('/api/v1/users/register', {
        accessToken: this.props.match.params.token,
        password: this.state.password,
      });
      // eslint-disable-next-line no-undef
      window.location.replace('/');
    } catch (error) {
      if ((typeof error.response.data) !== 'object') {
        this.setState({ errors: { message: 'Error de red' }, submitting: false });
      } else {
        this.setState({ errors: error.response.data, submitting: false });
      }
    }
  }

  handleInputChange(e: any) {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors } = this.state;
    // 500 error responses are not formatted within input field keys
    // but rather contain a single message key
    let nonValidationError = '';
    if (!errors.email && !errors.password && errors.message) {
      nonValidationError = errors.message;
    }
    return (
      <div className="register">
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3 card main-card-container">
              <ArvolutionLogo style={{ width: 50 }} className="arvolution-logo" />
              <p className="header-text">
                Bienvenido a<br /><span className="font-bold">Sorting</span>
              </p>
              {nonValidationError ? (
                <h3 className="error text-center spacing-top">
                  {nonValidationError}
                </h3>
              ) : null}
              {!this.state.isLoadingUserInfo
                ? this.state.email === ''
                  ? (
                    <h3 className="invalid-token">
                      {nonValidationError || 'Token de acceso no valido o expiró'}
                    </h3>
                  )
                  : (
                    <>
                      <p className="welcome-text">
                      Crea tu contraseña para acceder a la plataforma
                      </p>
                      <Input
                        type="text"
                        placeholder="Correo"
                        onChange={() => ''}
                        value={this.state.email}
                        name="email"
                      />
                      <Input
                        type="password"
                        placeholder="Contraseña"
                        onChange={this.handleInputChange}
                        name="password"
                        error={errors.password && errors.password.msg}
                      />
                      <Input
                        type="password"
                        placeholder="Confirmar contraseña"
                        onChange={this.handleInputChange}
                        name="passwordConfirm"
                        error={errors.passwordConfirm && errors.passwordConfirm.msg}
                      />
                      <p className="detail-text">
                      Ya tienes cuenta? Inicia sesión&nbsp;
                        <Link
                          to="/login"
                          className="link"
                          tabIndex={-1}
                        >aquí.
                        </Link>
                      </p>
                      <HighlightButton
                        text="INICIAR SESIÓN"
                        className="main-action"
                        onClick={this.submit}
                      />
                      {this.state.submitting && <LoadingSpinner />}
                    </>
                  )
                : <LoadingSpinner height={397} />}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
