import React, { CSSProperties, ClassicElement, Fragment } from 'react';

import OnClickDropdown from '../OnClickDropdown';
import './DefaultDropdown.scss';

type Props = {
  children: ClassicElement<any>;
  dropdownContent: Array<{
    onClickFunction?: any;
    value?: string | number;
    label: string | ClassicElement<any>;
    insertSectionDivider?: boolean;
  }>;
  className?: string;
  style?: CSSProperties;
  dropdownElementsWidth?: any;
  dropdownContentPositioning?: {
    top?: number | string;
    right?: number | string;
    bottom?: number | string;
    left?: number | string;
  };
  repositioningValue?: number; // This prop is applied to the dropdown content
  // when it is out of viewPort. The default is bottom: 42
  // (the 42 value can be customized with this prop).
}

const DefaultDropdown = ({
  children,
  dropdownContent,
  className,
  style,
  dropdownElementsWidth,
  dropdownContentPositioning,
  repositioningValue,
}: Props) => (
  <OnClickDropdown
    dropdownContent={(
      <div className="default-dropdown">
        {
          dropdownContent.map((element: any) => (
            <Fragment key={element.value}>
              <div
                role="button"
                className="option"
                key={element.value}
                onClick={element.onClickFunction}
              >
                <div>{element.label}</div>
              </div>
              <div
                style={{ borderBottom: element.insertSectionDivider ? '2px solid #DEDEDE' : 'none' }}
              />
            </Fragment>
          ))
          }
      </div>
      )}
    className={className}
    style={style}
    dropdownElementsWidth={dropdownElementsWidth}
    dropdownContentPositioning={dropdownContentPositioning}
    repositioningValue={repositioningValue}
  >
    {children}
  </OnClickDropdown>
);

export default DefaultDropdown;
