import { ApiRequestMethodType, RequestStatusType } from '../types/types-enums-interfaces';

export const SET_API_REQUEST = 'apiRequests/set-api-requests';
export const DELETE_API_REQUEST = 'apiRequests/delete-api-requests';

export function setApiRequestStatus(
  method: ApiRequestMethodType,
  requestId: string,
  status: RequestStatusType,
) {
  return { type: SET_API_REQUEST, method, requestId, status };
}

export function deleteApiRequest(method: ApiRequestMethodType, requestId: string) {
  return { type: DELETE_API_REQUEST, method, requestId };
}
