import React, { CSSProperties } from 'react';

import './InputWithLabel.scss';

type Props = {
  onChange(e: any): void;
  placeholder?: string;
  value?: string;
  name?: string;
  label: string;
  error?: string;
  type?: 'text' | 'password';
  style?: CSSProperties;
  className?: string;
};

const InputWithLabel = ({
  onChange, placeholder, value, name, label, error, type = 'text', style, className = '',
}: Props) => (
  <div className="input-with-label">
    <div className="labels">
      <p>{label}</p>
      <p className="error-text">{error}</p>
    </div>
    <input
      style={style}
      type={type}
      value={value}
      placeholder={placeholder}
      name={name}
      className={`form-input ${className} ${error ? 'form-error' : ''}`}
      onChange={onChange}
    />
  </div>
);

export default InputWithLabel;
