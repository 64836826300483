import { fromJS } from 'immutable';

import {
  CLEAR_STATE_FOR_DIST_CENTER,
  SET_DC_DAILY_PROGRESS,
  SET_SORTED_TIME_CHART,
  SET_SORTERS_RANKING,
  SET_SORTERS_TOTAL_SELECTION,
} from '../actions/homeDashboard.actions';

const INITIAL_STATE = fromJS({});

const sortingInfoReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_SORTERS_TOTAL_SELECTION:
      return state.update('sortersTotalSelection', () => fromJS(action.selection));
    case SET_SORTED_TIME_CHART:
      return state.update('timeChart', () => {
        const data = fromJS(action.data);
        // we reverse the presentations simply to see "Total" first in the time chart
        return data.update('presentations',
          (val: any) => val && val.reverse());
      });
    case SET_DC_DAILY_PROGRESS:
      return state.update('dailyGoal', () => fromJS(action.progress));
    case SET_SORTERS_RANKING:
      return state.update('sortersRanking', () => fromJS(action.ranking));
    case CLEAR_STATE_FOR_DIST_CENTER:
      if (state.get('currentDistCenter') !== action.distCenter) {
        return INITIAL_STATE.set('currentDistCenter', action.distCenter);
      }
      return state;
    default:
      return state;
  }
};

export default sortingInfoReducer;
