/* eslint-disable react/button-has-type */
/* eslint-disable no-undef */
import React, { FC, useState, useEffect } from 'react';
import {
  addMilliseconds,
  endOfDay,
  endOfToday,
  startOfDay,
  subDays,
  subMilliseconds,
  subMinutes,
} from 'date-fns';
import moment from 'moment';
import arrow from '../../../assets/icons/chevron-down.svg';
import useComponentVisible from '../../../utils/hooks/useComponentVisible';
import { getCurrentDate } from '../../../utils/dateHelpers';
import { FILTER_OPTIONS } from '../../../types/types-enums-interfaces';
import './NativeCalendarStyles.scss';

type Props = {
  onClose?: any;
  initialState?: FILTER_OPTIONS;
  excludeLastHour?: boolean;
  customFirstDate?: string;
  customSecondDate?: string;
};

const NativeCalendar: FC<Props> = props => {
  const {
    onClose, initialState, excludeLastHour,
    customFirstDate, customSecondDate,
  } = props;

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [currentFilter, setCurrentFilter] = useState<any>(initialState || FILTER_OPTIONS.lastHour);
  const calendarOptions = [
    { label: 'últ. hora', value: FILTER_OPTIONS.lastHour },
    { label: 'últ. turno (hoy)', value: FILTER_OPTIONS.lastDay },
    { label: 'últ. semana', value: FILTER_OPTIONS.lastWeek },
    { label: 'últ. mes', value: FILTER_OPTIONS.lastMonth },
    { label: 'Seleccionar periodo', value: FILTER_OPTIONS.customPeriod },
  ];
  if (excludeLastHour) calendarOptions.shift();

  const lastMonth = customFirstDate ? new Date(customFirstDate) : subDays(addMilliseconds(endOfToday(), 1), 30);
  const currentMonth = customSecondDate ? subMilliseconds(new Date(customSecondDate), 1) : new Date();

  const [customDate, setCustomDate] = useState<{ firstDate: string; secondDate: string }>({
    firstDate: moment(lastMonth).format('YYYY-MM-DD'),
    secondDate: moment(currentMonth).format('YYYY-MM-DD'),
  });


  const handleOptionChange = (value: any) => {
    setCurrentFilter(value);
    let firstCalendar;
    let secondCalendar;

    switch (value) {
      case FILTER_OPTIONS.lastHour:
        firstCalendar = new Date();
        onClose(subMinutes(firstCalendar, 60), firstCalendar, value);
        break;
      case FILTER_OPTIONS.lastDay:
        const { startDate, endDate } = getCurrentDate();
        onClose(startDate, endDate, value);
        break;
      case FILTER_OPTIONS.lastWeek:
        firstCalendar = new Date();
        secondCalendar = subDays(addMilliseconds(endOfToday(), 1), 7);
        onClose(secondCalendar, endOfDay(firstCalendar), value);
        break;
      case FILTER_OPTIONS.lastMonth:
        firstCalendar = new Date();
        secondCalendar = subDays(addMilliseconds(endOfToday(), 1), 30);

        onClose(secondCalendar, endOfDay(firstCalendar), value);
        break;
      case FILTER_OPTIONS.customPeriod:
        const { firstDate, secondDate } = customDate;
        firstCalendar = new Date(firstDate);
        secondCalendar = new Date(secondDate);

        // just in case user mistakenly chooses an end date earlier than a start date
        if (firstCalendar < secondCalendar) {
          onClose(
            startOfDay(firstCalendar),
            endOfDay(secondCalendar),
            'CUSTOM_PERIOD',
          );
        } else if (secondCalendar <= firstCalendar) {
          onClose(
            startOfDay(secondCalendar),
            endOfDay(firstCalendar),
            'CUSTOM_PERIOD',
          );
        }
        break;
      default: break;
    }
  };

  useEffect(() => {
    if (!isComponentVisible) {
      handleOptionChange(currentFilter);
    }
  }, [isComponentVisible]);

  const toggleHandler = () => {
    if (isComponentVisible) {
      handleOptionChange(currentFilter);
    }
    setIsComponentVisible(!isComponentVisible);
  };

  const selectionText = () => {
    if (currentFilter !== FILTER_OPTIONS.customPeriod) {
      const currOption = calendarOptions.find(option => option.value === currentFilter);
      // @ts-ignore
      return currOption.label;
    }

    return `${moment(customDate.firstDate).format('DD.MM.YY')}-${moment(customDate.secondDate).format('DD.MM.YY')}`;
  };

  const optionOnClick = (value: any) => {
    if (value !== FILTER_OPTIONS.customPeriod) {
      handleOptionChange(value);
      setIsComponentVisible(false);
    } else {
      setCurrentFilter(value);
    }
  };

  const maxDate = moment(new Date()).format('YYYY-MM-DD');

  return (
    <div id="nativeCalendar">
      <div className="mdropDown" ref={ref}>
        <button
          id="rtTYDU"
          role="combobox"
          aria-controls="rtTYDU"
          aria-expanded={false}
          onClick={toggleHandler}
          tabIndex={0}
          // title={title}
        >
          <p>{selectionText()}</p>
          <img src={arrow} alt="arrow" className={isComponentVisible ? 'open' : ''} />
        </button>
        {isComponentVisible
        && (
        <div>
          {calendarOptions.map(({ value, label }, i) => (
            <button
              role="option"
              aria-selected={value === currentFilter}
              onClick={(e: any) => optionOnClick(e.target.value)}
              value={value}
              className={value === currentFilter ? 'selected' : ''}
              key={value}
            >
              {label}
            </button>
          ))}
          {currentFilter === FILTER_OPTIONS.customPeriod && (
            <>
              <form onSubmit={(e: React.FormEvent<HTMLFormElement>) => { e.preventDefault(); toggleHandler(); }}>
                <label htmlFor="2u87g89i3v">
                  Desde:
                  <input
                    type="date"
                    max={maxDate}
                    id="2u87g89i3v"
                    value={customDate.firstDate}
                    required
                    name="firstDate"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCustomDate({ ...customDate, firstDate: e.target.value })}
                  />
                </label>
                <label htmlFor="2u87g89i3vj">
                  Hasta:
                  <input
                    type="date"
                    max={maxDate}
                    id="2u87g89i3vj"
                    value={customDate.secondDate}
                    required
                    name="secondDate"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCustomDate({ ...customDate, secondDate: e.target.value })}
                  />
                </label>
                <button className="apply" type="submit">Aplicar</button>
              </form>
            </>
          )}
        </div>
        )}
      </div>
    </div>
  );
};

export default NativeCalendar;
