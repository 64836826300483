/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-shadow */
import React, { FC, useState, useEffect } from 'react';
import { fromJS } from 'immutable';
import './PercentLineChart.scss';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import LineChart from '../../../../components/Charts/LineChart';
import request from '../../../../utils/request';


const PercentLineChart: FC<Props> = props => {
  const [data, setData] = useState<any>();
  const [showBlank, setShowBlank] = useState(false);
  const chartOptions = {
    events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
    myOnClick: props.onClick ? (event: any, chtElement: any, ctx: any) => {
      const e = chtElement[0];
      if (e) {
        // eslint-disable-next-line no-underscore-dangle
        const xValue = ctx.data.labels[e._index];
        props.onClick(xValue);
      }
    } : null,
    scales: {
      yAxes: [{
        ticks: {
          callback: (value: any) => `${Math.round(value)}%`,
        },
      }],
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem: any) => ` ${Math.round((Number(tooltipItem.value) + Number.EPSILON) * 100) / 100}%`,
      },
    },
  };

  const colors = 'rgba(147,177,247, 0.9)';

  const getData = (obj: object) => fromJS(Object.values(obj).map((data: any) => ({
    name: data.name,
    percent: data.percent,
  })));


  const getDatasets = (data: any) => [{
    data: data.map((s: any) => s.get('percent')).toArray(),
    lineTension: 0,
    fill: false,
    pointRadius: 3,
    borderWidth: 2,
    pointBackgroundColor: `${colors.slice(0, -6)}, 0.2)`,
    pointBorderColor: `${colors.slice(0, -6)}, 1)`,
    pointBorderWidth: 1,
    borderColor: colors,
    backgroundColor: colors,
  }];

  useEffect(() => {
    if (props.res) {
      setData(getData(props.res));
    } else if (props.location.search && props.location.search.trim()) {
      const queries: any = decodeURIComponent(props.location.search).split('?')[1].split('&');

      const { api, accessSecret } = queries.reduce((a: any, query: any) => {
        const [key, val] = query.split('=');
        a[key] = val;
        return a;
      }, {});

      if (api && accessSecret) {
        (async () => {
          try {
            const data = await request.get('/api/v1/reports/data', { data: api, accessSecret });
            // const data = JSON.parse('{"zones":{"north":{"name":"North","slug":"north","progress":10,"percent":10,"benchmark":10,"subZones":{"central-bajio":{"name":"Central%20Bajio","slug":"central-bajio","progress":10,"percent":10,"benchmark":10,"distributionCenters":{"san-pablo":{"name":"San%20Pablo","slug":"san-pablo","progress":10,"percent":10,"benchmark":10}}}}},"south":{"name":"South","slug":"south","progress":10,"percent":15,"benchmark":10,"subZones":{"yaba-sabo":{"name":"Yaba","slug":"yaba-sabo","progress":10,"percent":15,"benchmark":10,"distributionCenters":{"mushin":{"name":"Mushin%20Olosha","slug":"mushin","progress":8,"percent":8,"benchmark":10},"ejigbo":{"name":"Ejigbo","slug":"ejigbo","progress":7,"percent":7,"benchmark":10}}},"ibadan":{"name":"Iwo%20road","slug":"ibadan","progress":10,"percent":15,"benchmark":10,"distributionCenters":{"mushin":{"name":"Mushin%20Olosha","slug":"mushin","progress":7,"percent":7,"benchmark":10},"ejigbo":{"name":"Ejigbo","slug":"ejigbo","progress":8,"percent":8,"benchmark":10}}}}}}}');
            setData(getData(data.zones));
          } catch (error) {
            setShowBlank(true);
          }
        })();
      }
    }
  }, [props.res]);

  const averageValues = data ? data.reduce((a: any, c: any) => a + c.get('progress'), 0) / data.size : 0;
  const dataWithAverage = data && data.push(fromJS({ name: 'Promedio MX T2', progress: averageValues }));

  return (
    data
      ? (
        <LineChart
          labels={data.map((s: any) => s.get('name')).toArray()}
          data={getDatasets(dataWithAverage)}
          options={chartOptions}
        />
      ) : showBlank ? <div /> : <LoadingSpinner />
  );
};

type Props = {
  res: any;
  onClick: any;
  location?: any;
  match?: any;
};

export default PercentLineChart;
