import { fromJS } from 'immutable';
import {
  SET_WORKER_ACTIVITY,
  SET_WORKERS_COMPENSATION,
  SET_WORKERS_CONFIGURATION,
  UPDATE_WORKERS_COMPENSATION,
  DELETE_USER_FROM_WORKERS_CONFIGURATION,
} from '../actions/configuration.actions';
import { UserType } from '../types/types-enums-interfaces';

const INITIAL_STATE = fromJS({});

const configurationReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_WORKERS_CONFIGURATION:
      return state.update('workersConfiguration', () => {
        if (!action.workers) return undefined;
        const workers = fromJS(action.workers);
        return workers.update('users', (users: any) => users.sort((a: any, b: any) => {
          // push pallet makers (without bench) to the end
          if (a.get('roleId') === UserType.PalletMaker && !a.get('benchId')) return 1;

          if (!b.get('benchId')) return -1;

          if (a.get('benchId')) return a.get('benchId') < b.get('benchId') ? -1 : 1;

          // when "a" is a sorter with no benchId whereas "b" is sorter|stacker with benchId
          return 1;
        }));
      });
    case SET_WORKERS_COMPENSATION:
      return state.update('workersCompensation',
        () => fromJS(action.presentationCompensations));
    case UPDATE_WORKERS_COMPENSATION:
      const { presentationSlug, newFactorPerBox } = action.presentationCompensation;
      const presentationIndex = state.get('workersCompensation')
        .findIndex((p: any) => p.getIn(['presentation', 'slug']) === presentationSlug);

      return state.updateIn(
        ['workersCompensation', presentationIndex, 'factorPerBox'],
        () => newFactorPerBox,
      );
    case SET_WORKER_ACTIVITY:
      return state.updateIn(['workersConfiguration', 'users'], (users: any) => {
        const workerIndex = users.findIndex((worker: any) => worker.get('employeeNumber') === action.employeeNumber);
        return users.updateIn([workerIndex, 'roleId'], () => action.newActivityId);
      });
    case DELETE_USER_FROM_WORKERS_CONFIGURATION:
      return state.updateIn(['workersConfiguration', 'users'], (users: any) => users
        .filter((user: any) => user.get('slug') !== action.userSlug));
    default:
      return state;
  }
};

export default configurationReducer;
