import React, { Component } from 'react';
import { connect } from 'react-redux';

import BarChart from '../../../../components/Charts/BarChart';
import LoadingSpinner from '../../../../components/LoadingSpinner';

import { getSortersTotalSelection } from '../../../../actions/homeDashboard.actions';
import {
  getSortersSelectionDetail,
} from '../../../../actions/sortingDashboard.actions';
import {
  selectHomeCurrentDistCenter,
  selectHomeSortersTotalSelection,
  selectTimeFilterForLocation,
  selectSortingDashboardSortersSelectionDetail,
  selectSortingDashboardsortingHourlyGoals,
} from '../../../../selectors';

class SortersTotalSelection extends Component<Props, State> {
  totalSelectionChart: any;

  constructor(props: any) {
    super(props);
    // @ts-ignore
    this.state = {
      fetchingData: false,
    };

    this.getWorstThreshold = this.getWorstThreshold.bind(this);
  }

  componentDidMount() {
    if (this.props.sortersData) return this.getDataForTimeRange(false);
    this.getDataForTimeRange();
  }

  componentDidUpdate(prevProps: any) {
    if ((prevProps.timeFilter !== this.props.timeFilter) || prevProps.selectedShift !== this.props.selectedShift) {
      this.getDataForTimeRange();
      const { timeFilter } = this.props;
      this.props.dispatch(getSortersSelectionDetail(
        timeFilter.get('startDate'),
        timeFilter.get('endDate'),
      ));
    }
    if (prevProps.currentDistCenter !== this.props.currentDistCenter) {
      this.getDataForTimeRange();
    }
  }

  getDataForTimeRange(showSpinner = true): any {
    if (showSpinner) this.setState({ fetchingData: true });

    this.props.dispatch(getSortersTotalSelection())
      .then(() => this.setState({ fetchingData: false }));
  }

  getWorstThreshold(orderedSortersData: any) {
    const { sortingInfo, sortingHourlyGoals } = this.props;
    if (!sortingInfo || !sortingHourlyGoals || !orderedSortersData) return 0;
    if (sortingInfo.getIn(['slotsType']) !== 'hour') return 0;

    const currentDate = new Date();
    let sortingGoalToCurrentDate = 0;
    sortingInfo.get('sortTimes').forEach((st: any, i: any) => {
      if (new Date(st.get('end')) < currentDate) {
        sortingGoalToCurrentDate += sortingHourlyGoals.get(i);
      }
    });

    let sortersBelowGoal = 0;
    orderedSortersData.forEach((s: any) => {
      if (s.get('totalBoxes') < sortingGoalToCurrentDate) {
        sortersBelowGoal += 1;
      }
    });

    return sortersBelowGoal;
  }

  render() {
    const { sortersData } = this.props;
    const { fetchingData } = this.state;
    const orderedSortersData = (sortersData && sortersData
      .sort((a: any, b: any) => b.get('totalBoxes') - a.get('totalBoxes')));
    const worstThreshold = this.getWorstThreshold(orderedSortersData);

    // @ts-ignore
    return (
      <div className="card">
        {fetchingData || !orderedSortersData
          ? <LoadingSpinner height={300} />
          : (
            <BarChart
              getLabels={(data: any) => data.map((s: any) => s.get('name')).toArray()}
              getDatasets={(data: any) => [{
                data: data.map((s: any) => s.get('totalBoxes')).toArray(),
              }]}
              data={orderedSortersData}
              worstGradient={['#FFD5B1', '#FFF1E5']}
              worstThreshold={worstThreshold}
            />
          )}
      </div>
    );
  }
}

type Props = {
  dispatch: any;
  sortersData: any;
  timeFilter: any;
  currentDistCenter: string;
  sortingInfo: any;
  sortingHourlyGoals: any;
  selectedShift: any;
};

type State = {
  fetchingData: boolean;
};

function mapStateToProps(state: any) {
  return {
    sortersData: selectHomeSortersTotalSelection(state),
    timeFilter: selectTimeFilterForLocation(state, 'homeAndSortingDashboard'),
    selectedShift: state.getIn(['dataFilter', 'selectedShift']),
    currentDistCenter: selectHomeCurrentDistCenter(state),
    sortingInfo: selectSortingDashboardSortersSelectionDetail(state),
    sortingHourlyGoals: selectSortingDashboardsortingHourlyGoals(state),
  };
}

export default connect(mapStateToProps)(SortersTotalSelection);
