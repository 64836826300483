/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react/button-has-type */
/* eslint-disable no-shadow */
import React, { FC, useState, Component } from 'react';
import MBar from './MBarChart/MBarChart';
import UnderlineButton from '../../../components/Button/UnderlineButton';
import CapturasIcon from '../../../assets/icons/capturas.svg';
import SelectionIcon from '../../../assets/icons/bottle.svg';
import OutsourcingIcon from '../../../assets/icons/outsourcing.svg';
import PersonaIcon from '../../../assets/icons/icon-bottle.svg';
import PickingIcon from '../../../assets/icons/icon-picking.svg';
import MontacargasIcon from '../../../assets/icons/icon-forklift.svg';
import ReempaqueIcon from '../../../assets/icons/icon-box.svg';
import PickerProductivity from '../PickingProductivity';
import Forklift from '../ForkLift';
import './ChartsComponent.scss';

enum status {
  menus,
  analysis
}

const Analytics: FC = (props: any) => {
  const [nav, setNav] = useState<any>({
    status: status.menus,
    appName: '',
    appTitle: '',
    url: '',
  });

  const menus = [
    {
      name: 'Capturas',
      appTitle: 'Capturas',
      icon: CapturasIcon,
      url: '/api/v1/corporate/zone-wise-daily-metrics-capture',
    },
    {
      name: 'Selección',
      appTitle: 'Selección',
      icon: SelectionIcon,
      url: '/api/v1/corporate/zone-wise-boxes-by-daily-metrics',
    },
    {
      name: 'Outsourcing',
      appTitle: 'Outsourcing',
      icon: OutsourcingIcon,
      url: '/api/v1/corporate/zone-wise-boxes-by-daily-metrics-outsourced',
    },
    // {
    //   name: 'Selección (Persona)',
    //   icon: PersonaIcon,
    //   url: ' ',
    // },
    {
      name: 'Picking',
      appTitle: 'Productividad Picking',
      icon: PickingIcon,
      Component: PickerProductivity,
    },
    {
      name: 'Montacargas',
      appTitle: 'Productividad de Montacargas',
      icon: MontacargasIcon,
      Component: Forklift,
    },
    {
      name: 'Reempaque',
      appTitle: 'Reempaque',
      icon: ReempaqueIcon,
      url: '/api/v1/corporate/zone-wise-repackagers-productivity',
    },
  ];


  return (
    <>
      {/* <div className="card" /> */}
      <div className="btm">
        <nav>
          <UnderlineButton
            text="Gráficas"
            onClick={() => setNav({
              status: status.menus,
              appName: '',
              appTitle: '',
              url: '',
              Component: null,
            })}
            style={{ fontWeight: 'bold' }}
            className="nav"
            disabled={nav.status !== status.analysis}
          />
          {nav.status === status.analysis && (
            <>
              &gt;
              <UnderlineButton text={nav.appName} onClick={() => {}} style={{ fontWeight: 'bold' }} className="nav" disabled />
            </>
          )}
        </nav>
        <div className="card menus">
          {nav.status === status.menus
            ? menus.map(({ name, appTitle, icon: Icon, url, Component }) => (
              <button
                type="button"
                className="card"
                key={name}
                onClick={() => {
                  // if (redirect) return props.history.push(redirect);
                  // @ts-ignore
                  setNav({
                    appName: name,
                    appTitle,
                    url,
                    status: status.analysis,
                    Component,
                  });
                }}
              >
                <img src={Icon} alt={name} />
                <p>{name}</p>
              </button>
            ))
            : nav.Component
              ? <nav.Component appName={nav.appTitle} />
              : <MBar appName={nav.appTitle} url={nav.url} />}
        </div>
      </div>
    </>
  );
};

export default Analytics;
