import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import { ReactComponent as WrongIcon } from '../../assets/icons/wrong-icon.svg';

import LoadingSpinner from '../LoadingSpinner';
import Toast from '../Toast';
import { selectNonGetRequests } from '../../selectors';

import './RequestStatus.scss';

type Props = {
  apiNonGetRequests: any;
};
type State = {};

class RequestStatus extends Component<Props, State> {
  render() {
    const { apiNonGetRequests } = this.props;

    // do not appear on the login page
    // eslint-disable-next-line no-undef
    if (window.location.href.includes('login')) return null;

    const numberOfSuccessfulRequests = apiNonGetRequests && apiNonGetRequests.filter((v: any) => v === 'success').size;
    const numberOfPendingRequests = apiNonGetRequests && apiNonGetRequests.filter((v: any) => v === 'pending').size;
    const numberOfFailedRequests = apiNonGetRequests && apiNonGetRequests.filter((v: any) => v === 'fail').size;

    return (
      <>
        {apiNonGetRequests && apiNonGetRequests.size
          ? (
            <Toast
              cardClassNames={['row', 'justify-content-between', 'font-bold', 'request-status', 'appear-from-below']}
            >
              <p style={{ paddingLeft: 16 }}>
                {/* text */}
                {numberOfSuccessfulRequests && !numberOfPendingRequests && !numberOfFailedRequests
                  ? <>Cambios hechos</>
                  : (
                    <>
                      {numberOfFailedRequests
                        ? numberOfFailedRequests > 1
                          ? <>{numberOfFailedRequests} no fueron exitosos</>
                          : <>{numberOfFailedRequests} no fue exitoso</>
                        : (
                          <>
                            Procesando cambios <span style={{ color: '#6EA6FC', marginLeft: 16 }}> {numberOfPendingRequests}</span>
                          </>
                        )}
                    </>
                  )}
              </p>
              {/* img/spinner */}
              {numberOfSuccessfulRequests && !numberOfPendingRequests && !numberOfFailedRequests
                ? (
                  <CheckIcon style={{ width: 27, marginRight: 12 }} />
                )
                : (
                  <>
                    {numberOfFailedRequests
                      ? (
                        <WrongIcon style={{ marginRight: 12, width: 27 }} />
                      )
                      : <LoadingSpinner text="" style={{ margin: 'unset' }} />}
                  </>
                )}
            </Toast>
          )
          : null }
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  apiNonGetRequests: selectNonGetRequests(state),
});

export default connect(mapStateToProps)(RequestStatus);
