import React, { Component } from 'react';

type Props = {
  width?: number;
  color?: string;
};
type State = {};

class ActiveDot extends Component<Props, State> {
  render() {
    const { width = 6, color = '#2574FB' } = this.props;
    return (
      <svg width={width} height={width}>
        <ellipse cx={width / 2} cy={width / 2} rx={width / 2} ry={width / 2} fill={color} />
      </svg>
    );
  }
}

export default ActiveDot;
