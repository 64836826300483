import React, { ChangeEvent, useState, CSSProperties } from 'react';

import './Input.scss';

interface Props {
  onChange(e: any): void;
  placeholder?: string;
  value?: string | undefined;
  initialValue?: string;
  name?: string;
  error?: string;
  type?: 'text' | 'password' | 'email' | 'number';
  style?: CSSProperties;
  className?: string;
  maxLength?: number;
  maxFloat?: number; // max number for input type number
  inactiveBorderColor?: string;
  errorHeight?: boolean; // if it is true, it gives the error a default 24px height
}

export default function Input({
  onChange,
  placeholder,
  name,
  error,
  type = 'text',
  style,
  className = '',
  value,
  maxLength,
  inactiveBorderColor,
  maxFloat,
  errorHeight = false,
  initialValue,
}: Props) {
  const [isActive, setIsActive] = useState(false);
  // we never use the type 'number' in the input field since not all browsers allow custom styling
  const inputType = type === 'number' ? 'text' : type;
  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    // check number doesn't include non-numeric characters
    if (type === 'number') {
      // ensure only numbers and dots are used
      if (/[^\d.]/ig.test(e.target.value)) return;
      // ensure one dot at max is used
      if (e.target.value.split('.').length > 2) return;
      // ensure max prop is not exceeded
      if (maxFloat) if (+(e.target.value) > maxFloat) return;
    }
    onChange(e);
  }
  return (
    <>
      <input
        style={{
          boxShadow: isActive ? '0 0 3px 0 rgba(0, 124, 172, 0.82)' : 'none',
          borderColor: isActive ? 'rgba(11,102,222,0.6)' : inactiveBorderColor || '#ECEFF4',
          ...style,
        }}
        type={inputType}
        value={value}
        defaultValue={initialValue}
        placeholder={placeholder}
        name={name}
        className={`default-input form-input ${className} ${error ? 'form-error' : ''}`}
        onChange={handleChange}
        onFocus={() => setIsActive(true)}
        onBlur={() => setIsActive(false)}
        maxLength={maxLength}
      />
      <p className="error-text default-input" style={{ height: errorHeight ? 24 : 0 }}>{error}</p>
      {/* {error && <p className="error-text default-input">{error}</p>} */}
    </>
  );
}
