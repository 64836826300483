import React, { FC } from 'react';
import { Route, Switch, NavLink as Link } from 'react-router-dom';
import MBarChart from '../Analytics/ChartsComponent/MBarChart';
import PercentLineChart from '../Analytics/PickingProductivity/PercentLineChart';


const CorporateReportMailChart: FC<any> = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/charts/line-chart`} exact component={PercentLineChart} />
    <Route path={`${match.path}/charts/:barChartType`} exact component={MBarChart} />
  </Switch>
);

export default CorporateReportMailChart;
