/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactTable from 'react-table';
import { differenceInDays, differenceInMonths, format } from 'date-fns';
import * as esLocale from 'date-fns/locale/es';

import { ReactComponent as ChevronDownBlueIcon } from '../../../../assets/icons/chevron-down-blue.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete-icon.svg';

import LoadingSpinner from '../../../../components/LoadingSpinner';
import DefaultDropdown from '../../../../components/Dropdown/DefaultDropdown';
import SelectDropdown from '../../../../components/Dropdown/SelectDropdown';
import ActiveDot from '../../../../components/ActiveDot';
import Input from '../../../../components/Input';
import { getUserActivityDetail, editPallet, deletePallet } from '../../../../actions/user.actions';
import { selectTimeFilterForLocation, selectUserActivityDetail } from '../../../../selectors';
import './UserTasksTable.scss';

// import 'react-table/react-table.css'

class UserTasksTable extends Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      numberOfRowsInTable: 4,
      editMode: false,
      rowId: undefined,
      palletPresentation: undefined,
      palletSize: undefined,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.editPallet = this.editPallet.bind(this);
    this.deletePallet = this.deletePallet.bind(this);
    this.createTableColumns = this.createTableColumns.bind(this);
    this.renderProductInfoCell = this.renderProductInfoCell.bind(this);
    this.renderConfigurationCell = this.renderConfigurationCell.bind(this);
  }

  componentDidMount() {
    if (this.props.currentFilterTime) {
      return this.props.getUserActivityDetail(this.props.sorterSlug, this.props.currentFilterTime);
    }
    return this.props.getUserActivityDetail(this.props.sorterSlug);
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.currentFilterTime && prevProps.currentFilterTime
      !== this.props.currentFilterTime) {
      this.props.getUserActivityDetail(this.props.sorterSlug, this.props.currentFilterTime);
      // reset number of rows to 4
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ numberOfRowsInTable: 4 });
    }
  }

  increaseRowsBy(increaseBy: number) {
    this.setState((state: any) => ({ numberOfRowsInTable: state.numberOfRowsInTable
      + increaseBy }));
  }

  handleInputChange(e: any) {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  }

  editPallet(palletId: string, oldPalletSize: number, oldPalletPresentation: string) {
    if (!palletId || !oldPalletSize || !oldPalletPresentation) return;
    const { palletSize, palletPresentation } = this.state;
    if (palletSize === undefined && palletPresentation === undefined) {
      this.setState({ editMode: false });
      return;
    }
    this.setState({ editMode: false,
      palletPresentation: undefined,
      palletSize: undefined,
      rowId: undefined });
    this.props.editPallet(palletId, palletSize, oldPalletSize,
      palletPresentation, oldPalletPresentation);
  }

  deletePallet(palletId: string, sorterSlug: string, currentFilterTime: any) {
    this.props.deletePallet(palletId, sorterSlug, currentFilterTime);
  }

  createTableColumns(userActivityDetail: any) {
    const cellStyle = { justifyContent: 'center' };
    // decide date format depending on period chosen
    let dateFormat = '';
    const earliestDelivery = userActivityDetail && userActivityDetail.getIn([0, 'deliveryTime']);
    const latestDelivery = userActivityDetail && userActivityDetail.getIn([userActivityDetail.count() - 1, 'deliveryTime']);
    dateFormat += differenceInMonths(new Date(earliestDelivery),
      new Date(latestDelivery)) ? 'MMMM ' : '';
    dateFormat += differenceInDays(new Date(earliestDelivery),
      new Date(latestDelivery)) ? 'Do ' : '';
    dateFormat += 'hh:mm A';

    const columns = [];
    // col 1: product info
    columns.push({
      Header: () => <p className="th">Evento</p>,
      id: 'pallet',
      accessor: (row: any) => ({
        presentation: row.get('presentation'),
        palletSize: row.get('palletSize'),
      }),
      Cell: this.renderProductInfoCell,
      width: 250,
    });

    // col 2: forklifters
    columns.push({
      id: 'forkliftDriver',
      Header: () => <p className="th">Entregado por</p>,
      accessor: (row: any) => row.get('deliveredBy'),
      Cell: (row: any) => <p className="td">{row.value}</p>,
      width: 150,
    });

    // col 3: timestamp
    columns.push({
      Header: () => <p className="th">Hora</p>,
      id: 'startTime',
      accessor: (row: any) => row.get('deliveryTime'),
      Cell: (row: any) => (
        <p className="td">{format(new Date(row.value), dateFormat,
          { locale: esLocale })}
        </p>
      ),
      width: 150,
    });

    // col 4: configuration menu
    columns.push({
      id: 'configuration',
      Header: '',
      accessor: (row: any) => ({
        palletId: row.get('id'),
        palletSize: row.get('palletSize'),
        palletPresentation: row.get('presentation'),
      }),
      Cell: this.renderConfigurationCell,
      width: 150,
      style: cellStyle,
    });
    return columns;
  }

  renderProductInfoCell(row: any) {
    const palletPresentations = [
      { label: 'MEGA',
        value: 'mega' },
      { label: 'Cuartito',
        value: 'cuartito' },
      { label: 'Media',
        value: 'media' },
      { label: 'Premium',
        value: 'premium' },
      { label: 'Familiar',
        value: 'familiar' }];
    const { editMode, rowId, palletPresentation, palletSize } = this.state;
    const palletPresentationName = palletPresentation && palletPresentations
      .find((pallet: any) => pallet.value === palletPresentation);
    return (
      (editMode && rowId === row.index ? (
        <div className="d-flex align-content-center">
          <span>Recibió&nbsp;</span>
          <SelectDropdown
            name="palletPresentation"
            value={row.value.presentation}
            onChange={this.handleInputChange}
            dropdownContent={palletPresentations}
            className="configuration-dropdown"
            style={{ marginRight: 9 }}
            // highlightSelected
            selectUI={false}
            dropdownArrow
            dropdownContentHeight={145}
            repositioningValue={35}
          >
            <p>{palletPresentationName ? palletPresentationName.label : row.value.presentation}</p>
          </SelectDropdown>
          <span>de&nbsp;</span>
          <Input
            name="palletSize"
            type="number"
            // maxFloat={153}
            initialValue={row.value.palletSize}
            value={palletSize === undefined ? undefined : String(palletSize)}
            onChange={this.handleInputChange}
            className="pallet-size-input"
          />
        </div>
      ) : (
        <p className="td">Recibió {row.value.presentation} de {row.value.palletSize}</p>
      ))
    );
  }

  renderConfigurationCell(row: any) {
    const { editMode, rowId } = this.state;
    const { currentFilterTime, sorterSlug } = this.props;
    return (
      (editMode && rowId === row.index ? (
        // Cell content when the user wants to edit a pallet
        <div className="edit-buttons d-flex">
          {/* Save */}
          <p
            className="save-button font-bold"
            role="button"
            onClick={() => this.editPallet(row.value.palletId,
              row.value.palletSize,
              row.value.palletPresentation)}
          >Guardar cambios
          </p>
          {/* Cancel */}
          <p
            className="cancel-button font-bold"
            role="button"
            onClick={() => this.setState({ editMode: false,
              palletSize: undefined,
              palletPresentation: undefined,
              rowId: undefined })}
          >Cancelar
          </p>
        </div>
      ) : (
        // Cell content with configuration menu dropdown dots
        <DefaultDropdown
          dropdownContent={[{
            label: (
              <div className="d-flex align-items-center">
                <EditIcon style={{ marginRight: 10 }} />
                <p className="text-light">Editar</p>
              </div>),
            value: 'edit',
            onClickFunction: () => this.setState({ editMode: true,
              palletSize: undefined,
              palletPresentation: undefined,
              rowId: row.index }),
          }, {
            label: (
              <div className="d-flex align-items-center">
                <DeleteIcon style={{ marginRight: 10 }} />
                <p className="text-light">Eliminar</p>
              </div>),
            value: 'delete',
            onClickFunction: () => this.deletePallet(row.value.palletId,
              sorterSlug,
              currentFilterTime),
            insertSectionDivider: true,
          }]}
          className="configuration-dropdown"
          dropdownElementsWidth={100}
          repositioningValue={30}
        >
          <div className="config-dots d-flex">
            <ActiveDot color="#D8D8D8" width={4} />
            <ActiveDot color="#D8D8D8" width={4} />
            <ActiveDot color="#D8D8D8" width={4} />
          </div>
        </DefaultDropdown>
      ))
    );
  }

  render() {
    const { userActivityDetail } = this.props;
    const { numberOfRowsInTable } = this.state;
    const columns = userActivityDetail && this.createTableColumns(userActivityDetail);
    return (
      <div style={{ marginTop: 75 }}>
        {userActivityDetail
          ? (
            <div className="user-tasks-table">
              <ReactTable
                data={userActivityDetail}
                columns={columns}
                pageSize={numberOfRowsInTable}
                showPagination={false}
                className="-highlight"
                NoDataComponent={() => null}
              />
              {/* show more */}
              <div style={{ marginTop: 16 }}>
                <div
                  role="button"
                  className="clickableOpacity"
                  style={{
                    textAlign: 'center',
                    width: 'max-content',
                    margin: 'auto',
                    fontSize: '1.1em',
                    cursor: 'pointer',
                    display: userActivityDetail && userActivityDetail.count() > numberOfRowsInTable ? 'block' : 'none',
                  }}
                  onClick={() => this.increaseRowsBy(4)}
                >
                Ver 4 más <ChevronDownBlueIcon style={{ width: 13, margin: 5 }} />
                </div>
              </div>
            </div>
          )
          : <LoadingSpinner height={334} />}
      </div>
    );
  }
}

type Props = {
  userActivityDetail: any;
  getUserActivityDetail: typeof getUserActivityDetail;
  sorterSlug: string;
  currentFilterTime: any;
  // dispatch
  editPallet: Function;
  deletePallet: Function;
};

type State = {
  numberOfRowsInTable: number;
  editMode: boolean;
  rowId: number | undefined;
  palletPresentation: string | undefined;
  palletSize: number | undefined;
};

function mapStateToProps(state: any) {
  return {
    userActivityDetail: selectUserActivityDetail(state),
    currentFilterTime: selectTimeFilterForLocation(state, 'userDetails'),
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    getUserActivityDetail,
    editPallet,
    deletePallet,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UserTasksTable);

// take data from hourlyboxes here in tasks tables
