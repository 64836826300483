import request from '../utils/request';
import getCurrentDistCenter from '../utils/getCurrentDistCenter';
import { TIME_ZONE_OFFSET } from '../utils/dateHelpers';
import { log } from '../utils/logger';

export const SET_AUTHENTICATED_USER = 'user/set-authenticated-user';
export const SET_USER_HEADER_INFO = 'user/user-header-info';
export const SET_USER_ACTIVITY_DETAIL = 'user/user-activity-detail';
export const SET_USER_BOXES_BY_PRESENTATION = 'user/user-boxes-by-presentation';
export const SET_USER_WEEKLY_PAYMENT = 'user/user-weekly-payment';
export const SET_EDIT_PALLET_INFORMATION = 'user/set-edit-pallet-information';
export const DELETE_PALLET_FROM_USER_ACTIVITY_DETAIL = 'user/delete-pallet-from-user-activity-detail';

export function setAuthenticatedUser(user: any) {
  return { type: SET_AUTHENTICATED_USER, user };
}

export function setUserHeaderInfo(info: any) {
  return { type: SET_USER_HEADER_INFO, info };
}

export function setUserActivityDetail(detail: any) {
  return { type: SET_USER_ACTIVITY_DETAIL, detail };
}

export function setUserBoxesByPresentation(presentation: any) {
  return { type: SET_USER_BOXES_BY_PRESENTATION, presentation };
}

export function setUserWeeklyPayment(payment: any) {
  return { type: SET_USER_WEEKLY_PAYMENT, payment };
}

export function setEditPalletInformation(palletId: number,
  palletPresentation: string, palletSize: number) {
  return { type: SET_EDIT_PALLET_INFORMATION, palletId, palletPresentation, palletSize };
}

export function deletePalletFomUserActivityDetail(palletId: number) {
  return { type: DELETE_PALLET_FROM_USER_ACTIVITY_DETAIL, palletId };
}

export function isUserAuthenticated(): any {
  return async (dispatch: any) => {
    const url = '/api/v1/users';

    try {
      const response = await request.get(url);
      dispatch(setAuthenticatedUser(response));
    } catch {
      dispatch(setAuthenticatedUser({}));
    }
  };
}

export function getUserHeaderInfo(sorter: string,
  period?: { startTime: Date; endTime: Date }): any {
  return async (dispatch: any) => {
    try {
      const url = `${getCurrentDistCenter()}/sorters/${sorter}`;
      const response = await request.get(url, period);
      dispatch(setUserHeaderInfo(response));
    } catch (e) {
      log('e getUserHeaderInfo()', e.message || e);
    }
  };
}

export function getUserActivityDetail(sorter: string, currentFilterTime?: any): any {
  return async (dispatch: any) => {
    try {
      const url = `${getCurrentDistCenter()}/sorters/${sorter}/pallets-to-sort`;

      const timeFilter = currentFilterTime
        ? {
          startTime: currentFilterTime.get('startDate'), endTime: currentFilterTime.get('endDate'),
        }
        : undefined;
      const response = await request.get(url, timeFilter);
      dispatch(setUserActivityDetail(response.pallets));
    } catch (e) {
      log('e getUserActivityDetail()', e.message || e);
    }
  };
}

export function getUserBoxesByPresentation(sorterSlug: string,
  period?: { startTime: Date; endTime: Date }): any {
  return async (dispatch: any) => {
    try {
      const url = `${getCurrentDistCenter()}/sorters/${sorterSlug}/hourly-boxes`;

      const requestObject = {
        startTime: (period && period.startTime) || null,
        endTime: (period && period.endTime) || null,
        timezoneOffset: TIME_ZONE_OFFSET,
      };

      const response = await request.get(url, requestObject);

      dispatch(setUserBoxesByPresentation(response));
    } catch (e) {
      log('e getUserBoxesByPresentation()', e.message || e);
    }
  };
}

export function getUserWeeklyCompensation(sorterSlug: string,
  period?: { startTime: Date; endTime: Date }): any {
  return async (dispatch: any) => {
    try {
      const url = `${getCurrentDistCenter()}/sorters/${sorterSlug}/payment-weekly`;

      const requestObject = {
        startTime: (period && period.startTime) || null,
        endTime: (period && period.endTime) || null,
        timezoneOffset: TIME_ZONE_OFFSET,
      };

      const response = await request.get(url, requestObject);

      dispatch(setUserWeeklyPayment(response));
    } catch (e) {
      log('e getUserBoxesByPresentation()', e.message || e);
    }
  };
}

export function editPallet(palletId: number, palletSize: number, oldPalletSize: number,
  palletPresentation: string, oldPalletPresentation: string) {
  return async (dispatch: any) => {
    try {
      const url = `${getCurrentDistCenter()}/sorting/pallets-to-sort`;
      const body = {
        palletId,
        totalBoxes: palletSize || oldPalletSize,
        palletPresentation: palletPresentation || oldPalletPresentation,
      };
      const palletPresentationName = palletPresentation
        ? (palletPresentation === 'mega' ? palletPresentation.toLocaleUpperCase()
          : palletPresentation.charAt(0).toUpperCase() + palletPresentation.slice(1)) : undefined;
      // change locally
      dispatch(setEditPalletInformation(palletId,
        palletPresentationName || oldPalletPresentation,
        palletSize || oldPalletSize));

      await request.patch(url, body);
    } catch (e) {
      dispatch(setEditPalletInformation(palletId, oldPalletPresentation, oldPalletSize));
      log('e editPallet()', e.message || e);
    }
  };
}

export function deletePallet(palletId: number, sorterSlug: string, currentFilterTime: any) {
  return async (dispatch: any) => {
    try {
      const url = `${getCurrentDistCenter()}/sorting/pallets-to-sort`;

      // change locally
      dispatch(deletePalletFomUserActivityDetail(palletId));

      await request.delete(url, { palletId });
    } catch (e) {
      dispatch(getUserActivityDetail(sorterSlug, currentFilterTime));
      log('e deletePallet()', e.message || e);
    }
  };
}
