import React, { Component } from 'react';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import merge from 'lodash.merge';
import isEqual from 'lodash.isequal';
import cloneDeep from 'lodash.clonedeep';
import { timeSaturday } from 'd3-time';

class BarChart extends Component<Props, any> {
  barChart: any;
  ctx: any;
  barGradient: any;
  worstGradient: any;
  worstThreshold: number | undefined;
  defaultDatasets = {
    // data: [0, 0, 0, 0, 0],
    backgroundColor: ((o: any) => {
      const dataLength = o.dataset.data.length;
      if (this.isWorst(dataLength, o.dataIndex)) {
        return this.worstGradient;
      }
      return this.barGradient;
    }) as unknown as string,
    borderColor: (o: any) => {
      const dataLength = o.dataset.data.length;
      return this.isWorst(dataLength, o.dataIndex) ? '#FB9336' : '#2574FB';
    },
    borderWidth: 1.2,
  };
  defaultOptions = {
    maintainAspectRatio: false,
    legend: { display: false },
    events: null,
    scales: {
      xAxes: [{
        maxBarThickness: 35,
        gridLines: {
          color: 'rgba(0, 0, 0, 0)',
          drawBorder: false,
        },
        ticks: {
          fontStyle: 'bold',
          fontColor: 'black',
        },
      }],
      yAxes: [{
        gridLines: {
          borderDash: [6, 8],
          color: 'rgba(230, 234, 241, 1)',
          drawBorder: false,
        },
        ticks: {
          beginAtZero: true,
          fontColor: 'black',
        },
      }],
    },
    // animation: {
    //   onComplete: () => this.checkLabelPosition(),
    // },
    plugins: {
      datalabels: {
        color: (o: any) => {
          const dataLength = o.dataset.data.length;
          if (this.isWorst(dataLength, o.dataIndex)) {
            return '#FB9336';
          }
          return '#2574FB';
        },
        anchor: 'end',
        align: 'bottom',
        font: {
          weight: 900,
          size: 10,
        },
        formatter: (value: any) => value && value.toLocaleString(),
      },
    },
  };

  constructor(props: any) {
    super(props);
    this.checkLabelPosition = this.checkLabelPosition.bind(this);
    this.updateChart = this.updateChart.bind(this);
  }

  componentDidMount() {
    if (this.props.data) this.renderChart();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.data === undefined || !this.barChart) {
      return this.renderChart();
    }
    if (
      !isEqual(prevProps.data, this.props.data) || prevProps.options !== this.props.options
    ) {
      this.updateChart();
    }
    if (prevProps.worstThreshold !== this.props.worstThreshold) {
      this.updateChart();
    }
    if (prevProps.worstThreshold !== this.props.worstThreshold) {
      this.updateChart();
    }
  }

  componentWillUnmount() {
    if (this.barChart) this.barChart.destroy();
  }

  isWorst = (dataLength: number, dataIndex: number) => (
    this.worstThreshold
    && dataIndex >= (dataLength - this.worstThreshold)
  );

  updateChart() {
    this.worstThreshold = this.props.worstThreshold;

    this.barChart.data.datasets = this.props.getDatasets(this.props.data, this.ctx)
      .map((dataset: any) => {
        const defaultDatasets = cloneDeep(this.defaultDatasets);
        return merge(defaultDatasets, dataset);
      });

    this.barChart.data.labels = this.props.getLabels(this.props.data);
    this.barChart.options = (() => {
      const myOnClick = this.props && this.props.options && this.props.options.myOnClick;
      if (myOnClick) {
        this.props.options.onClick = (event: any, ele: any) => myOnClick(
          event, ele, this.barChart.config,
        );
        delete this.props.options.myOnClick;
      }
      return merge(this.defaultOptions, this.props.options);
    })();
    this.barChart.update();
    this.checkLabelPosition();
  }

  checkLabelPosition() {
    if (!this.barChart.$datalabels._datasets[0][0]) return;
    if (this.barChart.$datalabels._datasets[0][0].$context.dataset._meta[this.barChart.id]
      !== undefined) {
      this.barChart.$datalabels._datasets[0].forEach((e: any) => {
        if (
          this.barChart.config.data.datasets._meta
          && ((
            this.barChart.config.data.datasets[0]._meta[this.barChart.id].data[e._index]._model.y
            + this.barChart.$datalabels._datasets[0][e._index]._rects.frame.h
          )
          > this.barChart.scales['y-axis-0'].bottom)
        ) {
          this.barChart.$datalabels._datasets[0][e._index]._rects.text.y -= 23;
        }
      });
      // Canvas "y" position for number label in bar 0.
      // this.barChart.$datalabels._datasets[0][0]._rects.text.y;

      // Canvas "y" position for the bar 0 top.
      // this.barChart.config.data.datasets[0]._meta[3].data[0]._model.y;

      // Canvas "y" position for the chart's x axis.
      // this.barChart.scales["y-axis-0"].bottom;
    }
  }

  renderChart() {
    // @ts-ignore
    this.ctx = document.getElementById('bar-chart').getContext('2d');

    // Create bar gradients color
    this.barGradient = this.ctx.createLinearGradient(0, 0, 0, 300);
    this.barGradient.addColorStop(1, '#EAF2FE');
    this.barGradient.addColorStop(0, '#B5CDFB');

    if (this.props.worstGradient) {
      this.worstGradient = this.ctx.createLinearGradient(0, 0, 0, 300);
      this.worstGradient.addColorStop(1, this.props.worstGradient[0]);
      this.worstGradient.addColorStop(0, this.props.worstGradient[0]);
      this.worstThreshold = this.props.worstThreshold;
    }

    this.barChart = new Chart(this.ctx, {
      type: 'bar',
      data: {
        labels: this.props.getLabels(this.props.data),
        datasets: this.props.getDatasets(this.props.data, this.ctx)
          .map((dataset: any) => {
            const defaultDatasets = cloneDeep(this.defaultDatasets);
            return merge(defaultDatasets, dataset);
          }),
      },
      options: (() => {
        const myOnClick = this.props && this.props.options && this.props.options.myOnClick;
        if (myOnClick) {
          this.props.options.onClick = (event: any, ele: any) => myOnClick(
            event, ele, this.barChart.config,
          );
          delete this.props.options.myOnClick;
        }
        return merge(this.defaultOptions, this.props.options);
      })(),
      plugins: [ChartDataLabels],
    });
    this.checkLabelPosition();
    this.barChart.render();
  }

  render() {
    return (
      <div className="chart">
        <div className="chart-container">
          <canvas id="bar-chart" />
        </div>
      </div>
    );
  }
}

type Props = {
  getLabels: any;
  getDatasets: any;
  options?: any;
  data: any;
  worstGradient?: string[];
  worstThreshold?: number;
};

export default BarChart;
