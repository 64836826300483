import debounce from 'lodash.debounce';
import request from '../utils/request';
import getCurrentDistCenter from '../utils/getCurrentDistCenter';
import { TIME_ZONE_OFFSET } from '../utils/dateHelpers';
// import { getCurrentDate } from '../utils/dateHelpers';
import { log } from '../utils/logger';

export const SET_SORTERS_KPIS = 'sorting/set-sorters-kpis';
export const SET_SORTERS_SUMMARY = 'sorting/set-sorters-summary';
export const SET_SORTERS_SELECTION_DETAIL = 'sorting/set-sorters-selection-detail';
export const SET_SORTING_HOURLY_GOALS = 'sorting/set-sorting-hourly-goals';
export const SET_SORTING_GOAL_FOR_HOUR = 'sorting-info/set-sorting-goal-for-hour';
export const SET_SORTERS_PRESENTATION_TOTALS = 'sorting-info/set-sorters-presentation-totals';
export const SET_TOTAL_SORTING_FOR_PRESENTATIONS = 'sorting-info/set-total-sorting-presentations';
export const CLEAR_STATE_FOR_DIST_CENTER = 'sorting-info/clear-state-for-dist-center';

export function setSortersSelectionDetail(detail: any) {
  return { type: SET_SORTERS_SELECTION_DETAIL, detail };
}

export function setSortingHourlyGoals(payload: any) {
  return { type: SET_SORTING_HOURLY_GOALS, payload };
}

export function setSortersKPIs(kpis: any) {
  return { type: SET_SORTERS_KPIS, kpis };
}

export function setSortersSummary(summary: any) {
  return { type: SET_SORTERS_SUMMARY, summary };
}

export function setSortingGoalForHour(newGoal: number, hourIndex: number) {
  return { type: SET_SORTING_GOAL_FOR_HOUR, newGoal, hourIndex };
}

export function setSortersPresentationTotals(detail: any) {
  return { type: SET_SORTERS_PRESENTATION_TOTALS, detail };
}

export function setTotalSortingForPresentations(presentations: any) {
  return { type: SET_TOTAL_SORTING_FOR_PRESENTATIONS, presentations };
}

export function clearStateForDistCenter(distCenter: string) {
  return { type: CLEAR_STATE_FOR_DIST_CENTER, distCenter };
}

export function getSortersSelectionDetail(startTime?: Date, endTime?: Date): any {
  return async (dispatch: any) => {
    try {
      const url = `${getCurrentDistCenter()}/sorting/details`;

      let timeFilter: any = { timezoneOffset: TIME_ZONE_OFFSET };
      if (startTime && endTime) {
        timeFilter = {
          startTime,
          endTime,
          timezoneOffset: TIME_ZONE_OFFSET,
        };
      }

      const response = await request.get(url, timeFilter);
      dispatch(setSortingHourlyGoals(response.hourlySelectionKPI));
      return dispatch(setSortersSelectionDetail({
        ...response.selectionDetail,
        timeOffsetForDistCenter: response.timeOffsetForDistCenter,
      }));
    } catch (e) {
      log('e getSortersSelectionDetail()', e.message || e);
    }
  };
}

export function getSortersPresentationTotals(startTime?: Date, endTime?: Date): any {
  return async (dispatch: any) => {
    try {
      const url = `${getCurrentDistCenter()}/sorting/sorters-by-presentation-totals`;

      let timeFilter;
      if (startTime && endTime) timeFilter = { startTime, endTime };

      const response = await request.get(url, timeFilter);
      dispatch(setSortersPresentationTotals(response));
    } catch (e) {
      log('e getSortersPresentationTotals()', e.message || e);
    }
  };
}

export function getTotalSortingForPresentations(startTime?: Date, endTime?: Date): any {
  return async (dispatch: any) => {
    try {
      const url = `${getCurrentDistCenter()}/sorting/presentation-totals`;

      let timeFilter;
      if (startTime && endTime) timeFilter = { startTime, endTime };

      const response = await request.get(url, timeFilter);
      dispatch(setTotalSortingForPresentations(response.presentations));
    } catch (e) {
      log('e getTotalSortingForPresentations()', e.message || e);
    }
  };
}

export function getSortersKPIs(startTime?: Date, endTime?: Date) {
  return async (dispatch: any) => {
    try {
      const url = `${getCurrentDistCenter()}/sorting/sorters-kpis`;

      let timeFilter;
      if (startTime && endTime) timeFilter = { startTime, endTime };

      const response = await request.get(url, timeFilter);
      dispatch(setSortersKPIs(response));
    } catch (e) {
      log('e getSortersKPIs()', e.message || e);
    }
  };
}

export function getSortersSummary(startTime?: Date, endTime?: Date) {
  return async (dispatch: any) => {
    try {
      const url = `${getCurrentDistCenter()}/sorting/summary`;

      let timeFilter;
      if (startTime && endTime) timeFilter = { startTime, endTime };

      const response = await request.get(url, timeFilter);
      dispatch(setSortersSummary(response));
    } catch (e) {
      log('e getSortersSummary()', e.message || e);
    }
  };
}

const debouncedSortingGoalForHourUpdate = debounce(
  async (getState: any) => {
    try {
      const url = `${getCurrentDistCenter()}/sorting/hourly-goals`;
      const hourlySelection = getState()
        .getIn(['sortingDashboard', 'sortingHourlyGoals'])
        .toArray();
      await request.put(url, { hourlyGoals: hourlySelection });
    } catch (e) {
      log('e debouncedSortingGoalForHourUpdate()', e.message || e);
    }
  },
  500,
);

// Hour is the index of the new goal timeframe on the hourlySelectionKPI array
export function updateSortingGoalForHour(newGoal: number, hourIndex: number) {
  return async (dispatch: any, getState: any) => {
    const goalToSet = Number.isNaN(newGoal) ? 0 : newGoal;
    dispatch(setSortingGoalForHour(goalToSet, hourIndex));

    debouncedSortingGoalForHourUpdate(getState);
  };
}
