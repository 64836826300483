/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fromJS, List } from 'immutable';
import './MBarChart.scss';
import { selectTimeFilterForLocation } from '../../../../selectors';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import BarChart from '../../../../components/Charts/BarChart';
import UnderlineButton from '../../../../components/Button/UnderlineButton';
import { generateQueryDates } from '../../../../actions/corporateAgencies.actions';
import Selection from '../../../../components/Select/MultipleSelect';
import request from '../../../../utils/request';


const MBarCharts: FC<Props> = props => {
  const [subZone, setSubzone] = useState('');
  const [goal, setGoal] = useState(0);
  const [isPercent, setIsPrecent] = useState(false);
  const [showBlank, setShowBlank] = useState(false);
  const [filter, setFilter] = useState('');

  const isCapturas = props.appName === 'Capturas';
  const isSelection = props.appName === 'Selección';
  const isOutsourcing = props.appName === 'Outsourcing';
  const isRePackaging = props.appName === 'Reempaque';

  const allTrueText = 'Todos los CD’s';
  const selectionOptions = [
    {
      value: 'Sin Banda Selección',
      label: 'Sin Banda Selección',
    },
    {
      value: 'Banda Selección',
      label: 'Con Banda Selección',
    },
    {
      value: 'Sorting 2.0',
      label: 'Sorting 2.0',
    },
  ];

  const zoneOptions: any = {
    tooltips: {
      callbacks: {
        label: (tooltipItem: any) => ` ${Math.round((Number(tooltipItem.value) + Number.EPSILON) * 100) / 100}${isCapturas ? '%' : ''}`,
      },
    },
    events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
    myOnClick: (event: any, chtElement: any, ctx: any) => {
      const e = chtElement[0];
      if (e) {
        const xValue = ctx.data.labels[e._index];
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        gotoSubzone(xValue);
      }
    },
    scales: {
      yAxes: [{
        ticks: {
          callback: (value: any) => `${Math.round(value)}${isCapturas ? '%' : ''}`,
        },
      }],
    },
    plugins: {
      datalabels: {
        color: '#ffffff',
        formatter: (value: any) => `${Math.round(value)}${isCapturas ? '%' : ''}`,
        rotation: !props.appName ? -90 : 0,
      },
    },
  };

  const subZoneOptions: any = {
    scales: {
      xAxes: [{
        stacked: true,
      }],
      yAxes: [{
        stacked: true,
        ticks: {
          callback: (value: any) => `${Math.round(value)}${isCapturas ? '%' : ''}`,
        },
      }],
    },
    events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
    myOnClick: (event: any, chtElement: any, ctx: any) => {
      const e = chtElement[0];
      if (e) {
        const xValue = ctx.data.labels[e._index];
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        setSubzone(xValue);
      }
    },
    tooltips: {
      callbacks: {
        title: (tooltipItem: any, obj: any) => `UEN ${obj.labels[tooltipItem[0].index]}`,
        label: (tooltipItem: any) => (tooltipItem.datasetIndex ? ' 900' : ` ${Math.round((Number(tooltipItem.value) + Number.EPSILON) * 100) / 100}${isCapturas ? '%' : ''}`),
        footer: (tooltipItem: any) => (tooltipItem[0].datasetIndex ? 'Meta' : 'Progreso'),
      },
    },
    plugins: {
      datalabels: {
        color: '#ffffff',
        formatter: (value: any) => `${Math.round(value)}${isCapturas ? '%' : ''}`,
        rotation: !props.appName ? -90 : 0,
      },
    },
  };

  const colors: string[] = [
    'rgba(147,177,247)',
    'rgba(118,226,238)',
    'rgba(174,129,244)',
    'rgba(217,235,240)',
    'rgba(240,137,142)',
    'rgba(240,220,138)',
    'rgba(30,110,78)',
    'rgba(36,50,110)',
    'rgba(110,97,103)',
    'rgba(110,63,19)',
    'rgba(0,0,0)',
    'rgba(133,133,133)',
  ];

  const zoneColors: any = (isCapturas || isSelection || isPercent) ? [
    ['rgba(147,177,247)', 'rgba(4,27,80)'],
    ['rgb(251, 220, 180)', 'rgb(255, 6, 6)'],
  ] : colors;

  const [state, setState] = useState<{
    level: string;
    current: { zone: string; subZone: string };
    data: List<any> | null;
    res: any;
    options: any;
  }>({
    level: 'zone',
    current: {
      zone: '',
      subZone: '',
    },
    data: null,
    res: null,
    options: null,
  });


  const { data, options, res } = state;
  const { level, current: { zone } } = state;
  let backgroundColors: any = level === 'zone' ? colors : (isOutsourcing || isRePackaging) ? colors : ['rgb(233, 250, 255)'];


  const getData = (obj: object) => fromJS(Object.values(obj).map((data: any) => ({
    name: data.name,
    progress: data.progress,
    benchmark: data.benchmark,
  })));


  const getDatasetsForZone = (data: any, ctx: any) => [{
    data: data.map((s: any) => s.get('progress')).toArray(),
    borderColor: backgroundColors,
    backgroundColor: (() => {
      if (!props.appName) {
        if (isPercent || (orderedSortersData && orderedSortersData.get(0).get('benchmark'))) {
          const zoneColors = [
            ['rgba(147,177,247)', 'rgba(4,27,80)'],
            ['rgb(251, 220, 180)', 'rgb(255, 6, 6)'],
          ];
          return backgroundColors.map((color: string) => {
            const gradient = ctx.createLinearGradient(0, 0, 0, 300);
            gradient.addColorStop(0, color === zoneColors[0][0] ? zoneColors[0][1] : zoneColors[1][1]);
            gradient.addColorStop(1, `${color.slice(0, -1)}, 0.8)`);
            return gradient;
          });
        }
      }

      return backgroundColors.map((color: string) => {
        const gradient = ctx.createLinearGradient(0, 0, 0, 300);
        gradient.addColorStop(0, (!isCapturas && !isSelection) ? 'rgba(4,27,80,1)' : color === zoneColors[0][0] ? zoneColors[0][1] : zoneColors[1][1]);
        gradient.addColorStop(1, `${color.slice(0, -1)}, 0.8)`);
        return gradient;
      });
    })(),
  }];

  const getDatasetsForSubZone = (data: any, ctx: any) => {
    const dataSets: any[] = [{
      data: data.map((s: any) => s.get('progress')).toArray(),
      borderColor: backgroundColors,
      backgroundColor: backgroundColors.map((color: string) => {
        const gradient = ctx.createLinearGradient(0, 0, 0, 300);
        gradient.addColorStop(0, (!isCapturas && !isSelection) ? (isOutsourcing || isRePackaging) ? 'rgba(4,27,80,1)' : 'rgb(127, 165, 255)'
          : color === zoneColors[0][0] ? zoneColors[0][1] : zoneColors[1][1]);
        gradient.addColorStop(1, `${color.slice(0, -1)}, 0.8)`);
        return gradient;
      }),
    }];

    if (goal) {
      dataSets.push({
        data: data.map((s: any) => (900 - s.get('progress') <= 0 ? 0 : 900 - s.get('progress'))).toArray(),
        backgroundColor: backgroundColors,
        borderColor: backgroundColors,
        datalabels: {
          formatter: () => '',
        },
      });
      if (!props.appName) {
        dataSets[1].datalabels = {
          formatter: () => '',
        };
      }
    }

    return dataSets;
  };

  const gotoSubzone = (zoneName: string) => {
    let subzone: any;
    setState(state => {
      const zone: any = Object.values(res.zones).find((zone: any) => zone.name === zoneName);
      subzone = zone.subZones;

      return {
        ...state,
        level: 'subZone',
        current: {
          zone: zoneName,
          subZone: '',
        },
        data: getData(subzone),
        options: null,
      };
    });

    const currentSubzone = Object.values(subzone).sort((a: any, b: any) => b.progress - a.progress);
    // @ts-ignore
    if (currentSubzone.length) setSubzone(currentSubzone[0].name);
  };

  useEffect(() => {
    (async () => {
      setState({
        ...state,
        data: null,
        res: null,
        options: null,
      });

      let res: any;
      if (props.appName) {
        const queries: any = generateQueryDates(
          props.timeFilters.get('startDate'),
          props.timeFilters.get('endDate'),
        );

        if (isSelection && filter) {
          queries.clusterType = filter;
        }
        res = await request.get(props.url, queries);

        if (!Object.keys(res.zones).length) {
          setSubzone('');
          setState({
            ...state,
            level: 'zone',
            current: {
              zone: '',
              subZone: '',
            },
            res,
            data: getData(res.zones),
            options: null,
          });
          return;
        }
      } else if (props.location.search && props.location.search.trim()) {
        const queries: any = decodeURIComponent(props.location.search).split('?')[1].split('&');

        const { api, accessSecret, isPercent } = queries.reduce((a: any, query: any) => {
          const [key, val] = query.split('=');
          a[key] = val;
          return a;
        }, {});


        if (isPercent === 'true') {
          setIsPrecent(true);
        } else {
          // setGoal(900);
        }


        if (api && accessSecret) {
          try {
            res = await request.get('/api/v1/reports/data', { data: api, accessSecret });
            // res = JSON.parse('{"zones":{"centralBaijio":{"name":"Central Baijo","slug":"central-baijio","progress":43,"subZones":{"sanPablo":{"name":"San Pablo","slug":"san-pablo","progress":288,"distributionCenters":{"Yaba":{"name":"Eko","slug":"eko","progress":50},"Yaba1":{"name":"Ibadan","slug":"ibd","progress":53}}},"sanMiguel":{"name":"San Miguel","slug":"san-miguel","progress":288,"distributionCenters":{"Yaba6":{"name":"Yaba","slug":"yaba","progress":45},"Yaba":{"name":"Eko","slug":"eko","progress":50}}}}},"Ola":{"name":"Ola iro","slug":"ola","progress":34,"subZones":{"saboYaba":{"name":"Sabo Yaba","slug":"sabo-yaba","progress":300,"distributionCenters":{"Yaba":{"name":"Eko","slug":"eko","progress":50},"Yaba1":{"name":"Ibadan","slug":"ibd","progress":53}}},"johnnyDrille":{"name":"Johnny Drille","slug":"johnny-drille","progress":425,"distributionCenters":{"Yaba6":{"name":"Yaba","slug":"yaba","progress":35},"Yaba":{"name":"Eko","slug":"eko","progress":50}}}}}}}');
          } catch (error) {
            setShowBlank(true);
            throw error;
          }
          if (res) {
            if (props.match.params.barChartType === 'subzone-bar-chart') {
              const subZones: any = Object.values(res.zones).reduce((a: any, c: any) => ({
                ...a,
                ...c.subZones,
              }), {});

              setState({
                ...state,
                level: 'subZone',
                res,
                data: getData(subZones),
              });

              return;
            }
          }
        }
      }

      if (level === 'zone') {
        setState({
          ...state,
          res,
          data: getData(res.zones),
          options: null,
        });
      } else {
        const zoneExist: any = Object.values(res.zones).find((zonE: any) => zonE.name === zone);

        if (zoneExist) {
          setState({
            ...state,
            res,
            data: getData(zoneExist.subZones),
            options: null,
          });
        } else {
          setSubzone('');
          setState({
            ...state,
            level: 'zone',
            res,
            current: {
              zone: '',
              subZone: '',
            },
            data: getData(res.zones),
            options: null,
          });
        }
      }
    })();
  }, [props.timeFilters, filter]);

  useEffect(() => {
    if (res) {
      if (level === 'zone') {
        if (!props.appName && isPercent) {
          zoneOptions.scales.yAxes[0].ticks.callback = (value: any) => `${Math.round(value)}%`;
          zoneOptions.plugins.datalabels.formatter = (value: any) => `${Math.round(value)}%`;
        }
        setState({ ...state, options: zoneOptions });
      } else {
        if (props.match && props.match.params.barChartType === 'subzone-bar-chart') {
          // delete subZoneOptions.plugins.datalabels.formatter;

          if (isPercent) subZoneOptions.plugins.datalabels.color = '#ffffff';
        }
        setState({ ...state, options: subZoneOptions });
      }
    }
  }, [res, level]);
  const averageValues = data ? data.reduce((a, c) => a + c.get('progress'), 0) / data.size : 0;
  let orderedSortersData = (
    data && data.sort(
      (a: any, b: any) => b.get('progress') - a.get('progress'),
    )
  );
  orderedSortersData = data && orderedSortersData!.push(fromJS({ name: 'Promedio MX T2', progress: averageValues }));

  const benchmark = isCapturas ? 80 : res && (() => {
    if (level === 'zone') {
      return res.benchmark;
    }

    const currentZone: any = (Object.values(res.zones).find((zon: any) => zon.name === state.current.zone));

    return currentZone && currentZone.benchmark;
  })();

  if (!props.appName) {
    const colors: string[][] = [
      ['rgba(147,177,247)', 'rgba(4,27,80)'],
      ['rgb(251, 220, 180)', 'rgb(255, 6, 6)'],
    ];
    if (isPercent) {
      backgroundColors = orderedSortersData && orderedSortersData.map(
        (dt: any) => (dt.get('progress') >= 80 ? colors[0][0] : colors[1][0]),
      ).toArray();
    } else if (orderedSortersData && orderedSortersData.get(0).get('benchmark')) {
      backgroundColors = orderedSortersData && orderedSortersData.map(
        (dt: any) => (dt.get('progress') >= dt.get('benchmark') ? colors[0][0] : colors[1][0]),
      ).toArray();
    } else if (data && data.size > backgroundColors.length) {
      let k = 0;
      const remainder = data.size - backgroundColors.length;
      for (let i = 0; i < remainder; i++) {
        if (k > backgroundColors.length - 1) { k = 0; }
        backgroundColors.push(backgroundColors[k]);
        k += 1;
      }
    }
  } else
  if (data && data.size) {
    if (isCapturas || isSelection) {
      backgroundColors = orderedSortersData && orderedSortersData.map(
        (dt: any) => (dt.get('progress') >= benchmark ? zoneColors[0][0] : zoneColors[1][0]),
      ).toArray();
    }


    let k = 0;
    const remainder = data.size - backgroundColors.length;
    for (let i = 0; i < remainder; i++) {
      if (k > backgroundColors.length - 1) { k = 0; }
      backgroundColors.push(backgroundColors[k]);
      k += 1;
    }
  }

  const changeSelection = (selectedVals: string[]) => {
    if (selectedVals.length === selectionOptions.length) {
      return setFilter('');
    }

    return setFilter(selectedVals.join(','));
  };

  return (
    <>
      {showBlank ? <div />
        : (
          <div className="card mbar">
            <div>
              {props.appName
        && (
        <nav>
          <UnderlineButton
            text={level === 'zone' ? props.appName : zone}
            onClick={() => {
              setSubzone('');
              setState({
                ...state,
                level: 'zone',
                current: {
                  zone: '',
                  subZone: '',
                },
                data: getData(res.zones),
                options: null,
              });
            }}
            style={{ fontWeight: 'bold' }}
            className="nav"
            disabled={level !== 'subZone'}
          />
          {level === 'subZone' && (
            <>
              &gt;
              <UnderlineButton text={`UENs ${zone}`} onClick={() => {}} style={{ fontWeight: 'bold' }} className="nav" disabled />
            </>
          )}
        </nav>
        )}
              {props.appName
        && (
        <div className={isSelection ? 'filter' : ''}>
          <div className="legends">
            {orderedSortersData && (level === 'zone' ? isCapturas ? (
              <>
                <li><span style={{ backgroundColor: '#7b93ca' }} /> &gt;=</li>
                <li className="bench">BenchMark(80%)</li>
                <li>&lt;&nbsp;<span style={{ backgroundColor: '#fb7a47' }} /></li>
              </>
            ) : ''
              : isCapturas ? (
                <>
                  <li><span style={{ backgroundColor: '#7b93ca' }} /> &gt;=</li>
                  <li className="bench">BenchMark(80%)</li>
                  <li>&lt;&nbsp;<span style={{ backgroundColor: '#fb7a47' }} /></li>
                </>
              ) : ''
            )}
            { isSelection ? (
              <div style={{ width: '10em' }}>
                <Selection options={selectionOptions} allTrueText={allTrueText} onClose={changeSelection} />
              </div>
            ) : ''}
          </div>
        </div>
        )}
            </div>
            {orderedSortersData && (level === 'zone' ? !isCapturas
              ? isSelection ? (
                <div className="legends">
                  <li><span style={{ backgroundColor: '#7b93ca' }} /> &gt;=</li>
                  <li className="bench">BenchMark({`${Math.round((Number(benchmark) + Number.EPSILON) * 100) / 100}`})</li>
                  <li>&lt;&nbsp;<span style={{ backgroundColor: '#fb7a47' }} /></li>
                </div>
              ) : (
                <div className="legends pb-3">
                  {orderedSortersData.map((s: any, i: number) => (
                    <li
            // eslint-disable-next-line react/no-array-index-key
                      key={i}
                    >
                      <span style={{ backgroundColor: backgroundColors[i] }} />{s.get('name')}
                    </li>
                  ))}
                </div>
              ) : ''
              : !isCapturas
                ? isSelection ? (
                  <div className="legends">
                    <li><span style={{ backgroundColor: '#7b93ca' }} /> &gt;=</li>
                    <li className="bench">BenchMark({`${Math.round((Number(benchmark) + Number.EPSILON) * 100) / 100}`})</li>
                    <li>&lt;&nbsp;<span style={{ backgroundColor: '#fb7a47' }} /></li>
                  </div>
                ) : (isOutsourcing || isRePackaging) ? (
                  <div className="legends pb-3">
                    {orderedSortersData.map((s: any, i: number) => (
                      <li
            // eslint-disable-next-line react/no-array-index-key
                        key={i}
                      >
                        <span style={{ backgroundColor: backgroundColors[i] }} />{s.get('name')}
                      </li>
                    ))}
                  </div>
                ) : (
                  <div className="legends pb3">
                    <li><span style={{ backgroundColor: 'rgb(127, 165, 255)' }} />Progreso</li>
                    <li><span style={{ backgroundColor: backgroundColors[0] }} />Meta</li>
                  </div>
                ) : '')}
            {(data && options)
              ? (
                <>
                  <BarChart
        // @ts-ignore
                    key={data}
                    getLabels={(data: any) => data.map((s: any) => s.get('name')).toArray()}
                    getDatasets={level === 'zone' ? getDatasetsForZone : getDatasetsForSubZone}
                    data={orderedSortersData}
                    options={options}
                  />
                  {subZone && (() => {
                    const zoneExist: any = Object.values(res.zones).find((zon: any) => zon.name === zone);
                    if (zoneExist) {
                      const sub: any = Object.values(zoneExist.subZones).find((dis: any) => dis.name === subZone);

                      if (sub) {
                        const distros = Object.values(sub.distributionCenters).map((dist: any) => (
                          <button
                            key={dist.slug}
                            type="button"
                            className="card"
                            onClick={() => {}}
                          >
                            <h4>{dist.name}</h4>
                            <p><span>{Math.round(dist.progress || 0)}</span> {
                              (isSelection || isOutsourcing || isRePackaging) ? 'cajas por persona' : (
                                props.url.includes('zone-wise-repackagers-productivity') ? '' : '% capturas'
                              )
                            }
                            </p>
                          </button>
                        ));

                        return (
                          <div className="card menus">
                            {distros}
                          </div>
                        );
                      }
                    }
                    return '';
                  })()}

                </>
              ) : <LoadingSpinner height={300} />}
          </div>
        )}
    </>
  );
};

type Props = {
  timeFilters: any;
  appName: string;
  url: string ;
  location?: any;
  match?: any;
};

const mapStateToProps = (state: any) => ({
  timeFilters: selectTimeFilterForLocation(state, 'corporate'),
});

export default connect(mapStateToProps)(MBarCharts);
