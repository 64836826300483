import React, { CSSProperties, ClassicElement } from 'react';

import useComponentVisible from '../../../utils/hooks/useComponentVisible';
import ChevronIcon from '../../../assets/icons/chevron-down-blue.svg';
import './OnClickDropdown.scss';

type Props = {
  children: ClassicElement<any>;
  dropdownContent: ClassicElement<any>;
  className?: string;
  style?: CSSProperties;
  dropdownElementsWidth?: any;
  dropdownContentHeight?: string | number; // Specifies the height of the
  // dropdown content and adds a scrollbar for the elements overflow (each element is aprox 50px).
  dropdownContentPositioning?: {
    top?: number | string;
    right?: number | string;
    bottom?: number | string;
    left?: number | string;
  };
  dropdownArrow?: boolean; // when set to true, displays a chevron down besides the children
  repositioningValue?: number;
};

const OnClickDropdown = ({
  children,
  dropdownContent,
  className = '',
  style,
  dropdownElementsWidth,
  dropdownContentHeight = undefined,
  dropdownContentPositioning,
  dropdownArrow = false,
  repositioningValue = 42,
}: Props) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const toggleDropdown = () => setIsComponentVisible(!isComponentVisible);
  const isElementInViewPort = (el: any) => {
    if (el) {
      const rect = el.getBoundingClientRect();
      // eslint-disable-next-line no-undef
      return (rect.top + 150) < window.innerHeight;
    }
  };
  return (
    <div className={`on-click-dropdown ${className}`} style={style}>
      <div role="button" className="dropdown-container" onClick={toggleDropdown}>
        <div ref={ref}>
          {/* Children and optional dropdown arrow */}
          <div className="d-flex align-content-center">
            {/* The children prop is the element that displays the dropdown
            content with an onClick */}
            {children}
            {dropdownArrow && (
            <img
              src={ChevronIcon}
              className={`${isComponentVisible ? 'rotate' : ''}`}
              style={{ marginLeft: 10 }}
              alt="chevron-down-blue"
              width={12}
            />
            )}
          </div>
          {/* Dropdown content that displays with children onClick */}
          {isComponentVisible && (
            <div
              className="dropdown-content"
              style={{
                minWidth: dropdownElementsWidth,
                maxHeight: dropdownContentHeight,
                display: !isComponentVisible ? 'none' : 'block',
                overflowY: dropdownContentHeight ? 'scroll' : undefined,
                bottom: isElementInViewPort(ref.current) ? undefined : repositioningValue,
                ...dropdownContentPositioning,
              }}
            >
              {dropdownContent}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OnClickDropdown;
