/* eslint-disable no-undef */
/* eslint-disable react/button-has-type */
import React, { FC, useState, useEffect, useLayoutEffect } from 'react';
import arrow from '../../../assets/icons/chevron-down.svg';
import useComponentVisible from '../../../utils/hooks/useComponentVisible';
import './MultipleSelect.scss';

const MultipleSelect: FC<Props> = props => {
  const [selections, setSelections] = useState<string[]>([]);
  const [toggle, setToggle] = useState(false);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const makeSelection = ({ target: { value } }: any) => {
    const exist = selections.some(selection => value === selection);
    if (exist) {
      if (selections.length > 1) setSelections(selections.filter(selection => selection !== value));
    } else {
      // @ts-ignore
      setSelections([...selections, value]);
    }
  };

  useEffect(() => {
    setSelections(props.options.map(option => option.value));
  }, []);

  useEffect(() => {
    if (!isComponentVisible && selections.length) props.onClose(selections);
    setToggle(isComponentVisible);
  }, [isComponentVisible]);


  const isSelection = (value: string) => selections.some(selection => value === selection);
  const selectionText = () => {
    const selected: string[] = [];
    props.options.forEach(option => {
      if (selections.includes(option.value)) {
        selected.push(option.label);
      }
    });

    if (selected.length === props.options.length && props.allTrueText) {
      return props.allTrueText;
    }
    return selected.join(', ');
  };

  const toggleHandler = () => {
    if (toggle) { props.onClose(selections); }
    setToggle(!toggle);
    setIsComponentVisible(!toggle);
  };

  const title = (() => {
    const selc: string[] = [];
    props.options.forEach(option => {
      if (selections.includes(option.value)) {
        selc.push(option.label);
      }
    });
    return selc.join(', ');
  })();

  return (
    // @ts-ignore
    <div className="mdropDown" ref={ref}>
      <button
        id="rtTYDU"
        role="combobox"
        aria-controls="rtTYDU"
        aria-expanded={false}
        onClick={toggleHandler}
        tabIndex={0}
        title={title}
      >
        <p>{selectionText()}</p>
        <img src={arrow} alt="arrow" className={toggle ? 'open' : ''} />
      </button>
      {toggle
      && (
      <div>
        {props.options.map(({ value, label }, i) => (
          <button
            role="option"
            aria-selected={isSelection(value)}
            onClick={makeSelection}
            value={value}
            className={isSelection(value) ? 'selected' : ''}
            key={value}
            tabIndex={0}
          >
            {label}
          </button>
        ))}
        <button className="apply" onClick={toggleHandler}>Aplicar filtro</button>
      </div>
      )}
    </div>
  );
};

type Props = {
  allTrueText?: string;
  options: {
      value: string;
      label: string;
    }[];
  onClose: (selections: string[]) => any;
}

export default MultipleSelect;
