/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-shadow */
import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fromJS, List } from 'immutable';
import './Forklift.scss';
import { selectTimeFilterForLocation } from '../../../selectors';
import { generateQueryDates } from '../../../actions/corporateAgencies.actions';
import LoadingSpinner from '../../../components/LoadingSpinner';
import BarChart from '../../../components/Charts/BarChart';
import UnderlineButton from '../../../components/Button/UnderlineButton';
import request from '../../../utils/request';


const Forklift: FC<Props> = props => {
  const [subZone, setSubzone] = useState('');
  const zoneOptions = {
    events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
    myOnClick: (event: any, chtElement: any, ctx: any) => {
      const e = chtElement[0];
      if (e) {
        const xValue = ctx.data.labels[e._index];

        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        gotoSubzone(xValue);
      }
    },
    scales: {
      yAxes: [{
        ticks: {
          callback: (value: any) => Math.round(value),
        },
      }],
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem: any) => (tooltipItem.datasetIndex ? ' 900' : ` ${Math.round((Number(tooltipItem.value) + Number.EPSILON) * 100) / 100}`),
      },
    },
    plugins: {
      datalabels: {
        color: '#ffffff',
        formatter: (value: any) => Math.round(value),
      },
    },
  };


  const colors: string[][] = [
    ['rgba(147,177,247)', 'rgba(4,27,80)'],
    ['rgb(251, 220, 180)', 'rgb(255, 6, 6)'],
  ];

  const [state, setState] = useState<{
    data: List<any> | null;
    res: any;
    options: any;
    level: string;
    current: { zone: string };
  }>({
    level: 'zone',
    current: {
      zone: '',
    },
    data: null,
    res: null,
    options: null,
  });


  const { data, options, res, level, current } = state;

  const getData = (obj: object) => fromJS(Object.values(obj).map((data: any) => ({
    name: data.name,
    progress: data.progress,
    benchmark: data.benchmark,
  })));


  const getDatasets = (data: any, ctx: any) => [{
    data: data.map((s: any) => s.get('progress')).toArray(),
    borderColor: backgroundColors,
    // @ts-ignore
    backgroundColor: backgroundColors.map((color: string) => {
      const gradient = ctx.createLinearGradient(0, 0, 0, 300);
      gradient.addColorStop(0, color === colors[0][0] ? colors[0][1] : colors[1][1]);
      gradient.addColorStop(1, `${color.slice(0, -1)}, 0.8)`);
      return gradient;
    }),
  }];


  const gotoSubzone = (zoneName: string) => {
    const zone: any = Object.values(res.zones).find((zone: any) => zone.name === zoneName);

    const subzone = zone.subZones;
    setState({
      ...state,
      level: 'subZone',
      current: {
        zone: zoneName,
      },
      data: getData(subzone),
      options: null,
    });

    // @ts-ignore
    setSubzone(Object.values(subzone).sort((a: any, b: any) => b.progress - a.progress)[0].name);
  };

  useEffect(() => {
    (async () => {
      setState({
        ...state,
        data: null,
        res: null,
        options: null,
      });

      if (!props.timeFilters) return;
      const res = await request.get('/api/v1/corporate/zone-wise-forklift-productivity', generateQueryDates(
        props.timeFilters.get('startDate'),
        props.timeFilters.get('endDate'),
      ));


      if (!Object.keys(res.zones).length) {
        setSubzone('');
        setState({
          ...state,
          level: 'zone',
          res,
          current: {
            zone: '',
          },
          data: getData(res.zones),
          options: null,
        });
        return;
      }

      if (level === 'zone') {
        setState({
          ...state,
          res,
          data: getData(res.zones),
          options: null,
        });
      } else {
        const zoneExist: any = Object.values(res.zones)
          .find((zonE: any) => zonE.name === current.zone);

        if (zoneExist) {
          setState({
            ...state,
            res,
            data: getData(zoneExist.subZones),
            options: null,
          });
        } else {
          setSubzone('');
          setState({
            ...state,
            level: 'zone',
            res,
            current: {
              zone: '',
            },
            data: getData(res.zones),
            options: null,
          });
        }
      }
    })();
  }, [props.timeFilters]);

  useEffect(() => {
    if (res) {
      if (level === 'zone') {
        setState({ ...state, options: zoneOptions });
      } else {
        const subZoneOptions = {
          ...zoneOptions,
          myOnClick: (event: any, chtElement: any, ctx: any) => {
            const e = chtElement[0];
            if (e) {
              const xValue = ctx.data.labels[e._index];
              setSubzone(xValue);
            }
          },
        };
        setState({ ...state, options: subZoneOptions });
      }
    }
  }, [res, level]);

  const orderedSortersData = (
    data && data.sort(
      (a: any, b: any) => b.get('progress') - a.get('progress'),
    )
  );

  const benchmark = res && (() => {
    if (level === 'zone') {
      return res.benchmark;
    }

    // @ts-ignore
    return (Object.values(res.zones).find((zon: any) => zon.name === current.zone)).benchmark;
  })();

  const backgroundColors = orderedSortersData && orderedSortersData.map(
    (dt: any) => (dt.get('progress') >= benchmark ? colors[0][0] : colors[1][0]),
  ).toArray();


  if ((backgroundColors && data) && data.size > backgroundColors.length) {
    let k = 0;
    const remainder = data.size - backgroundColors.length;
    for (let i = 0; i < remainder; i++) {
      if (k > backgroundColors.length - 1) { k = 0; }
      backgroundColors.push(backgroundColors[k]);
      k += 1;
    }
  }


  return (
    <div className="forklift">
      <div className="card">
        <div>
          <nav>
            <UnderlineButton
              text={level === 'zone' ? props.appName : current.zone}
              onClick={() => {
                setSubzone('');
                setState({
                  ...state,
                  level: 'zone',
                  current: {
                    zone: '',
                  },
                  data: getData(res.zones),
                  options: null,
                });
              }}
              style={{ fontWeight: 'bold' }}
              className="nav"
              disabled={level !== 'subZone'}
            />
            {level === 'subZone' && (
              <>
              &gt;
                <UnderlineButton text={`UENs ${current.zone}`} onClick={() => {}} style={{ fontWeight: 'bold' }} className="nav" disabled />
              </>
            )}
          </nav>
          <div>
            <li><span style={{ backgroundColor: '#7b93ca' }} /> &gt;=</li>
            <li className="bench">BenchMark({Math.round(benchmark || 0) || ''})</li>
            <li>&lt;&nbsp;<span style={{ backgroundColor: '#fb7a47' }} /></li>
          </div>
        </div>
        {(data && options)
          ? (
            <>
              <BarChart
                // @ts-ignore
                key={data}
                getLabels={(data: any) => data.map((s: any) => s.get('name')).toArray()}
                getDatasets={getDatasets}
                data={orderedSortersData}
                options={options}
              />
              {subZone && (() => {
                const { zone } = current;
                const zoneExist: any = Object.values(res.zones).find((zon: any) => zon.name === zone);

                if (zoneExist) {
                  const sub: any = Object.values(zoneExist.subZones).find((dis: any) => dis.name === subZone);

                  if (sub) {
                    const distros = Object.values(sub.distributionCenters).map((dist: any) => (
                      <button
                        key={dist.slug}
                        type="button"
                        className="card"
                        onClick={() => {}}
                      >
                        <h4>{dist.name}</h4>
                        <p><span>{Math.round(dist.progress || 0)}</span> Cartones desplazados/montacarguista</p>
                      </button>
                    ));

                    return (
                      <div className="card menus">
                        {distros}
                      </div>
                    );
                  }
                }
                return '';
              })()}
            </>
          ) : <LoadingSpinner height={300} />}
      </div>
    </div>
  );
};

type Props = {
  timeFilters: any;
  url: string;
  appName: string;
};


const mapStateToProps = (state: any) => ({
  timeFilters: selectTimeFilterForLocation(state, 'corporate'),
});

export default connect(mapStateToProps)(Forklift);
