import React, { Component } from 'react';

import './Toggle.scss';

export default class Toggle extends Component<Props, any> {
  render() {
    return (
      <div className="toggle">
        {this.props.options.map((option: any) => (
          <button
            type="button"
            key={option.key}
            className={`toggle ${
              this.props.selectedOption === option.key ? 'button-on' : 'button-off'}`}
            onClick={() => this.props.onOptionSelect(option.key)}
          >
            {option.text}
          </button>
        ))}
      </div>
    );
  }
}

type Props = {
  options: Array<{ text: string; key: string }>;
  onOptionSelect: any;
  selectedOption: string;
};
