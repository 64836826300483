import React, { Component } from 'react';
import { scaleLinear } from 'd3-scale';

import LineChart from '../../../../../components/Charts/LineChart';
import './HourlySelectionLineChart.scss';
import { formatTimeLabel } from '../../../../../utils/dateHelpers';

class HourlySelectionLineChart extends Component<Props, any> {
  static createColors(detail: any) {
    let dataColors: any = [];
    let numColor = 0;
    const popScale = scaleLinear()
      .domain([0, detail.get('users').size / 2, detail.get('users').size])
    // @ts-ignore
      .range(['#ff7a00', '#00f7ff', '#ff00a8']);
    detail.get('users').forEach(() => {
      dataColors = [...dataColors, popScale(numColor)];
      numColor += 1;
    });
    return dataColors;
  }
  generateDataSets(detail: any, colors: any = '#2574FB', unit: string) {
    const unitAccessor = unit === 'cajas' ? 'sortBoxes' : 'sortPallets';
    const dataSet: any = [];
    if (this.props.type === 'perPerson') {
      detail.get('users').forEach((user: any, i: number) => {
        dataSet.push({
          lineTension: 0,
          fill: true,
          backgroundColor:
            `${colors[i].slice(0, 3)}a${colors[i].slice(3, colors[i].length - 1)}, 0.01)`,
          pointRadius: 4,
          borderWidth: 1.5,
          backgroundOpacity: 0.5,
          // lineOpacity: 0.1,
          pointBackgroundColor: 'white',
          pointBorderColor: colors[i],
          borderColor: colors[i],
          data: user.get(unitAccessor).toArray().reduce(
            (a: [], c: number) => {
              if (a.length === 0) return [c];
              const lastNumber = a[a.length - 1];
              const currentNumber = lastNumber + c;
              // @ts-ignore
              a.push(currentNumber);
              return a;
            }, [],
          ),
        });
      });
    }
    if (this.props.type === 'totals') {
      dataSet.push({
        lineTension: 0,
        fill: false,
        pointRadius: 4,
        borderWidth: 1.5,
        pointBackgroundColor: 'white',
        pointBorderColor: '#2574FB',
        borderColor: '#2574FB',
        data: detail.get('sortTimes').map((_: any, i: number) => {
          let totalUnits = 0;
          detail.get('users').forEach((user: any) => {
            totalUnits += user.getIn([unitAccessor, i]);
          });
          return totalUnits;
        }).toArray(),
      });
    }
    return dataSet;
  }

  render() {
    const { detail, unit } = this.props;
    const colors = HourlySelectionLineChart.createColors(detail);
    const timeUnit = detail.get('slotsType');
    const startTimes = formatTimeLabel(detail.get('sortTimes'),
      timeUnit,
      detail.get('timeOffsetForDistCenter'));

    return (
      <>
        <div className="hourly-selection-line-chart">
          <div className="row title">
            <div className="col-1">
              <div className="row">
                <p className="title-string">{unit}</p>
                <div style={{ height: 10, width: 1, border: '1px solid #2574FB' }} />
              </div>
            </div>
            {this.props.type === 'perPerson'
              ? detail.get('users').map((user: any, i: number) => (
                <div className="label-container" key={user.get('id')}>
                  <div key={user.get('name')}>
                    <div
                      className="label"
                      style={{ background: `${colors[i]}` }}
                    />
                    <p style={{ display: 'inline-block' }}>{user.get('name')}</p>
                  </div>
                </div>
              ))
              : null}
          </div>
        </div>
        <LineChart
          data={this.generateDataSets(detail, colors, unit)}
          labels={startTimes}
          height={488}
          areaChart={false}
        />
      </>
    );
  }
}

type Props = {
  detail: any;
  type?: string;
  unit: string;
};

export default HourlySelectionLineChart;
