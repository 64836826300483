import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ContentDivisionHeader from '../../../components/Layout/ContentDivisionHeader';
import SortersTotalSelection from './SortersTotalSelection';
import SortedProductsTimeChart from './SortedProductsTimeChart';
import DCGoals from './DCGoals';
import DoubleProductivityCard from './DoubleProductivityCard';
import { clearStateForDistCenter } from '../../../actions/homeDashboard.actions';

class HomeDashboard extends PureComponent<Props, {}> {
  componentDidUpdate() {
    this.props.clearStateForDistCenter(this.props.match.params.distCenter);
  }

  render() {
    return (
      <>
        {/* 1st row */}
        <ContentDivisionHeader title="Selección" />
        <div className="row">
          {/* bar chart: SORTERS */}
          <div className="col-12 col-md-9">
            <SortersTotalSelection />
          </div>
          {/* pie chart: GOAL */}
          <div className="col-12 col-md-3">
            <DCGoals />
          </div>
        </div>

        {/* area chart (presentations): STACKERS */}
        <SortedProductsTimeChart />

        <ContentDivisionHeader title="Productividad" />
        {/* most and least productive */}
        <DoubleProductivityCard distCenterSlug={this.props.match.params.distCenter} />
      </>
    );
  }
}

type Props = {
  match: any;
  clearStateForDistCenter(distCenter: string): void;
};

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    clearStateForDistCenter,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeDashboard);
