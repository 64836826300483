import React, { FC, useEffect, useState, Fragment } from 'react';
import deepClone from 'lodash.clonedeep';
import ContentDivisionHeader from '../../../../components/Layout/ContentDivisionHeader';
import DeleteIcon from '../../../../assets/icons/delete-icon.svg';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import request from '../../../../utils/request';
import './QuotaPresentation.scss';


type Props = {
  distCenter: string;
}

const QuotaPresentation: FC<Props> = props => {
  const [state, setState] = useState<{
    name: string;
    slug: string;
    products: {
      name: string;
      slug: string;
    }[];
  }[] | null>(null);

  const [newProd, setNewProd] = useState('');
  const [selected, setSelected] = useState('');

  useEffect(() => {
    (async () => {
      const res: {
          presentations: {
            name: string;
            slug: string;
            products: {
              name: string;
              slug: string;
            }[];
          }[];
        } = await request.get(`/api/v1/distribution-centers/${props.distCenter}/products`);


      if (res.presentations.length) {
        setState(res.presentations);
        setSelected(res.presentations[0].slug);
      }
    })();
  }, []);

  useEffect(() => { setNewProd(''); }, [selected]);

  const onAddProduct = async () => {
    if (newProd.trim()) {
      const newState = deepClone(state);
      if (newState) {
        const presentation = newState.find(present => present.slug === selected);

        if (presentation) {
          const res = await request.post(`/api/v1/distribution-centers/${props.distCenter}/products`, {
            products: [
              {
                presentationSlug: selected,
                productName: newProd.trim(),
              },
            ],
          });

          res.forEach((p: any) => presentation.products.push({ name: p.name, slug: p.slug }));

          setState(newState);
          setNewProd('');
        }
      }
    }
  };

  const onRemoveProduct = async (prodSlug: string) => {
    const newState = deepClone(state);
    if (newState) {
      const presentation = newState.find(present => present.slug === selected);

      if (presentation) {
        await request.delete(`/api/v1/distribution-centers/${props.distCenter}/products`, {
          productSlug: prodSlug,
        });

        presentation.products = presentation.products.filter(prod => prod.slug !== prodSlug);
        setState(newState);
      }
    }
  };

  return (
    <>
      <ContentDivisionHeader title="Presentaciones por cuota " />
      <div className="card limited-width text-smaller quo-pres" style={{ padding: 14 }}>
        {state
          ? (
            <div className="d-flex flex-row  p-2">
              <ul className="menu">
                {state.map(present => (
                  <li key={present.slug} className={`${present.slug === selected ? 'active' : ''}`}>
                    <button type="button" onClick={() => setSelected(present.slug)}>{present.name}</button>
                  </li>
                ))}
              </ul>
              {(() => {
                const selectedPresent = state.find(present => present.slug === selected);

                if (selectedPresent) {
                  const addForm = (
                    <form onSubmit={e => { e.preventDefault(); onAddProduct(); }}>
                      <input type="text" value={newProd} onChange={e => setNewProd(e.target.value)} />
                      {newProd.trim() && <button type="submit">Agregar</button>}
                    </form>
                  );

                  const options = selectedPresent.products.map((prod, index) => {
                    const form = index === selectedPresent.products.length - 1 ? addForm : '';

                    return (
                      <Fragment key={prod.slug}>
                        <li>{prod.name}{' '}
                          <button
                            type="button"
                            onClick={() => onRemoveProduct(prod.slug)}
                          >
                            <img src={DeleteIcon} alt="Delete Icon" />
                          </button>
                        </li>
                        {form}
                      </Fragment>
                    );
                  });

                  return (
                    <ul className="option" key={selectedPresent.slug}>
                      {options.length ? options : addForm}
                    </ul>
                  );
                }
              })()}
            </div>
          ) : <LoadingSpinner height={200} />}
      </div>
    </>
  );
};

export default QuotaPresentation;
