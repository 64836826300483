import { Collection, Map } from 'immutable';

export type ApiRequestMethodType = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

export type RequestStatusType = 'success' | 'pending' | 'fail';

export enum FILTER_OPTIONS {
  lastDay = 'LAST_DAY',
  lastWeek = 'LAST_WEEK',
  lastMonth = 'LAST_MONTH',
  lastHour = 'LAST_HOUR',
  customPeriod = 'CUSTOM_PERIOD',
}

export enum PAGE {
  CORPORATE_HOME = 'corporate',
  CORPORATE_AGENCIES = 'corporate-agencies',
  DIST_CENTER_HOME = 'distribution-centers',
}

export enum UserType {
  Corporate = 'Corporate',
  DistributionCenterManager = 'DistributionCenterManager',
  Forklift = 'Forklift',
  Sorter = 'Sorter',
  PalletMaker = 'PalletMaker',
}

export enum UnitType {
  boxes = 'cajas',
  pallets = 'tarimas',
}

export enum TimeFilterOption {
  LAST_HOUR,
  LAST_DAY,
  LAST_WEEK,
  LAST_MONTH,
  CUSTOM_PERIOD,
}

// Corporate: Agencies: zone cards
export enum ZoneIndexDepthOfView {
  ZoneView = 1,
  SubZoneView = 2,
  DistCenterView = 3,
}

/* interfaces */

// distribution center Info
export interface DistributionCenterInfo {
  name: string;
  roles: {
    slug: string;
    name: string;
  }[];
  shifts: {
    startTime: string; // e.g. "7.00"
    lengthInHours: string; // e.g. "9.00"
    name: string; // e.g. "Morning"
    slug: string; // e.g. "morning"
  }[];
  slug: string;
  timezone: string; // e.g. "US/Central"
  timezoneOffsetInMinutes: number; // e.g. 360
  currency: string; // iso 3-letter code
  currencySymbol: string;
}

export interface DistributionCenterInfoMap extends Map<string, any> {
  name: string;
  roles: {
    slug: string;
    name: string;
  }[];
  shifts: {
    startTime: string; // e.g. "7.00"
    lengthInHours: string; // e.g. "9.00"
    name: string; // e.g. "Morning"
    slug: string; // e.g. "morning"
  }[];
  slug: string;
  timezone: string; // e.g. "US/Central"
  timezoneOffsetInMinutes: number; // e.g. 360
  currency: string; // iso 3-letter code
  currencySymbol: string;
}

// CorporateHome: productivity chart
export interface ProductivityChart {
  unit: string;
  slotsType: string;
  slots: any[];
  slotsMapped: any[];
}

export interface ProductivityChartMap extends Map<string, any> {
  unit: string;
  slotsType: string;
  slots: Collection<any, any>;
  slotsMapped: Collection<any, any>;
}

// CorporateHome: employees count
export interface EmployeesCount {
  [UserType.Sorter]: number;
  [UserType.Forklift]: number;
  [UserType.PalletMaker]: number;
}

// CorporateHome: most and least productive workers
type WorkerInMostLeastProductiveWorkers = {
  distCenterSlug: string;
  quantity: string;
  unit: string;
  userSlug: string;
  name: string;
};

export interface MostLeastProductiveWorkers {
  mostProductive: WorkerInMostLeastProductiveWorkers;
  leastProductive: WorkerInMostLeastProductiveWorkers;
}

export interface WorkerInMostLeastProductiveWorkersMap extends Map<string, string> {
  distCenterSlug: string;
  quantity: string;
  unit: string;
  userSlug: string;
  name: string;
}

// CorporateHome: most and least productive agencies
export type AgencyInMostLeastProductiveAgencies = {
  distSlug: string;
  quantity: number; // number of units sorted
  sorters: number; // number of sorters
  currentPeriodProductivity: number;
  LastPeriodProductivity: number;
};

export interface MostLeastProductiveAgencies {
  unit: string;
  TopThree: AgencyInMostLeastProductiveAgencies[];
  WorstThree: AgencyInMostLeastProductiveAgencies[];
}

// Corporate wrapper: zones
export interface Zone {
  name: string;
  slug: string;
  externalId: string;
}

// dist center: configuration page

export interface WorkersCompensation {
  factorPerBox: number;
  // PS: we receive and send numbers from/to the server. However, on the app, we use only string.
  presentation: {
    name: string;
    slug: string;
  };
}

export interface WorkersCompensationMap extends Map<string, any> {
  factorPerBox: number;
  presentation: {
    name: string;
    slug: string;
  };
}

export type WorkersCompensations = WorkersCompensation[];
