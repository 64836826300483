import React, { Component } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import { NavLink } from 'react-router-dom';
import UnderlineButton from '../../../../components/Button/UnderlineButton';
import { PAGE, ZoneIndexDepthOfView } from '../../../../types/types-enums-interfaces';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { selectCorporateAgenciesZones } from '../../../../selectors';
import { getZonesTotals } from '../../../../actions/corporateAgencies.actions';

type Props = {
  dispatch: any;
  timeFilters: any;
  // store
  zonesTotals: any;
};
type State = {
  depthOfIndex: ZoneIndexDepthOfView;
  chosenZoneSlug: string;
  chosenSubZoneSlug: string;
  fetchingData: boolean;
};

class ZonesCards extends Component<Props, State> {
  static navigateToDistributionCenter(distCenterSlug: string) {
    return {
      pathname: `/distribution-centers/${distCenterSlug}`,
      state: { from: PAGE.CORPORATE_AGENCIES },
    };
  }
  constructor(props: Props) {
    super(props);
    this.state = {
      depthOfIndex: ZoneIndexDepthOfView.ZoneView,
      chosenZoneSlug: '',
      chosenSubZoneSlug: '',
      fetchingData: false,
    };
    this.handleIndexDepthChange = this.handleIndexDepthChange.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount(): void {
    if (this.props.zonesTotals) return this.fetchData(false);
    this.fetchData();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.timeFilters !== prevProps.timeFilters) {
      this.fetchData();
    }
  }

  fetchData(showSpinner = true) {
    if (showSpinner) this.setState({ fetchingData: true });

    if (this.props.timeFilters) {
      return this.props.dispatch(getZonesTotals(
        this.props.timeFilters.get('startDate'),
        this.props.timeFilters.get('endDate'),
      )).then(() => this.setState({ fetchingData: false }));
    }

    this.props.dispatch(getZonesTotals()).then(() => this.setState({ fetchingData: false }));
  }

  handleIndexDepthChange(slug: string, index?: number) {
    if (index) return this.setState({ depthOfIndex: index });

    const { depthOfIndex } = this.state;

    if (depthOfIndex === ZoneIndexDepthOfView.ZoneView) this.setState({ chosenZoneSlug: slug });
    if (depthOfIndex === ZoneIndexDepthOfView.SubZoneView) {
      this.setState({ chosenSubZoneSlug: slug });
    }

    this.setState({ depthOfIndex: depthOfIndex + 1 });
  }

  render() {
    const { zonesTotals, timeFilters } = this.props;
    const { depthOfIndex, chosenZoneSlug, chosenSubZoneSlug, fetchingData } = this.state;

    let cardsData = zonesTotals;
    if (depthOfIndex === ZoneIndexDepthOfView.SubZoneView) {
      cardsData = zonesTotals && zonesTotals.getIn([chosenZoneSlug, 'subZones']);
    }
    if (depthOfIndex === ZoneIndexDepthOfView.DistCenterView) {
      cardsData = zonesTotals && zonesTotals.getIn([chosenZoneSlug, 'subZones',
        chosenSubZoneSlug, 'distribution-centers']);
    }

    const dateFormat = 'D/M, hA';

    return (
      <>
        {/* nav & period info */}
        <div className="row justify-content-between align-items-baseline">
          <div className="col-auto">
            {/* nav */}
            <h5 className="pl-1 text-light">
              {/* zone */}
              <UnderlineButton
                disabled={depthOfIndex === ZoneIndexDepthOfView.ZoneView}
                text={'Todas las Zonas (DRV\'s)'}
                onClick={() => this.handleIndexDepthChange('', ZoneIndexDepthOfView.ZoneView)}
              />
              {/* sub zone */}
              {depthOfIndex > ZoneIndexDepthOfView.ZoneView
              && (
                <>
                  &gt; <UnderlineButton
                    disabled={depthOfIndex === ZoneIndexDepthOfView.SubZoneView}
                    text={zonesTotals.getIn([chosenZoneSlug, 'name'])}
                    onClick={() => this.handleIndexDepthChange('', ZoneIndexDepthOfView.SubZoneView)}
                  />
                </>
              )}
              {/* dist center */}
              {depthOfIndex === ZoneIndexDepthOfView.DistCenterView
              && <span> &gt; {zonesTotals.getIn([chosenZoneSlug, 'subZones', chosenSubZoneSlug, 'name'])}</span>}
            </h5>
          </div>

          {/* period info */}
          <div className="col-auto">
            {timeFilters && (
            <p className="text-muted text-smallest">
              información de {format(timeFilters.get('startDate'), dateFormat)} –– {format(
              timeFilters.get('endDate'),
              dateFormat,
            )}
            </p>
            )}
          </div>
        </div>

        {/* cards */}
        <div className="row align-items-center">
          {!fetchingData && zonesTotals && zonesTotals.size ? cardsData.valueSeq()
            .map((zone: any) => (
              <NavLink
                key={zone.get('slug')}
                className="col-6 col-sm-4 col-lg-3 clickable clickableOpacity"
                onClick={e => {
                  if (depthOfIndex !== ZoneIndexDepthOfView.DistCenterView) {
                    e.preventDefault(); // prevent link from navigating
                    this.handleIndexDepthChange(zone.get('slug'));
                  }
                }}
                to={ZonesCards.navigateToDistributionCenter(zone.get('slug'))}
              >
                <div className="card mx-1">
                  <h5 className="text-smallest">{zone.get('name')}</h5>
                  <p className="text-smaller text-primary font-bold">
                    {zone.get('totalBoxes') && zone.get('totalBoxes').toLocaleString()}
                    <small className="text-muted"> cajas seleccionadas</small>
                  </p>
                </div>
              </NavLink>
            ))
            : <LoadingSpinner style={{ marginTop: 75 }} />}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => (
  {
    zonesTotals: selectCorporateAgenciesZones(state),
  }
);

export default connect(mapStateToProps)(ZonesCards);
