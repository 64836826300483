import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { getSortersSummary } from '../../../../actions/sortingDashboard.actions';

import { ReactComponent as UserIcon } from '../../../../assets/icons/user.svg';
import { ReactComponent as UserWarningIcon } from '../../../../assets/icons/user-warning.svg';
import { ReactComponent as SummaryIcon } from '../../../../assets/icons/summary.svg';
import { ReactComponent as BoxLightIcon } from '../../../../assets/icons/box-light.svg';
import { ReactComponent as BoxDarkIcon } from '../../../../assets/icons/box-dark.svg';

import {
  selectHomeCurrentDistCenter,
  selectSortingDashboardsortersSummary,
} from '../../../../selectors';

import './SortersSummary.scss';

class SortersSummary extends Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.getDataForTimeFilter = this.getDataForTimeFilter.bind(this);
  }

  componentDidMount() {
    if (this.props.timeFilter) return this.getDataForTimeFilter();
    this.props.dispatch(getSortersSummary());
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.timeFilter && prevProps.timeFilter
      !== this.props.timeFilter) this.getDataForTimeFilter();
    if (prevProps.currentDistCenter !== this.props.currentDistCenter) {
      this.getDataForTimeFilter();
    }
  }

  getDataForTimeFilter() {
    this.props.dispatch(
      getSortersSummary(this.props.timeFilter.get('startDate'),
        this.props.timeFilter.get('endDate')),
    );
  }

  render() {
    const { sortersSummary, productUnit } = this.props;
    const unit = productUnit === 'boxes' ? 'cajas' : 'tarimas';
    const total = productUnit === 'boxes' ? 'currentSelection' : 'currentSelectionInPallets';
    const perMinute = productUnit === 'boxes' ? 'boxesPerMinute' : 'palletsPerMinute';
    const perHour = productUnit === 'boxes' ? 'boxesPerHour' : 'palletsPerHour';

    return (
      <div className="card sorters-summary">
        {sortersSummary
          ? (
            <>
              {/* header */}
              <div className="row card-header-row">
                <div className="col">
                  <div className="row">
                    <SummaryIcon style={{ width: 20, height: 20, marginRight: 10 }} className="summary-image" />
                    <p>Resumen</p>
                  </div>
                </div>
              </div>
              {/* total */}
              <div className="row card-section">
                <div className="col no-padding">
                  <p className="text-light">En total se han seleccionado</p>
                  <p>{sortersSummary.get(total) && sortersSummary.get(total)
                    .toLocaleString()} {unit}
                  </p>
                </div>
              </div>
              {/* by period */}
              <div className="row card-section">
                <div className="col box-detail">
                  <div className="row">
                    <BoxLightIcon style={{ width: 15, height: 15 }} className="card-image" />
                    <p>{(sortersSummary.get(perMinute)
                  && parseInt(sortersSummary.get(perMinute), 10)) || 0} {unit}
                    </p>
                  </div>
                  <div className="row">
                    <BoxDarkIcon style={{ width: 15, height: 15 }} className="card-image" />
                    <p>{(sortersSummary.get(perHour) && parseInt(sortersSummary.get(perHour), 10)
                      .toLocaleString()) || 0} {unit}
                    </p>
                  </div>
                </div>
                <div className="col">
                  <p className="text-light box-unit-measure">por minuto</p>
                  <p className="text-light box-unit-measure">por hora</p>
                </div>
              </div>
              {/* most and least productive */}
              <div className="row card-section card-last-section no-gutters">
                <div className="col-auto icons-container">
                  <UserIcon style={{ width: 20, height: 20, marginTop: 10 }} className="card-image" />
                  <UserWarningIcon style={{ width: 20, height: 20 }} className="card-image" />
                </div>
                <div className="col">
                  <p className="text-light">el más productivo fue</p>
                  <p style={{ marginBottom: 10 }}>{sortersSummary.get('mostProductive')}</p>
                  <p className="text-light">el menos productivo fue</p>
                  <p>{sortersSummary.get('leastProductive')}</p>
                </div>
              </div>
            </>
          )
          : <LoadingSpinner height={250} />}
      </div>
    );
  }
}

type Props = {
  sortersSummary: any;
  dispatch: any;
  productUnit: string;
  timeFilter: any;
  currentDistCenter: string;
};

function mapStateToProps(state: any) {
  return {
    sortersSummary: selectSortingDashboardsortersSummary(state),
    currentDistCenter: selectHomeCurrentDistCenter(state),
  };
}

export default connect(mapStateToProps)(SortersSummary);
