import React, { Component } from 'react';
import { connect } from 'react-redux';
import { selectTimeFilterForLocation } from '../../../selectors';
import ZonesCards from './ZonesCards';

type Props = {
  dispatch: any;
  // store
  timeFilters: any;
};
type State = {};

class CorporateAgencies extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { timeFilters } = this.props;

    return (
      <div>
        {/* map */}
        {/* <div className="row justify-content-center align-items-center">
          <div className="col-auto">
            <h1>MAP</h1>
          </div>
        </div> */}

        {/* cards: zones, subzones, distcenters */}
        <ZonesCards timeFilters={timeFilters} />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => (
  {
    timeFilters: selectTimeFilterForLocation(state, 'corporate'),
  }
);

export default connect(mapStateToProps)(CorporateAgencies);
