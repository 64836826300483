import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { getEmployeesCount } from '../../../../actions/corporateHome.actions';
import { UserType } from '../../../../types/types-enums-interfaces';
import { selectWorkersCount } from '../../../../selectors';
import LoadingSpinner from '../../../../components/LoadingSpinner';

type Props = {
  dispatch?: any;
  currentZoneSlug: string;
  // store
  employeesCount: Map<UserType, number>;
};
type State = {
  fetchingData: boolean;
};

class WorkersCount extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      fetchingData: false,
    };
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount(): void {
    if (this.props.employeesCount) this.fetchData(false);
    this.fetchData();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.currentZoneSlug !== prevProps.currentZoneSlug) {
      this.fetchData();
    }
  }

  fetchData(showSpinner = true) {
    if (showSpinner) this.setState({ fetchingData: true });

    this.props.dispatch(getEmployeesCount(this.props.currentZoneSlug))
      .then(() => this.setState({ fetchingData: false }));
  }

  render() {
    const { employeesCount } = this.props;
    const { fetchingData } = this.state;

    const sorterCount = employeesCount
      && employeesCount.get(UserType.Sorter, 0) + employeesCount.get(UserType.PalletMaker, 0);
    const forkliftCount = employeesCount && employeesCount.get(UserType.Forklift, 0);

    return (
      <>
        <div
          className={`card mt-2 ${(fetchingData || !employeesCount) && 'row no-gutters align-items-center'}`}
          style={{ minHeight: 95 }}
        >
          {!employeesCount || fetchingData
            ? <LoadingSpinner />
            : (
              <>
                {/* selectors */}
                <div className="row justify-content-between align-items-center px-lg-3">
                  <div className="col-auto">
                    <p className="text-smallest">Seleccionadores/Estibadores</p>
                  </div>

                  <div className="col-auto col-sm-12 col-md-auto">
                    <h3>
                      {sorterCount}
                    </h3>
                  </div>
                </div>

                {/* FOs */}
                <div className="row justify-content-between align-items-center px-lg-3">
                  <div className="col-auto">
                    <p className="text-smallest">Montacarguistas</p>
                  </div>

                  <div className="col-auto col-sm-12 col-md-auto">
                    <h3>{forkliftCount}</h3>
                  </div>
                </div>
              </>
            )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  employeesCount: selectWorkersCount(state),
});

export default connect(mapStateToProps)(WorkersCount);
