import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getProductivityChart } from '../../../../actions/corporateHome.actions';
import { selectProductivityChartData, selectTimeFilterForLocation } from '../../../../selectors';
import { ProductivityChartMap } from '../../../../types/types-enums-interfaces';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import Separator from '../../../../components/Separator';
import { formatTimeLabel, TIME_ZONE_OFFSET } from '../../../../utils/dateHelpers';
import UserLineChart from '../../../DistCenterWrapper/UserDetail/UserLineChart';

class ProductivityChart extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      fetchingData: false,
    };
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount(): void {
    if (this.props.chartData) return this.fetchData(false);
    this.fetchData();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.currentZoneSlug !== prevProps.currentZoneSlug) this.fetchData();

    if (this.props.timeFilters !== prevProps.timeFilters) this.fetchData();
  }

  fetchData(showSpinner = true) {
    if (showSpinner) this.setState({ fetchingData: true });

    if (this.props.timeFilters) {
      return this.props.dispatch(getProductivityChart(
        this.props.currentZoneSlug,
        this.props.timeFilters.get('startDate'),
        this.props.timeFilters.get('endDate'),
      )).then(() => this.setState({ fetchingData: false }));
    }
    return this.props.dispatch(getProductivityChart(this.props.currentZoneSlug))
      .then(() => this.setState({ fetchingData: false }));
  }

  render() {
    const { chartData } = this.props;
    const { fetchingData } = this.state;

    let labels = [];
    if (chartData) {
      labels = formatTimeLabel(
        chartData.get('slots'),
        chartData.get('slotsType'),
        TIME_ZONE_OFFSET,
      );
    }

    return (
      <>
        <div
          className={`card h-100 ${(fetchingData || !chartData) && 'row no-gutters align-items-center'}`}
          style={{ minHeight: 375 }}
        >
          {!chartData || fetchingData
            ? <LoadingSpinner />
            : (
              <>
                {/* row */}
                <div className="row justify-content-between align-items-center px-3">
                  {/* unit */}
                  <div className="col-auto">
                    <p>Cantidad de {chartData.get('unit') === 'boxes' ? 'cajas' : 'tarimas'}</p>
                  </div>

                  {/* presentation */}
                  <div className="col-auto" style={{ opacity: 0 }}>
                    <p>Cupo: <span className="font-bold">Media</span></p>
                  </div>
                </div>

                <Separator style={{ marginTop: 6, marginBottom: 12 }} />

                <UserLineChart
                  data={chartData.get('slotsMapped')}
                  labels={labels}
                  isAreaChart={false}
                  isCheckBox={false}
                />
              </>
            )}
        </div>
      </>
    );
  }
}

type Props = {
  dispatch: any;
  currentZoneSlug: string;
  // store
  chartData: ProductivityChartMap;
  timeFilters: any;
};
type State = {
  fetchingData: boolean;
};

const mapStateToProps = (state: any) => ({
  chartData: selectProductivityChartData(state),
  timeFilters: selectTimeFilterForLocation(state, 'corporate'),
});

export default connect(mapStateToProps)(ProductivityChart);
