import { getCurrentDate } from '../utils/dateHelpers';
import request from '../utils/request';
import { log } from '../utils/logger';

export const SET_ZONES_TOTALS = 'corporate-agencies/set-zones-totals';

export function setZonesTotals(zonesTotals: any) {
  return { type: SET_ZONES_TOTALS, zonesTotals };
}

// helpers
export function generateQueryDates(startTime?: Date, endTime?: Date) {
  const today = getCurrentDate();
  return {
    startTime: startTime || today.startDate,
    endTime: endTime || today.endDate,
    timezoneOffsetStart: (startTime || today.startDate).getTimezoneOffset(),
    timezoneOffsetEnd: (endTime || today.endDate).getTimezoneOffset(),
  };
}

// APIs
export function getZonesTotals(startTime?: Date, endTime?: Date): any {
  return async (dispatch: any) => {
    try {
      const url = '/api/v1/corporate/zone-wise-boxes';

      const response = await request.get(url, generateQueryDates(startTime, endTime));

      dispatch(setZonesTotals(response));
    } catch (e) {
      log('e getZonesTotals()', e.message || e);
    }
  };
}
