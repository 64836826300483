import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import HomeIcon from '../assets/icons/home.svg';
import HomeOutlineIcon from '../assets/icons/home-outline.svg';
import SortingIcon from '../assets/icons/selection.svg';
import SortingOutlineIcon from '../assets/icons/selection-outline.svg';
import AgenciesIcon from '../assets/icons/icon-agencias.svg';
import AgenciesOutlineIcon from '../assets/icons/icon-agencias-outline.svg';
import AnalyticsOutlineIcon from '../assets/icons/icon-analytics-outline.svg';
import AnalyticsIcon from '../assets/icons/icon-analytics.svg';

import ActiveDot from './ActiveDot';

export default class FactoryNavigation extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeRouteId: 0,
    };
  }

  render() {
    const { distCenterSlug, location } = this.props;
    const { activeRouteId } = this.state;
    let routes: any[] = [];

    // corporate vs distribution center
    if (distCenterSlug) {
      routes = [
        {
          url: `/distribution-centers/${distCenterSlug}`,
          label: 'INICIO',
          activeImage: HomeIcon,
          inactiveImage: HomeOutlineIcon,
          routeId: 1,
        }, {
          url: `/distribution-centers/${distCenterSlug}/sorting`,
          label: 'SELECCIÓN',
          activeImage: SortingIcon,
          inactiveImage: SortingOutlineIcon,
          routeId: 2,
        },
      ];
    } else {
      routes = [
        {
          url: '/corporate',
          label: 'INICIO',
          activeImage: HomeIcon,
          inactiveImage: HomeOutlineIcon,
          routeId: 3,
        },
        // {
        //   url: '/corporate/selection',
        //   label: 'SELECCIÓN',
        //   activeImage: SortingIcon,
        //   inactiveImage: SortingOutlineIcon,
        //   routeId: 4,
        // },
        {
          url: '/corporate/analytics',
          label: 'ANALYTICS',
          activeImage: AnalyticsIcon,
          inactiveImage: AnalyticsOutlineIcon,
          routeId: 5,
        },
        {
          url: '/corporate/agencies',
          label: 'AGENCIAS',
          activeImage: AgenciesIcon,
          inactiveImage: AgenciesOutlineIcon,
          routeId: 6,
        },
      ];
    }

    return (
      <div className="d-flex justify-content-center factory-navigation">
        <div className="row justify-content-around no-gutters navbar-wrapper">
          {routes.map(route => (
            <NavLink
              to={route.url}
              key={route.url}
              activeClassName="active-route"
              className="col-auto route-wrapper"
              isActive={(match: any) => {
                if (!match) return false;
                setTimeout(() => {
                  this.setState({ activeRouteId: route.routeId });
                }, 0);
                return true;
              }}
              exact
            >
              <div className="row">
                {/* img and label */}
                <div className="col-12">
                  <div className="row">
                    <img
                      src={this.state.activeRouteId === route.routeId
                        && location.pathname === route.url
                        ? route.activeImage : route.inactiveImage}
                      className="route-icon"
                      alt={route.label}
                    />
                    <div>
                      <p>{route.label}</p>
                      {/* active link blue dot */}
                      <div
                        className="d-flex justify-content-center"
                        style={{ opacity: (activeRouteId === route.routeId
                          && location.pathname === route.url) ? 1 : 0,
                        marginTop: 5 }}
                      >
                        <ActiveDot />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </NavLink>
          ))}
        </div>
      </div>
    );
  }
}

type Props = {
  distCenterSlug?: string;
  location: any;
};

type State = {
  activeRouteId: number;
};
