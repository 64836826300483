import React, { Component } from 'react';

import { ReactComponent as WarehouseIcon } from '../../assets/icons/warehouse.svg';
import { ReactComponent as ArvolutionLogo } from '../../assets/icons/arvolution-logo.svg';

import './UnsupportedBrowser.scss';

type Props = {};
type State = {};

class UnsupportedBrowser extends Component<Props, State> {
  render() {
    return (
      <div
        className="container-fluid unsupported-browser"
        style={{ padding: 0, height: '100vh', margin: 0 }}
      >
        {/* Header */}
        <div
          className="row justify-content-center align-items-center"
          style={{
            paddingTop: 16,
            borderBottomWidth: 1,
            borderBottomColor: '#DAE0EA',
            borderBottomStyle: 'solid',
            height: 100,
            backgroundColor: '#fff',
          }}
        >
          <ArvolutionLogo />
          <h1 style={{
            marginLeft: 16,
            fontWeight: 100,
            letterSpacing: 8,
            fontSize: 18,
            color: '#94A4B3',
          }}
          >
            ARVOLUTION
          </h1>
        </div>

        {/* page */}
        <div style={{
          backgroundColor: '#F8FBFF',
          padding: 0,
          margin: 0,
          height: '100%',
          width: '100%',
          paddingTop: 40,
        }}
        >
          {/* row 1 */}
          <div className="row justify-content-center">
            <div className="col-9 col-md-5">
              <h1
                className="text-center"
                style={{ color: '#0D3066', fontSize: 26, fontWeight: 800 }}
              >
                Lá página no <br /> soporta este navegador
              </h1>
            </div>
          </div>

          {/* row 2 */}
          <div className="row justify-content-center">
            <div className="col-9 col-md-4">
              <p
                className="text-center font-weight-light text-smaller"
                style={{ color: '#0D3066' }}
              >
                Te recomendamos utilizar&nbsp;
                <a
                  href="https://www.google.com/intl/en_uk/chrome/"
                  className="text-underline"
                >
                  Google Chrome
                </a> o&nbsp;
                <a
                  href="https://www.mozilla.org/en-US/firefox/new/"
                  className="text-underline"
                >
                  Firefox
                </a> para una mejor experiencia
              </p>
            </div>
          </div>

          {/* image */}
          <div className="row justify-content-center mt-5">
            <div className="col-auto">
              <WarehouseIcon
                style={{ maxWidth: '100%', height: 'auto', paddingLeft: 16, paddingRight: 16 }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UnsupportedBrowser;
