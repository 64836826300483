import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { ReactComponent as UserIcon } from '../../../../assets/icons/user.svg';
import { ReactComponent as PalletIcon } from '../../../../assets/icons/pallet.svg';
import { ReactComponent as UserWarningIcon } from '../../../../assets/icons/user-warning.svg';

import Separator from '../../../../components/Separator';
import { getMostLeastProductiveWorkers } from '../../../../actions/corporateHome.actions';
import {
  selectCorporateHomeLeastProductiveWorker,
  selectCorporateHomeMostProductiveWorker,
  selectCorporateHomeUnit,
  selectTimeFilterForLocation,
} from '../../../../selectors';
import {
  UnitType,
  WorkerInMostLeastProductiveWorkersMap,
} from '../../../../types/types-enums-interfaces';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { unitMapToSpanish } from '../../../../constants';

type Props = {
  dispatch: any;
  currentZoneSlug: string;
  // store
  mostProductive: WorkerInMostLeastProductiveWorkersMap;
  leastProductive: WorkerInMostLeastProductiveWorkersMap;
  unit: UnitType;
  timeFilters: any;
};
type State = {
  fetchingData: boolean;
};

class MostLeastProductiveWorkers extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      fetchingData: false,
    };
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount(): void {
    if (this.props.mostProductive && this.props.leastProductive) {
      return this.fetchData(
        false,
      );
    }
    this.fetchData();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.currentZoneSlug !== prevProps.currentZoneSlug) this.fetchData();

    if (this.props.timeFilters !== prevProps.timeFilters) this.fetchData();
  }

  fetchData(showSpinner = true) {
    if (showSpinner) this.setState({ fetchingData: true });

    if (this.props.timeFilters) {
      return this.props.dispatch(getMostLeastProductiveWorkers(
        this.props.currentZoneSlug,
        this.props.timeFilters.get('startDate'),
        this.props.timeFilters.get('endDate'),
      )).then(() => this.setState({ fetchingData: false }));
    }

    this.props.dispatch(getMostLeastProductiveWorkers(this.props.currentZoneSlug))
      .then(() => this.setState({ fetchingData: false }));
  }

  render() {
    const { mostProductive, leastProductive, unit } = this.props;
    const { fetchingData } = this.state;

    return (
      <>
        <div
          className={`card ${(fetchingData || !(mostProductive && leastProductive)) && 'row no-gutters align-items-center'}`}
          style={{ minHeight: 295 }}
        >
          {fetchingData || !(mostProductive && leastProductive)
            ? <LoadingSpinner />
            : (
              <>
                {/* most productive */}
                <div className="most-least-productive">
                  {/* title */}
                  <p>El más productivo</p>
                  {/* row 2 */}
                  <div style={{ marginTop: 16 }}>
                    <Link
                      to={`/distribution-centers/${mostProductive.getIn(['distCenter', 'slug'])}/users/${mostProductive.get(
                        'userSlug',
                      )}`}
                    >
                      <UserIcon style={{ width: 20 }} />
                      <span style={{ marginLeft: 12 }}>
                        <span className="font-bolder text-larger">
                          {mostProductive.get('name')}
                        </span>
                      </span>
                    </Link>
                  </div>
                  {/* row 3 */}
                  <div>
                    <PalletIcon style={{ width: 20 }} />
                    <h3 style={{ display: 'inline', marginLeft: 12 }}>
                      {Number(mostProductive.get('quantity', 0)).toLocaleString()}
                    </h3>
                    <span style={{ marginLeft: 5 }}>
                      {unitMapToSpanish[unit]}
                    </span>
                  </div>
                </div>

                <Separator style={{ marginTop: 8 }} />

                {/* least productive */}
                <div className="most-least-productive" style={{ marginTop: 8 }}>
                  {/* title */}
                  <p>El menos productivo</p>
                  {/* row 2 */}
                  <div style={{ marginTop: 16 }}>
                    <Link
                      to={`/distribution-centers/${leastProductive.getIn(['distCenter', 'slug'])}/users/${leastProductive.get(
                        'userSlug',
                      )}`}
                    >
                      <UserWarningIcon style={{ width: 20 }} />
                      <span style={{ marginLeft: 12 }}>
                        <span className="font-bolder text-larger">
                          {leastProductive.get('name')}
                        </span>
                      </span>
                    </Link>
                  </div>
                  {/* row 3 */}
                  <div>
                    <PalletIcon style={{ width: 20 }} />
                    <h3 style={{ display: 'inline', marginLeft: 12 }}>
                      {Number(leastProductive.get('quantity', 0)).toLocaleString()}
                    </h3>
                    <span style={{ marginLeft: 5 }}>
                      {unitMapToSpanish[unit]}
                    </span>
                  </div>
                </div>
              </>
            )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  mostProductive: selectCorporateHomeMostProductiveWorker(state),
  leastProductive: selectCorporateHomeLeastProductiveWorker(state),
  unit: selectCorporateHomeUnit(state),
  timeFilters: selectTimeFilterForLocation(state, 'corporate'),
});

export default connect(mapStateToProps)(MostLeastProductiveWorkers);
