import React, { CSSProperties } from 'react';

import './BasicButton.scss';

type Props = {
  text: string;
  onClick(e?: any): void;
  className?: string;
  background?: string;
  disabled?: boolean;
  style?: CSSProperties;

};

// NOTE: Default background color is #2574FB

const BasicButton = ({ text, onClick, className = '', background, disabled, style }: Props) => (
  <button
    type="button"
    className={`basic-button ${className}`}
    onClick={onClick}
    tabIndex={0}
    style={{
      ...style,
      backgroundColor: background,
      opacity: disabled ? 0.3 : 1,
      outline: 'none',
    }}
    disabled={disabled}
  >
    {text}
  </button>
);

export default BasicButton;
