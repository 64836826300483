import React, { PureComponent } from 'react';

export default class DeviceConfiguration extends PureComponent {
  componentDidMount() {
    // const devices = request.get('/api/v1/?')
  }

  render() {
    return (
      <div className="container main-content-margin">
        <div className="card">
          <h1>Devices</h1>
        </div>
      </div>
    );
  }
}
