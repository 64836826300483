import React, { Component } from 'react';
import Chart from 'chart.js';
import merge from 'lodash.merge';
import isEqual from 'lodash.isequal';

class LineChart extends Component<Props, any> {
  static gradientFactory(ctx: any, color: string) {
    const gradient = ctx.createLinearGradient(0, 0, 0, 450);
    gradient.addColorStop(0, color);
    gradient.addColorStop(1, 'rgba(255,255,255,0.91)');
    return gradient;
  }
  lineChart: any;
  canvasId = Math.random().toString(5);

  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate(prevProps: any) {
    if (!isEqual(prevProps.data, this.props.data)) {
      // @ts-ignore

      const ctx = document.getElementById(this.canvasId).getContext('2d');
      let datasets = this.props.data;

      // area chart options
      if (this.props.areaChart) {
        // adding gradient
        datasets = this.props.data.map((k: any) => {
          k.backgroundColor = LineChart.gradientFactory(ctx, k.backgroundColor);
          return k;
        });

        this.lineChart.options.scales.yAxes[0].stacked = true;
      }

      this.lineChart.data.datasets = datasets;
      this.lineChart.options = (() => {
        // const { myOnClick =  } = this.props.options;
        const myOnClick = this.props && this.props.options && this.props.options.myOnClick;
        if (myOnClick) {
          this.props.options.onClick = (event: any, ele: any) => myOnClick(
            event, ele, this.lineChart.config,
          );
          delete this.props.options.myOnClick;
        }
        return merge(this.getOptions(), this.props.options);
      })();

      this.lineChart.update();
    }

    if (!isEqual(prevProps.labels, this.props.labels)) {
      this.lineChart.data.labels = this.props.labels;
      this.lineChart.update();
    }
  }

  getOptions = () => ({
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        formatter: () => '',
      },
    },
    aspectRatio: 1,
    legend: {
      display: false,
    },
    scales: {
      xAxes: [{
        maxBarThickness: 35,
        gridLines: {
          color: 'rgba(0, 0, 0, 0)',
          drawBorder: false,
        },
        ticks: {
          fontStyle: 'bold',
          fontColor: 'black',
        },
      }],
      yAxes: [{
        gridLines: {
          borderDash: [5, 5],
          color: '#D9D9D9',
          drawBorder: false,
        },
        ticks: {
          beginAtZero: true,
          fontColor: 'black',
          suggestedMax: this.props.yAxisSuggestedMax || 1,
        },
      }],
    },
    layout: {
      padding: {
        top: 10,
        bottom: 30,
        left: 10,
        right: 10,
      },
    },
  });

  renderChart() {
    // @ts-ignore
    const ctx = document.getElementById(this.canvasId).getContext('2d');

    // adding gradient
    const datasets = this.props.areaChart ? this.props.data.map((k: any) => {
      k.backgroundColor = LineChart.gradientFactory(ctx, k.backgroundColor);
      return k;
    })
      : this.props.data;

    const chartOptions: any = {
      type: 'line',
      data: {
        datasets,
      },
      options: (() => {
        const myOnClick = this.props && this.props.options && this.props.options.myOnClick;
        if (myOnClick) {
          this.props.options.onClick = (event: any, ele: any) => myOnClick(
            event, ele, this.lineChart.config,
          );
          delete this.props.options.myOnClick;
        }
        return merge(this.getOptions(), this.props.options);
      })(),
    };
    // area chart options
    if (this.props.areaChart) {
      chartOptions.options.scales.yAxes[0].stacked = true;
    }

    // labels?
    if (this.props.labels) chartOptions.data.labels = this.props.labels;

    // instantiate
    this.lineChart = new Chart(ctx, chartOptions);
  }

  render() {
    const { height } = this.props;

    return (
      <div style={{ position: 'relative', height: height || 300 }}>
        <canvas style={{ width: '100%', height: '100%' }} id={this.canvasId} />
      </div>
    );
  }
}

type Props = {
  options?: any;
  dimension?: any;
  data?: any;
  labels?: any;
  height?: any;
  areaChart?: boolean;
  yAxisSuggestedMax?: number;
};

export default LineChart;
