export const SET_TIME_FILTER_FOR_PAGE = 'data=filters/set-time-for-page';
export const SET_PRODUCT_UNIT_FOR_PAGE = 'data=filters/set-product-unit-for-page';
export const SET_CURRENT_ZONE = 'data=filters/set-current-zone';
export const SET_SELECTED_SHIFT = 'data-filters/set-selected-shift';

export function setTimeFilterForPage(
  timeFilter: { startDate: Date; endDate: Date },
  stateLocation: string,
  dropdownValue: string,
) {
  return { type: SET_TIME_FILTER_FOR_PAGE, timeFilter, stateLocation, dropdownValue };
}

export function setProductUnit(stateLocation: string, productUnit: string) {
  return { type: SET_PRODUCT_UNIT_FOR_PAGE, stateLocation, productUnit };
}

export function setZone(zoneSlug: string) {
  return { type: SET_CURRENT_ZONE, zoneSlug };
}

export function setSelectedShift(
  shift: any,
) {
  return { type: SET_SELECTED_SHIFT, shift };
}
