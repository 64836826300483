// This file is used to generate data which will be used in testing and composition

// constants
export const mockPresentations = [
  { name: 'Media', slug: 'media' },
  { name: 'MEGA', slug: 'mega' },
  { name: 'Cuartito', slug: 'cuartito' },
  { name: 'Premium', slug: 'premium' },
  { name: 'Familiar', slug: 'familiar' },
];

// functions
export function generateMockPresentations(numberOfPresentations: number) {
  return mockPresentations.slice(0, numberOfPresentations);
}

const pickRandom = (...args: any[]) => {
  const argumentsLength = args.length;
  return args[Math.floor(Math.random() * argumentsLength)];
};

export const generateRandomLowerCaseChar = () => String.fromCharCode(Math.round(97
  + Math.random() * (122 - 97)));
export const generateRandomUpperCaseChar = () => String.fromCharCode(Math.round(65
  + Math.random() * (90 - 65)));

const generateRandomNumber = (lowerLimit = 0,
  upperLimitInclusive?: number) => lowerLimit + Math.round(
  Math.random() * (upperLimitInclusive || 1000),
);

export const generateRandomAlphaNumericText = (length: number) => {
  let text = '';
  for (let i = 0; i < length; i++) {
    const randomChar = pickRandom(generateRandomLowerCaseChar,
      generateRandomUpperCaseChar,
      generateRandomNumber)();
    text += randomChar;
  }

  return text;
};

export const generateZonesCards = (length: number,
  subZones = false, distributionCenters = false) => {
  const zones = {} as any;

  for (let i = 0; i < length; i++) {
    const zoneName = (distributionCenters ? 'DIST_CENTER-' : (subZones ? 'SUBZONE-' : 'ZONE-')) + generateRandomAlphaNumericText(
      6,
    );
    const zoneSlug = zoneName.toLowerCase();
    zones[zoneSlug] = {
      slug: zoneSlug,
      name: zoneName,
      totalBoxes: generateRandomNumber(10000, 100000),
    };

    // adding subzones
    if (!subZones) zones[zoneSlug].subZones = generateZonesCards(generateRandomNumber(3, 12), true);
    // adding dist. centers to subzones
    else if (!distributionCenters) {
      zones[zoneSlug]['distribution-centers'] = generateZonesCards(
        generateRandomNumber(12, 20),
        true,
        true,
      );
    }
  }

  return zones;
};
