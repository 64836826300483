import React, { Component } from 'react';

type Props = {
  width?: number;
  cardStyle?: any;
  cardClassNames?: string[];
  position?: 'top' | 'bottom';
};
type State = {};

class Toast extends Component<Props, State> {
  setParentStyle(position: Props['position']) {
    const parentStyle = {
      position: 'fixed',
      right: '50%',
      marginRight: -(this.props.width || 280) / 2,
    } as any;
    const margin = 40;

    switch (position) {
      case 'bottom':
        parentStyle.bottom = margin;
        break;
      case 'top':
        parentStyle.top = margin;
        break;
      default:
        parentStyle.bottom = margin;
    }

    return parentStyle;
  }

  render() {
    const { width, cardStyle, cardClassNames, children, position = 'bottom' } = this.props;

    const parentStyle = this.setParentStyle(position);

    return (
      <div
        style={{ ...parentStyle }}
      >
        <div
          className={`card px-1 ${cardClassNames && cardClassNames.join(' ')}`}
          style={{
            width: width || 280,
            borderRadius: width || 280,
            margin: 'auto',
            ...cardStyle,
          }}
        >
          {children}
        </div>
      </div>
    );
  }
}

export default Toast;
