import React, { CSSProperties } from 'react';

import Input from '../Input';
import './InputWithIcon.scss';

type Props = {
  onChange(e: any): void;
  placeholder: string;
  icon: string;
  errorIcon?: string;
  value?: string;
  name?: string;
  error?: string;
  type?: 'text' | 'password' | 'number';
  style?: CSSProperties;
  className?: string;
  errorHeight?: boolean;
};

const InputWithIcon = ({
  onChange,
  placeholder,
  icon,
  errorIcon,
  value,
  name,
  error,
  type,
  style,
  className,
  errorHeight = false,
}: Props) => (
  <div className="input-with-icon">
    {errorIcon
      ? <img src={error ? errorIcon : icon} alt="icon" className="input-icon" />
      : <img src={icon} alt="icon" className="input-icon" /> }
    <Input
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      name={name}
      error={error}
      className={`input ${className} ${error ? 'error' : ''}`}
      style={{ borderColor: error ? '#D47420' : '#176EE0', boxShadow: 'none', ...style }}
      value={value}
      errorHeight={errorHeight}
    />
  </div>
);

export default InputWithIcon;
