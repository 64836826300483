import React, { Component } from 'react';
import ReactTable from 'react-table';
import { Map } from 'immutable';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { ReactComponent as UserIcon } from '../../../../assets/icons/user.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete-icon.svg';

import LoadingSpinner from '../../../../components/LoadingSpinner';
import SelectDropdown from '../../../../components/Dropdown/SelectDropdown';
import DefaultDropdown from '../../../../components/Dropdown/DefaultDropdown';
import { UserType } from '../../../../types/types-enums-interfaces';
import { disableUser } from '../../../../actions/configuration.actions';

import 'react-dropdown/style.css';
import './SortersConfiguration.scss';
import ActiveDot from '../../../../components/ActiveDot';

class SortersConfiguration extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.disableUser = this.disableUser.bind(this);
    this.renderConfigurationCell = this.renderConfigurationCell.bind(this);
  }


  handleChangeActivity(employeeNumber: string, newActivityId: string, oldActivityId: string) {
    if (newActivityId === oldActivityId) return;

    this.props.handleChangeActivity(employeeNumber, newActivityId, oldActivityId);
  }

  disableUser(userSlug: string) {
    this.props.disableUser(userSlug);
  }

  createTableColumns(sortersConfiguration: any) {
    const columns = [] as any;
    columns.push({
      Header: () => (
        <UserIcon style={{ width: 20, height: 20 }} className="text-left" />
      ),
      id: 'name',
      accessor: (dataRow: any) => dataRow.get('name'),
      Cell: (row: any) => (
        <p className="text-left font-bold">
          {row.value}
        </p>
      ),
    });

    // employee number
    /* columns.push({
      Header: '# de Empleado',
      id: 'employeeId',
      accessor: (dataRow: any) => dataRow.get('employeeNumber'),
      Cell: (row: any) => <p className="text-left">
        {row.value}
      </p>,
      style: { fontWeight: 'lighter' },
    }); */

    // select activity
    columns.push({
      Header: 'Actividad',
      id: 'role',
      accessor: (dataRow: any) => Map(
        {
          roleId: dataRow.get('roleId'),
          employeeNumber: dataRow.get('employeeNumber'),
        },
      ),
      Cell: (row: any) => (
        <SelectDropdown
          name="activity"
          value={row.value.get('roleId')}
          placeholder="Actividad"
          dropdownContent={sortersConfiguration.get('roleOptions')
            .valueSeq()
            .map((role: any) => (
              {
                value: role.get('id'),
                label: role.get('description'),
              }))}
          onChange={e => this.handleChangeActivity(
            row.value.get('employeeNumber'),
            e.target.value,
            row.value.get('roleId'),
          )}
          highlightSelected
          className="activity-dropdown"
          selectWidth={130}
          dropdownElementsWidth={180}
        >
          <p />
        </SelectDropdown>
      ),
    });

    // bench
    columns.push({
      Header: 'Ubicación',
      id: 'workbench',
      accessor: (dataRow: any) => Map({
        benchId: dataRow.get('benchId'),
        roleId: dataRow.get('roleId'),
      }),
      Cell: (row: any) => {
        const bench = sortersConfiguration.getIn(['benches', String(row.value.get('benchId'))]);
        const benchPosition = (bench && bench.get('name')) || (row.value.get('roleId') === UserType.PalletMaker
          ? 'Zona de Estibadores' : '');

        return (
          <div>
            <p>{benchPosition}</p>
          </div>
        );
      },
      style: { fontWeight: 'lighter' },
    });

    // attendance
    /* columns.push({
      Header: 'Asistencia',
      id: 'asistance',
      accessor: (dataRow: any) => dataRow.get('attendanceId'),
      Cell: (row: any) => (
        // toDo: use modal that anchors on selected cell
        // https://spectrum.chat/react-table/general/
        // dropdown-is-still-hidden-with-overflow-set-to-auto~bc683a0e-d88b-41f9-aee3-539c1bb3582e
        <select value={row.value.toString()} onChange={() => null}>
          {sortersConfiguration.get('attendanceOptions').map((attendance: any) => (
            <option
              value={attendance.get('id').toString()}
              key={attendance.get('id')}
            >
              {attendance.get('description')}
            </option>
          )).toArray()}
        </select>
      ),
      style: { textAlign: 'left', fontWeight: 'lighter' },
    }); */

    // col 4: configuration menu
    columns.push({
      id: 'configuration',
      Header: '',
      accessor: (row: any) => ({
        userSlug: row.get('slug'),
      }),
      Cell: this.renderConfigurationCell,
      width: 200,
    });

    return columns;
  }

  renderConfigurationCell(row: any) {
    return (
      // Cell content with configuration menu dropdown dots
      <DefaultDropdown
        dropdownContent={[{
          label: (
            <div className="d-flex align-items-center">
              <DeleteIcon style={{ marginRight: 10 }} />
              <p className="text-light">Dar de baja</p>
            </div>),
          value: 'delete',
          onClickFunction: () => this.disableUser(row.value.userSlug),
        }]}
        className="configuration-dropdown"
        dropdownElementsWidth={150}
        repositioningValue={30}
      >
        <div style={{ paddingLeft: 10, paddingRight: 10 }}>
          <div className="config-dots d-flex flex-column">
            <ActiveDot color="#D8D8D8" width={4} />
            <ActiveDot color="#D8D8D8" width={4} />
            <ActiveDot color="#D8D8D8" width={4} />
          </div>
        </div>
      </DefaultDropdown>
    );
  }

  render() {
    const { workersConfiguration } = this.props;
    const columns = workersConfiguration && this.createTableColumns(workersConfiguration);
    const tableData = workersConfiguration && workersConfiguration.get('users').filter((v: any) => v.get('roleId') !== UserType.Forklift);

    return (
      <div className="card sorters-configuration">
        {workersConfiguration
          ? (
            <>
              {/* table */}
              <ReactTable
                data={tableData}
                columns={columns}
                defaultPageSize={tableData.size}
                showPagination={false}
                className="-striped"
                NoDataComponent={() => null}
              />
            </>
          )
          : <LoadingSpinner height={200} />}
      </div>
    );
  }
}

type Props = {
  workersConfiguration: any;
  handleChangeActivity: Function;
  disableUser: Function;
};

type State = {};

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    disableUser,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SortersConfiguration);
