import { subHours } from 'date-fns';
import request from '../utils/request';
import getCurrentDistCenter from '../utils/getCurrentDistCenter';
import { TIME_ZONE_OFFSET } from '../utils/dateHelpers';
import { log } from '../utils/logger';

export const SET_SORTERS_TOTAL_SELECTION = 'sorting-info/set-sorters-total-info';
export const SET_SORTED_TIME_CHART = 'sorted-products/set-sorted-time-chart';
export const SET_DC_DAILY_PROGRESS = 'sorting-info/set-dc-daily-progress';
export const SET_SORTERS_RANKING = 'sorting-info/set-sorters-ranking';
export const CLEAR_STATE_FOR_DIST_CENTER = 'sorting-info/clear-state-for-dist-center';

export function setSortersTotalSelection(selection: any) {
  return { type: SET_SORTERS_TOTAL_SELECTION, selection };
}

export function setSortedProductsTimeChart(data: any) {
  return { type: SET_SORTED_TIME_CHART, data };
}

export function setDCDailyProgress(progress: any) {
  return { type: SET_DC_DAILY_PROGRESS, progress };
}

export function setSortersRanking(ranking: any): any {
  return { type: SET_SORTERS_RANKING, ranking };
}

export function clearStateForDistCenter(distCenter: string) {
  return { type: CLEAR_STATE_FOR_DIST_CENTER, distCenter };
}

export function getSortersTotalSelection(): any {
  return async (dispatch: any, getState: any) => {
    try {
      const state = getState();
      const url = `${getCurrentDistCenter()}/sorters-total-selection`;
      const dateFilters = state.getIn(['dataFilter', 'homeAndSortingDashboard', 'time']);
      let requestTimeFilter;
      if (dateFilters && dateFilters.get('startDate') && dateFilters.get('endDate')) {
        requestTimeFilter = {
          // startTime: subHours(new Date(dateFilters.get('startDate')), 24),
          // endTime: subHours(new Date(dateFilters.get('endDate')), 24),
          startTime: dateFilters.get('startDate'),
          endTime: dateFilters.get('endDate'),
          timezoneOffset: TIME_ZONE_OFFSET,
          timeShift: state.getIn(['dataFilter', 'selectedShift']),
        };
      }

      const response = await request.get(url, requestTimeFilter);
      // once the method returns, the loading spinner in SortersTotalSelection stops showing
      return dispatch(setSortersTotalSelection(response.users));
    } catch (e) {
      log('e setSortersTotalSelection()', e.message || e);
    }
  };
}

export function getSortedProductsTimeChart(): any {
  return async (dispatch: any, getState: any) => {
    try {
      const state = getState();
      const url = `${getCurrentDistCenter()}/sorted-products/time-chart`;
      const dateFilters = state.getIn(['dataFilter', 'homeAndSortingDashboard', 'time']);

      let requestTimeFilter;
      if (dateFilters && dateFilters.get('startDate') && dateFilters.get('endDate')) {
        requestTimeFilter = {
          startTime: dateFilters.get('startDate'),
          endTime: dateFilters.get('endDate'),
          timezoneOffset: TIME_ZONE_OFFSET,
          timeShift: state.getIn(['dataFilter', 'selectedShift']),
        };
      }
      const response = await request.get(url, requestTimeFilter);
      dispatch(setSortedProductsTimeChart(response.chartData));
    } catch (e) {
      log('e getSortedProductsTimeChart()', e.message || e);
    }
  };
}

export function getDCDailyGoal(startTime?: Date, endTime?: Date) {
  return async (dispatch: any, getState: any) => {
    try {
      const state = getState();
      const url = `${getCurrentDistCenter()}/sorting/daily-progress`;

      let timeFilter;
      if (startTime && endTime) {
        timeFilter = {
          startTime,
          endTime,
          timeShift: state.getIn(['dataFilter', 'selectedShift']),
        };
      }

      const response = await request.get(url, timeFilter);
      dispatch(setDCDailyProgress(response));
    } catch (e) {
      log('e getDCDailyGoal()', e.message || e);
    }
  };
}
