import React, { Component, ClassicElement } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { ReactComponent as LeftChevronIcon } from '../../assets/icons/chevron-left.svg';
import { ReactComponent as NutIcon } from '../../assets/icons/nut-icon.svg';

import {
  selectDistributionCenterInfo,
  selectUserDistributionCenters,
  selectUserName,
  selectUserType,
} from '../../selectors';
import { PAGE, UserType } from '../../types/types-enums-interfaces';
import LinkDropdown from '../Dropdown/LinkDropdown';
import ActiveDot from '../ActiveDot';

import './MainNavigationHeader.scss';

class MainNavigationHeader extends Component<Props> {
  render() {
    const { pathname, distributionCenters,
      match, userType, from, currentDistributionCenter } = this.props;
    const isCorporateUser = userType === UserType.Corporate;
    let pathnameLocation: string;
    let distCentersArray: any[] = [];

    let goBackRoute = '/';
    switch (from) {
      case PAGE.CORPORATE_HOME:
        goBackRoute = '/';
        break;
      case PAGE.CORPORATE_AGENCIES:
        goBackRoute = '/corporate/agencies';
        break;
      default:
        goBackRoute = '/';
    }

    // corporate vs distribution center
    if (pathname.includes(PAGE.DIST_CENTER_HOME)) {
      if (match.params.distCenter && distributionCenters) {
        let index;
        distCentersArray = distributionCenters.toJS();
        distCentersArray.forEach((distCenter: any, i: any) => {
          if (match.params.distCenter === distCenter.slug) {
            index = i;
            distCentersArray.splice(index, 1);
            distCentersArray.unshift(distCenter);
          }
        });
        // eslint-disable-next-line prefer-destructuring
        pathnameLocation = pathname.includes('users') ? '' : pathname.split(match.params.distCenter)[1];
      }
    }

    return (
      <div className="main-navigation-header">
        <div className="row align-items-center justify-content-around">
          {/* dist center menu */}
          <div className="col-9 col-md-8 d-flex align-items-baseline">
            {/* go back and hi */}
            <div className="row align-items-center w-100">
              <div className="col-auto">
                {/* back to corporate */}
                {isCorporateUser && pathname.includes(PAGE.DIST_CENTER_HOME)
                && (
                <NavLink to={goBackRoute}>
                  <LeftChevronIcon style={{ filter: 'brightness(0.6)', marginRight: 8, width: 20 }} />
                  <h3
                    className="text-light"
                    style={{
                      color: '#828282',
                      marginRight: 12,
                      display: 'inline-block',
                      verticalAlign: 'middle',
                    }}
                  >
                    Regresar a Global
                  </h3>
                </NavLink>
                )}
              </div>

              <div className="col-auto">
                <h3 className="d-inline">Hola {this.props.userName}, estás en </h3>
                {distCentersArray.length
                  ? (
                    <LinkDropdown
                      dropdownContent={distCentersArray.map((distCenter: any, i: any) => ({
                        url: `/distribution-centers/${distCenter.slug}${pathnameLocation}`,
                        label:
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        {distCenter.name}
                        {i === 0 && <ActiveDot width={8} />}
                      </div>,
                      }))}
                      style={{ marginLeft: 5, marginTop: 1, display: 'inline' }}
                      dropdownElementsWidth={250}
                      dropdownArrow
                    >
                      <span style={{
                        textDecoration: 'underline',
                        fontWeight: 'bold',
                        fontSize: '1.15em',
                      }}
                      >
                        {currentDistributionCenter && currentDistributionCenter.get('name')}
                      </span>
                    </LinkDropdown>
                  )
                  : (
                    <h4 className="text-underline ml-1 mb-0 d-inline">
                      {pathname.includes(PAGE.CORPORATE_HOME)
                        ? 'Corporativo'
                        : currentDistributionCenter && currentDistributionCenter.get('name')}
                    </h4>
                  )}
              </div>
            </div>
          </div>

          {/* Configuration menu */}
          <LinkDropdown
            dropdownContent={this.props.configRoutes}
            className="col-3 col-md-4"
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}
            dropdownContentPositioning={{ right: -10 }}
          >
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <NutIcon />
              <h3 className="d-md-block d-none" style={{ paddingLeft: 10 }}>Configuración</h3>
            </div>
          </LinkDropdown>
        </div>
      </div>
    );
  }
}

type Props = {
  configRoutes: Array<{ url: string; label: string | ClassicElement<any> }>;
  match: any;
  pathname: any;
  from?: PAGE;
  // store
  userName: string;
  distributionCenters: any;
  userType: UserType;
  currentDistributionCenter: any;
};

function mapStateToProps(state: any) {
  return {
    userName: selectUserName(state),
    distributionCenters: selectUserDistributionCenters(state),
    userType: selectUserType(state),
    currentDistributionCenter: selectDistributionCenterInfo(state),
  };
}

export default connect(mapStateToProps)(MainNavigationHeader);
