import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { NavLink as Link } from 'react-router-dom';

import { ReactComponent as UserIcon } from '../../../../assets/icons/user.svg';

import LoadingSpinner from '../../../../components/LoadingSpinner';
import { getSortersKPIs } from '../../../../actions/sortingDashboard.actions';
import {
  selectHomeCurrentDistCenter,
  selectSortingDashboardsortersKPIs,
} from '../../../../selectors';

// import 'react-table/react-table.css'

class SortersKPITable extends Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.getDataForTimeFilter = this.getDataForTimeFilter.bind(this);
  }

  componentDidMount() {
    if (this.props.timeFilter) return this.getDataForTimeFilter();
    this.props.dispatch(getSortersKPIs());
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.timeFilter && prevProps.timeFilter
      !== this.props.timeFilter) this.getDataForTimeFilter();
    if (prevProps.currentDistCenter !== this.props.currentDistCenter) {
      this.getDataForTimeFilter();
    }
  }

  getDataForTimeFilter() {
    this.props.dispatch(getSortersKPIs(
      this.props.timeFilter.get('startDate'), this.props.timeFilter.get('endDate'),
    ));
  }

  render() {
    const { productUnit } = this.props;
    const current = productUnit === 'boxes' ? 'currentBoxes' : 'currentPallets';
    const hourlyRate = productUnit === 'boxes' ? 'boxesPerHour' : 'palletsPerHour';

    const columns = [];
    columns.push({
      Header: () => (
        <UserIcon style={{ width: 20, height: 20 }} className="card-image" />
      ),
      id: 'name',
      accessor: (dataRow: any) => ({
        name: dataRow.get('name'),
        slug: dataRow.get('slug'),
      }),
      Cell: (row: any) => (
        <Link to={
        `/distribution-centers/${this.props.distCenterSlug}/users/${row.value.slug}`
      }
        >{row.value.name}
        </Link>
      ),
      style: { fontWeight: 'bold' },
    });

    columns.push({
      Header: 'Selección',
      id: 'currentBoxes',
      accessor: (dataRow: any) => (
        <p>{dataRow.get(current)
        && dataRow.get(current).toLocaleString()}
        </p>
      ),
      sortMethod: (a: any, b: any) => a.props.children - b.props.children,
    });

    columns.push({
      Header: 'Objetivo (cajas)',
      id: 'goal',
      accessor: (dataRow: any) => <p>{dataRow.get('goal', 0).toLocaleString()}</p>,
    });

    columns.push({
      Header: 'Selección promedio',
      id: 'boxesPerHour',
      accessor: (dataRow: any) => (
        <p> {dataRow.get(hourlyRate)
        && dataRow.get(hourlyRate).toLocaleString()}
        </p>
      ),
      sortMethod: (a: any, b: any) => a.props.children[1] - b.props.children[1],
      style: { borderLeft: '2px dashed rgba(0, 0, 0, 0.05)' },
      headerStyle: { borderLeft: '2px dashed rgba(0, 0, 0, 0.05)' },
    });

    const { sortersKPIs } = this.props;
    return (
      <div className="card sorters-presentation-table">
        {sortersKPIs
          ? (
            <ReactTable
              data={sortersKPIs.get('users')}
              columns={columns}
              pageSize={sortersKPIs.get('users').size}
              showPagination={false}
              className="-highlight"
              NoDataComponent={() => null}
            />
          )
          : <LoadingSpinner height={250} />}
      </div>
    );
  }
}

type Props = {
  dispatch: any;
  sortersKPIs: any;
  productUnit?: string;
  timeFilter: any;
  currentDistCenter: string;
  distCenterSlug: string;
};

function mapStateToProps(state: any) {
  return {
    sortersKPIs: selectSortingDashboardsortersKPIs(state),
    currentDistCenter: selectHomeCurrentDistCenter(state),
  };
}

export default connect(mapStateToProps)(SortersKPITable);
