import React, { CSSProperties } from 'react';

import './UnderlineButton.scss';

type Props = {
  text: string;
  onClick(e?: any): void;
  className?: string;
  disabled?: boolean;
  style?: CSSProperties;
};

// NOTE: Default background color is #2574FB

const UnderlineButton = ({ text, onClick, className = '', disabled, style }: Props) => (
  <button
    type="button"
    className={`underline-button ${className}`}
    onClick={onClick}
    tabIndex={0}
    style={{
      outline: 'none',
      textDecoration: disabled ? 'none' : 'underline',
      color: disabled ? 'black' : '#2574FB',
      ...style,
    }}
    disabled={disabled}
  >
    {text}
  </button>
);

export default UnderlineButton;
