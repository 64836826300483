import { fromJS } from 'immutable';
import { DELETE_API_REQUEST, SET_API_REQUEST } from '../actions/apiRequests.actions';

const INITIAL_STATE = fromJS({});

const apiRequestsReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_API_REQUEST:
      return state.updateIn([action.method, action.requestId], () => action.status);
    case DELETE_API_REQUEST:
      return state.deleteIn([action.method, action.requestId]);
    default:
      return state;
  }
};

export default apiRequestsReducer;
