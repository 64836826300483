import request from '../utils/request';
import {
  EmployeesCount,
  MostLeastProductiveAgencies,
  MostLeastProductiveWorkers,
  ProductivityChart,
  Zone,
} from '../types/types-enums-interfaces';
import { getCurrentDate, TIME_ZONE_OFFSET } from '../utils/dateHelpers';
import { log } from '../utils/logger';

export const SET_EMPLOYEES_COUNT = 'corporate-home/set-employees-count';
export const SET_MOST_LEAST_PRODUCTIVE_AGENCIES = 'corporate-home/set-most-least-productive-agencies';
export const SET_MOST_LEAST_PRODUCTIVE_WORKERS = 'corporate-home/set-most-least-productive-workers';
export const SET_PRODUCTIVITY_CHART_DATA = 'corporate-home/set-productivity-chart-data';
export const SET_ZONES = 'corporate-home/set-zones';

export function setEmployeesCount(employeesCount: EmployeesCount) {
  return { type: SET_EMPLOYEES_COUNT, employeesCount };
}

export function
setMostLeastProductiveAgencies(mostLeastProductiveAgencies: MostLeastProductiveAgencies) {
  return { type: SET_MOST_LEAST_PRODUCTIVE_AGENCIES, mostLeastProductiveAgencies };
}

export function
setMostLeastProductiveWorkers(mostLeastProductiveWorkers: MostLeastProductiveWorkers) {
  return { type: SET_MOST_LEAST_PRODUCTIVE_WORKERS, mostLeastProductiveWorkers };
}

export function setProductivityChartData(productivityChartData: ProductivityChart) {
  return { type: SET_PRODUCTIVITY_CHART_DATA, productivityChartData };
}

export function setZones(zones: Zone[]) {
  return { type: SET_ZONES, zones };
}

// helpers
function parametersPopulator(zoneSlug?: string, startTime?: Date, endTime?: Date) {
  const today = getCurrentDate();
  return {
    startTime: startTime || today.startDate,
    endTime: endTime || today.endDate,
    timezoneOffset: String(TIME_ZONE_OFFSET),
    // startTimezoneOffset: String(calculateOffset(startTime || today.startDate)),
    // endTimezoneOffset: String(calculateOffset(endTime || today.endDate)),
    zone: zoneSlug,
  };
}

// APIs
export function getEmployeesCount(zoneSlug?: string) {
  return async (dispatch: any) => {
    let response = {} as EmployeesCount;
    try {
      const url = '/api/v1/corporate/employees-count';

      response = await request.get(url, { zone: zoneSlug });
      dispatch(setEmployeesCount(response));
    } catch (e) {
      log('e getEmployeesCount()', e.message || e);
    }
  };
}

export function
getMostLeastProductiveAgencies(zoneSlug?: string, startTime?: Date, endTime?: Date) {
  return async (dispatch: any) => {
    try {
      const url = '/api/v1/corporate/most-least-productive-dist-center';

      const query = parametersPopulator(zoneSlug, startTime, endTime);

      const response = await request.get(url, query);

      dispatch(setMostLeastProductiveAgencies(response));
    } catch (e) {
      log('e getMostLeastProductiveAgencies()', e.message || e);
    }
  };
}

export function getMostLeastProductiveWorkers(zoneSlug?: string, startTime?: Date, endTime?: Date) {
  return async (dispatch: any) => {
    try {
      const url = '/api/v1/corporate/most-least-productive-user';

      const query = parametersPopulator(zoneSlug, startTime, endTime);

      const response = await request.get(url, query);

      dispatch(setMostLeastProductiveWorkers(response));
    } catch (e) {
      log('e getMostLeastProductiveWorkers()', e.message || e);
    }
  };
}

export function getProductivityChart(zoneSlug?: string, startTime?: Date, endTime?: Date) {
  return async (dispatch: any) => {
    try {
      const url = '/api/v1/corporate/boxes-chart-data';

      const query = parametersPopulator(zoneSlug, startTime, endTime);

      const response = await request.get(url, query);

      dispatch(setProductivityChartData(response));
    } catch (e) {
      log('e getProductivityChart()', e.message || e);
    }
  };
}

export function getZones() {
  return async (dispatch: any) => {
    try {
      const url = '/api/v1/zones';

      /*
      * API ( for only zones ) : http://localhost:8000/api/v1/zones
      * API ( for zones with subzones ) : http://localhost:8000/api/v1/zones/?subzones=true
      * API ( for zones with subzones and their dist centers ) : http://localhost:8000/api/v1/zones/?subzones=true&distCenters=true
      * */

      const response = await request.get(url);

      dispatch(setZones(response));
    } catch (e) {
      log('e getZones()', e.message || e);
    }
  };
}
