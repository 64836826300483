import React, { PureComponent, CSSProperties } from 'react';

import './LoadingSpinner.scss';

export default class LoadingSpinner extends PureComponent<Props> {
  render() {
    const { height, positionTop, text = 'Cargando', style = {}, className = '' } = this.props;
    // TODO: Find out how to vertical align spinner without the padding calculation
    return (
      <div
        className={`loading-spinner ${className}`}
        style={{
          height: height || 0,
          paddingTop: (height ? ((height / 2) - 12) : 0),
          top: (positionTop || 0),
          position: (positionTop ? 'relative' : 'static'),
          margin: 'auto',
          ...style,
        }}
      >
        <div className="spinner" />
        <p>{text}</p>
      </div>
    );
  }
}

type Props = {
  height?: number;
  positionTop?: number;
  text?: string;
  style?: CSSProperties;
  className?: string;
};
