import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import UserLineChart from '../UserLineChart';
import './UserBody.scss';
import { selectTimeFilterForLocation, selectUserBoxesByPresentation } from '../../../../selectors';
import { formatTimeLabel } from '../../../../utils/dateHelpers';

class UserBody extends Component<Props, any> {
  render() {
    const { userBoxesByPresentation, currentFilterTime } = this.props;

    let labels = [];
    if (userBoxesByPresentation) {
      labels = formatTimeLabel(
        userBoxesByPresentation.get('slots'),
        userBoxesByPresentation.get('slotsType'),
        userBoxesByPresentation.get('timeOffsetForDistCenter'),
      );
    }

    return (
      <div className="information-container container mt-2">
        {/* ensure data is not for previous user */}
        {userBoxesByPresentation
        && userBoxesByPresentation.get('slotsMapped').size
        && labels.length
          ? (
            <div className="line-chart-container">
              {currentFilterTime && userBoxesByPresentation
            && (
            <UserLineChart
              data={userBoxesByPresentation.get('slotsMapped')}
              labels={labels}
              period={currentFilterTime}
              isAreaChart
              isCheckBox
            />
            )}
            </div>
          )
          : <LoadingSpinner height={50} />}
      </div>
    );
  }
}

type Props = {
  sorterSlug: string;
  // store
  userBoxesByPresentation: any;
  currentFilterTime: any;
};

function mapStateToProps(state: any) {
  return {
    userBoxesByPresentation: selectUserBoxesByPresentation(state),
    currentFilterTime: selectTimeFilterForLocation(state, 'userDetails'),
  };
}

export default connect(mapStateToProps)(UserBody);
