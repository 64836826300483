import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { distanceInWords, format } from 'date-fns';
import * as esLocale from 'date-fns/locale/es';
import moment from 'moment';

import UserIcon from '../../../../assets/icons/user-icon.svg';
import EditIcon from '../../../../assets/icons/icon-edit.svg';

// import { ReactComponent as UserIcon } from '../../../../assets/icons/user-icon.svg';
// import { ReactComponent as EditIcon } from '../../../../assets/icons/icon-edit.svg';
// EDIT FEATURE IS NOT IMPLEMENTED YET

import LoadingSpinner from '../../../../components/LoadingSpinner';
import {
  getUserBoxesByPresentation,
  getUserHeaderInfo,
  setUserHeaderInfo,
  getUserWeeklyCompensation,
} from '../../../../actions/user.actions';
import Calendar from '../../../../components/Calendar';
import { setTimeFilterForPage } from '../../../../actions/dataFilter.actions';
import {
  selectDistributionCenterInfo,
  selectTimeFilterForLocation,
  selectUserHeaderInfo,
  selectUserTotalCompensation, selectUserWeeklyPayment,
} from '../../../../selectors';
import {
  DistributionCenterInfoMap,
  FILTER_OPTIONS,
} from '../../../../types/types-enums-interfaces';

import './UserHeader.scss';

class UserHeader extends Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
    };
    this.onTimeFilterSelect = this.onTimeFilterSelect.bind(this);
  }

  componentDidMount() {
    const { currentFilterTime } = this.props;
    if (currentFilterTime) {
      this.props.getUserBoxesByPresentation(this.props.sorterSlug, {
        startTime: currentFilterTime.get('startDate'),
        endTime: currentFilterTime.get('endDate'),
      });

      this.props.getUserWeeklyCompensation(this.props.sorterSlug,
        {
          startTime: moment().startOf('week').toDate(),
          endTime: moment().startOf('week').add(1, 'week').toDate(),
        });


      return this.props.getUserHeaderInfo(this.props.sorterSlug,
        {
          startTime: currentFilterTime.get('startDate'),
          endTime: currentFilterTime.get('endDate'),
        });
    }

    this.props.getUserHeaderInfo(this.props.sorterSlug);
    this.props.getUserBoxesByPresentation(this.props.sorterSlug);
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { currentFilterTime } = this.props;
    if (currentFilterTime && prevProps.currentFilterTime !== currentFilterTime) {
      this.props.getUserHeaderInfo(this.props.sorterSlug, {
        startTime: currentFilterTime.get('startDate'),
        endTime: currentFilterTime.get('endDate'),
      });

      this.props.getUserBoxesByPresentation(this.props.sorterSlug,
        {
          startTime: currentFilterTime.get('startDate'),
          endTime: currentFilterTime.get('endDate'),
        });
      this.props.getUserWeeklyCompensation(this.props.sorterSlug,
        {
          startTime: moment().startOf('week').toDate(),
          endTime: moment().startOf('week').add(1, 'week').toDate(),
        });
    }
  }

  componentWillUnmount(): void {
    this.props.dispatch(setUserHeaderInfo(null));
  }

  onTimeFilterSelect(startDate: Date, endDate: Date, dropdownValue: string) {
    if (!startDate || !endDate) return;
    // to avoid repeated requests!
    if (startDate.getTime() === this.state.startDate.getTime()
    && endDate.getTime() === this.state.endDate.getTime()) return;

    this.props.setTimeFilterForPage(
      { startDate, endDate }, 'userDetails', dropdownValue,
    );

    // update state
    this.setState({ startDate, endDate });
  }

  render() {
    const { userHeaderInfo, currentFilterTime, distributionCenter,
      userWeeklyPayment: userWeeklyCompensation } = this.props;
    let sortingGoal = userHeaderInfo && userHeaderInfo.get('goal');
    if (sortingGoal) {
      sortingGoal = sortingGoal.getIn(['hourlySelectionKPI']).reduce((a: number, b: number) => a + b, 0);
    }
    let percentage = userHeaderInfo && ((Math.round(userHeaderInfo.get('totalBoxes')
      / sortingGoal) * 100));
    if (percentage === Infinity) percentage = 0;
    const today = format(new Date(), '[Hoy] DD [de] MMMM [de] YYYY', { locale: esLocale });
    const periodInWords = currentFilterTime ? distanceInWords(currentFilterTime.get('startDate'),
      currentFilterTime.get('endDate'), { locale: esLocale }) : '';

    return (
      <div>
        {
          userHeaderInfo
            ? (
              <>
                <div className="user-header row mt-2" style={{ justifyContent: 'space-between' }}>
                  {/* avatar and user data on LEFT */}
                  <div className="col-12 col-sm-6 header-container">
                    <div className="row">
                      {/* avatar */}
                      <div className="col-auto">
                        <div className="square-icon-container align-items-center">
                          <img src={userHeaderInfo.get('picture') || UserIcon} className="square-icon" alt="user-icon" />
                        </div>
                      </div>

                      {/* user data */}
                      <div className="col-auto" style={{ msFlex: 'auto' }}>
                        {/* row 1 */}
                        <div className="row">
                          {/* name */}
                          <div className="col-sm-6">
                            <p className="font-bold light-weight">{userHeaderInfo.get('name')}</p>
                          </div>
                          {/* edit */}
                          <div className="col-sm-6">
                            <button className="editButton" type="button" onClick={() => this.props.showEditPage(true)}>
                              <img src={EditIcon} alt="edit icon" /> Editar
                            </button>
                          </div>
                        </div>

                        {/* row 2 */}
                        <div className="row mt-1">
                          <div className="col-sm-6">
                            <p className="light-weight">Selección promedio por hora:</p>
                          </div>

                          <div className="col-sm-6">
                            <p className="font-bold">
                              {`${userHeaderInfo.get('boxesPerHour')} cajas`}
                            </p>
                          </div>
                        </div>

                        {/* row 3 */}
                        <div className="row">
                          <div className="col-sm-6">
                            <p className="light-weight">Cajas por minuto</p>
                          </div>

                          <div className="col-sm-6">
                            <p className="font-bold">
                              {`${Math.floor(userHeaderInfo.get('boxesPerHour') / 60)} cajas`}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*  date, compensation and calendar on RIGHT */}
                  <div
                    className="col-12 col-sm-6 row"
                    style={{ flexDirection: 'column', justifyContent: 'space-between' }}
                  >
                    <div className="row">
                      <div className="col">
                        <p className="text-right light-weight">{today}</p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <p className="text-right light-weight">
                          Compensación Variable:
                          <span className="text-larger text-primary ml-2 font-bold">
                            {/* +{distributionCenter
                              && distributionCenter.get('currencySymbol')}{totalCompensation
                              && totalCompensation.toLocaleString()} */}
                            {distributionCenter && distributionCenter.get('currencySymbol')}&nbsp;
                            {userWeeklyCompensation
                              ? Array.from(userWeeklyCompensation.get('paymentsForTime')).reduce(
                                (a: any, v: any) => (a + parseFloat(v)), 0,
                              ) : 0}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="row" style={{ justifyContent: 'flex-end' }}>
                          <Calendar
                            onClose={(startDate: Date,
                              endDate: Date, dropdownValue: string) => (this.onTimeFilterSelect(
                              startDate,
                              endDate,
                              dropdownValue,
                            ))}
                            initialState={FILTER_OPTIONS.lastDay}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* below separator */}
                {/* Productivity & Compensation toggle - Start */}
                {/* <div className="d-flex flex-wrap" style={{ marginTop: 20 }}>
                  <span
                    role="button"
                    onClick={() => {
                      if (!this.props.productivityView) {
                        this.props.setProductivityView({ productivityView: true });
                      }
                    }}
                    style={{
                      cursor: (this.props.productivityView ? 'unset' : 'pointer'),
                      color: (this.props.productivityView ? '#2574FB' : '#9DAECC'),
                      marginRight: 20,
                    }}
                  >
                    Productividad
                  </span>
                  <span
                    role="button"
                    onClick={() => {
                      if (this.props.productivityView) {
                        this.props.setProductivityView({ productivityView: false });
                      }
                    }}
                    style={{
                      cursor: (this.props.productivityView ? 'pointer' : 'unset'),
                      color: (this.props.productivityView ? '#9DAECC' : '#2574FB'),
                    }}
                  >
                    Compensación Variable
                  </span>
                </div> */}
                {/* Productivity & Compensation toggle - End */}
                {
                  this.props.productivityView
                  && (
                    <div className="row user-header" style={{ justifyContent: 'space-between' }}>
                      <div className="col-auto information-goal">
                        <p className="information-title">Meta de {userHeaderInfo.get('name')}:&nbsp;</p>
                        <p className="information-title-bold">{` ${sortingGoal} cajas`}</p>
                      </div>
                      <div className="col-auto">
                        <p className="light-weight" style={{ fontSize: 12 }}>{periodInWords}</p>
                      </div>
                    </div>
                  )
                }
              </>
            )
            : <LoadingSpinner height={200} />
        }
      </div>
    );
  }
}

type Props = {
  getUserHeaderInfo: typeof getUserHeaderInfo;
  setTimeFilterForPage: typeof setTimeFilterForPage;
  getUserBoxesByPresentation: typeof getUserBoxesByPresentation;
  getUserWeeklyCompensation: typeof getUserWeeklyCompensation;
  sorterSlug: string;
  dispatch: any;
  productivityView: boolean;
  setProductivityView: any;
  showEditPage: any;
  // store
  userHeaderInfo: any;
  currentFilterTime: any;
  totalCompensation: number;
  distributionCenter: DistributionCenterInfoMap;
  userWeeklyPayment: any;
};

function mapStateToProps(state: any) {
  return {
    userHeaderInfo: selectUserHeaderInfo(state),
    currentFilterTime: selectTimeFilterForLocation(state, 'userDetails'),
    totalCompensation: selectUserTotalCompensation(state),
    distributionCenter: selectDistributionCenterInfo(state),
    userWeeklyPayment: selectUserWeeklyPayment(state),
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    dispatch,
    getUserHeaderInfo,
    setTimeFilterForPage,
    getUserBoxesByPresentation,
    getUserWeeklyCompensation,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UserHeader);
