import request from '../utils/request';
import getCurrentDistCenter from '../utils/getCurrentDistCenter';
import { log } from '../utils/logger';
import { DistributionCenterInfo } from '../types/types-enums-interfaces';

export const SET_DISTRIBUTION_CENTER_INFO = 'distributionCenter/set-distribution-center-info';

export function setDistributionCenterInfo(info: DistributionCenterInfo | undefined) {
  return { type: SET_DISTRIBUTION_CENTER_INFO, info };
}

export function getDistributionCenterInformation() {
  return async (dispatch: any) => {
    dispatch(setDistributionCenterInfo(undefined));
    try {
      const url = `${getCurrentDistCenter()}/info`;
      const response = await request.get(url);
      dispatch(setDistributionCenterInfo(response));
    } catch (e) {
      log('e, getDistributionCenterInformation()', e);
    }
  };
}
