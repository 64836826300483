import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { ReactComponent as UserIcon } from '../../../../assets/icons/user.svg';
import { ReactComponent as BoxLightIcon } from '../../../../assets/icons/box-light.svg';
import { ReactComponent as UserWarningIcon } from '../../../../assets/icons/user-warning.svg';
import { ReactComponent as BoxWarningIcon } from '../../../../assets/icons/box-warning.svg';

import LoadingSpinner from '../../../../components/LoadingSpinner';
import { selectHomeSortersTotalSelection } from '../../../../selectors';

class DoubleProductivityCard extends Component<Props, any> {
  render() {
    const { sortersRanking } = this.props;
    return (
      <div className="row">
        <div className="col-sm">
          <div className="card">
            {sortersRanking
              ? (
                <div className="col">
                  <div className="row">
                    <div className="col" style={{ borderRight: '1px solid #D8E1E6' }}>
                      <p className="text-light" style={{ paddingBottom: 20 }}>Primer Lugar</p>
                      <div className="row no-gutters" style={{ paddingBottom: 15 }}>
                        <UserIcon style={{ width: 25, height: 25 }} className="card-image" />
                        <Link
                          to={`/distribution-centers/${this.props.distCenterSlug}/users/${sortersRanking.best.slug}`}
                          className="font-bold"
                        >
                          {sortersRanking.best.name}
                        </Link>
                      </div>
                      <div className="row no-gutters">
                        <BoxLightIcon style={{ width: 25, height: 25 }} className="card-image" />
                        <p>
                          <span className="font-bold">
                            {sortersRanking.best.currentAmount
                            && sortersRanking.best.currentAmount.toLocaleString()}
                          </span> cajas
                        </p>
                      </div>
                    </div>
                    <div className="col" style={{ paddingLeft: 30 }}>
                      <p className="text-light" style={{ paddingBottom: 20 }}>Último Lugar</p>
                      <div className="row no-gutters" style={{ paddingBottom: 15 }}>
                        <UserWarningIcon style={{ width: 25, height: 25 }} className="card-image" />
                        <Link
                          to={`/distribution-centers/${this.props.distCenterSlug}/users/${sortersRanking.worst.slug}`}
                          className="font-bold"
                        >
                          {sortersRanking.worst.name}
                        </Link>
                      </div>
                      <div className="row no-gutters">
                        <BoxWarningIcon style={{ width: 25, height: 25 }} className="card-image" />
                        <p>
                          <span className="font-bold">
                            {sortersRanking.worst.currentAmount
                        && sortersRanking.worst.currentAmount.toLocaleString()}
                          </span> cajas
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )
              : <LoadingSpinner height={250} />}
          </div>
        </div>
      </div>
    );
  }
}

type Props = {
  sortersRanking: any;
  distCenterSlug?: string;
};

function mapStateToProps(state: any) {
  const sortersSortedByTotalBoxes = selectHomeSortersTotalSelection(state)
  && selectHomeSortersTotalSelection(state)
    .sortBy((sorter: any) => sorter.get('totalBoxes'));
  const worstSorter = sortersSortedByTotalBoxes && sortersSortedByTotalBoxes.get(0);
  const bestSorter = sortersSortedByTotalBoxes && sortersSortedByTotalBoxes.get(
    sortersSortedByTotalBoxes.count() - 1,
  );

  if (!bestSorter || !worstSorter) {
    return {
      sortersRanking: {
        best: { name: '', currentValue: '', slug: '' },
        worst: { name: '', currentValue: '', slug: '' },
      },
    };
  }

  return {
    sortersRanking: {
      best: { name: bestSorter.get('name'), currentAmount: bestSorter.get('totalBoxes'), slug: bestSorter.get('slug') },
      worst: { name: worstSorter.get('name'), currentAmount: worstSorter.get('totalBoxes'), slug: worstSorter.get('slug') },
    },
  };
}

export default connect(mapStateToProps)(DoubleProductivityCard);
