import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ReactComponent as UserIcon } from '../../../assets/icons/user.svg';
import { selectUserName } from '../../../selectors';
import request from '../../../utils/request';
import LoadingSpinner from '../../../components/LoadingSpinner';
import InputWithLabel from '../../../components/Input/InputWithLabel';
import BasicButton from '../../../components/Button/BasicButton';

import './AccountDetail.scss';

type State = {
  current: string;
  new: string;
  newConfirm: string;
  submitting: boolean;
  errors: any;
  changePassword: boolean;
  changePasswordText: string;
};

class AccountDetail extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      current: '',
      new: '',
      newConfirm: '',
      submitting: false,
      errors: {},
      changePassword: false,
      changePasswordText: '',
    };
    this.changePassword = this.changePassword.bind(this);
    this.discardChanges = this.discardChanges.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  changePassword() {
    this.setState({ changePassword: true });
    this.setState({ changePasswordText: '' });
  }

  discardChanges() {
    this.setState({
      changePassword: false,
      current: '',
      new: '',
      newConfirm: '',
      errors: {},
    });
  }

  handleInputChange(e: any) {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  }

  async submit() {
    this.setState({ errors: {} });
    if (this.state.new.length < 7) {
      this.setState({ errors: { newPassword: { msg: 'Contraseña muy corta' } } });
      return null;
    }
    if (this.state.new !== this.state.newConfirm) {
      this.setState({ errors: { passwordConfirm: { msg: 'No es igual a contraseña' } } });
      return null;
    }
    this.setState({ submitting: true, errors: {} });
    try {
      await request.post('/api/v1/password/reset', {
        current: this.state.current,
        new: this.state.new,
      });
      this.setState({ submitting: false });
      this.setState({ changePasswordText: 'Contraseña modificada con éxito.' });
      this.discardChanges();
    } catch (error) {
      if ((typeof error.response.data) !== 'object') {
        this.setState({ errors: { message: 'Error de red' }, submitting: false });
      } else {
        this.setState({ errors: error.response.data, submitting: false });
      }
    }
  }

  render() {
    const { errors } = this.state;
    return (
      <div className="account-detail container main-content-margin card align-self-center col-md-6">
        <div className="first-section-container padding">
          <div className="user-image-container col-md-4 col-12">
            <UserIcon className="user-image" />
          </div>
          <div className="user-info-container col-md-8 col-12">
            <div className="username-container">
              <h3>{this.props.username}</h3>
              <p />
            </div>
            <div className="user-shift-container">
              {/* <label htmlFor="">Turno:</label>
            <select className="pointer" name="" id="">
              <option value="">Matutino</option>
              <option value="">Vespertino</option>
            </select> */}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12" style={{ padding: 0 }}>
              <hr />
            </div>
          </div>
        </div>
        {this.state.changePassword
          ? (
            <div className="second-section-container padding">
              <InputWithLabel
                name="current"
                type="password"
                onChange={this.handleInputChange}
                error={errors.password && errors.password.msg}
                label="Contraseña Actual"
              />
              <InputWithLabel
                name="new"
                type="password"
                placeholder="Escribe nueva contraseña"
                onChange={this.handleInputChange}
                error={errors.newPassword && errors.newPassword.msg}
                label="Nueva Contraseña"
              /><br />
              <InputWithLabel
                name="newConfirm"
                type="password"
                placeholder="Escribe nueva contraseña"
                onChange={this.handleInputChange}
                error={errors.passwordConfirm && errors.passwordConfirm.msg}
                label="Confirma la Nueva Contraseña"
              />
              <BasicButton
                text="GUARDAR"
                onClick={this.submit}
                disabled={!(this.state.new && this.state.newConfirm && this.state.current)}
                style={{ marginRight: '15px', marginTop: '15px' }}
              />
              <BasicButton
                text="DESCARTAR CAMBIOS"
                onClick={this.discardChanges}
                background="#FB8A25"
                style={{ marginTop: '15px' }}
              />
              {this.state.submitting
                ? <LoadingSpinner text="" style={{ display: 'inline', marginTop: 5, marginLeft: 36 }} />
                : null}
            </div>
          )
          : (
            <div className="second-section-container padding">
              <p>Contraseña</p>
              <div className="flex">
                <BasicButton
                  text="CAMBIAR CONTRASEÑA"
                  onClick={this.changePassword}
                  style={{ marginTop: '15px' }}
                />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={{ color: '#2574FB', marginTop: 15 }}>{this.state.changePasswordText}</p>
                </div>
              </div>
            </div>
          )}
      </div>
    );
  }
}

type Props = {
  username: string;
};

function mapStateToProps(state: any) {
  return {
    username: selectUserName(state),
  };
}

export default connect(mapStateToProps)(AccountDetail);
