import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { NavLink as Link } from 'react-router-dom';

import { ReactComponent as UserIcon } from '../../../../assets/icons/user.svg';

import LoadingSpinner from '../../../../components/LoadingSpinner';
import { getSortersPresentationTotals } from '../../../../actions/sortingDashboard.actions';
import {
  selectHomeCurrentDistCenter,
  selectSortingDashboardSortersPresentationTotals,
} from '../../../../selectors';

class SortersPresentationTable extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.createTableColumns = this.createTableColumns.bind(this);
    this.getDataForTimeFilter = this.getDataForTimeFilter.bind(this);
  }

  componentDidMount() {
    if (this.props.timeFilter) return this.getDataForTimeFilter();
    this.props.dispatch(getSortersPresentationTotals());
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.timeFilter && prevProps.timeFilter
      !== this.props.timeFilter) this.getDataForTimeFilter();
    if (prevProps.currentDistCenter !== this.props.currentDistCenter) {
      this.getDataForTimeFilter();
    }
  }

  getDataForTimeFilter() {
    this.props.dispatch(getSortersPresentationTotals(
      this.props.timeFilter.get('startDate'), this.props.timeFilter.get('endDate'),
    ));
  }

  createTableColumns(presentations: any, productUnit: string) {
    const unitTotal = productUnit === 'boxes' ? 'totalBoxes' : 'totalPallets';
    const columns = [] as any;
    // col 1: row names
    columns.push({
      Header: () => (
        <UserIcon style={{ width: 20, height: 20 }} className="card-image" />
      ),
      id: 'name',
      accessor: (dataRow: any) => ({
        name: dataRow.get('name'),
        slug: dataRow.get('slug'),
      }),
      Cell: (row: any) => (
        <Link to={
        `/distribution-centers/${this.props.distCenterSlug}/users/${row.value.slug}`
      }
        >{row.value.name}
        </Link>
      ),
      style: { fontWeight: 'bold' },
    });

    // middle columns: numbers
    presentations.forEach((_: any, i: any) => columns.push({
      Header: () => <p>{presentations.getIn([i, 'name'])}</p>,
      id: presentations.getIn([i, 'id']),
      accessor: (dataRow: any) => dataRow.getIn([unitTotal, i]),
      Cell: (row: any) => <p>{row.value && row.value.toLocaleString()}</p>,
    }));

    // totals column
    columns.push({
      Header: () => <p style={{ fontWeight: 'bold' }}>Total</p>,
      id: 'total',
      accessor: (dataRow: any) => dataRow.get(unitTotal).reduce((a: any, b: any) => a + b, 0),
      Cell: (row: any) => (
        <p style={{ textAlign: 'center' }}>
          {row.value.toLocaleString()}
        </p>
      ),
      minWidth: 150,
      style: { borderLeft: '2px dashed rgba(0, 0, 0, 0.05)' },
      headerStyle: { borderLeft: '2px dashed rgba(0, 0, 0, 0.05)' },
    });
    return columns;
  }

  render() {
    const { presentationTotals, productUnit } = this.props;
    const columns = presentationTotals
    && productUnit && this.createTableColumns(
      presentationTotals.get('presentations'), productUnit,
    );

    return (
      <div className="card sorters-presentation-table">
        {columns
          ? (
            <ReactTable
              data={presentationTotals.get('users')}
              columns={columns}
              pageSize={presentationTotals.get('users').size}
              showPagination={false}
              className="-highlight"
              NoDataComponent={() => null}
            />
          )
          : <LoadingSpinner height={400} />}
      </div>
    );
  }
}

type State = {
  tableColumns: any;
  tableData: any;
};

type Props = {
  presentationTotals: any;
  dispatch: any;
  productUnit: string;
  timeFilter: any;
  currentDistCenter: string;
  distCenterSlug: string;
};

function mapStateToProps(state: any) {
  return {
    presentationTotals: selectSortingDashboardSortersPresentationTotals(state),
    currentDistCenter: selectHomeCurrentDistCenter(state),
  };
}

export default connect(mapStateToProps)(SortersPresentationTable);
