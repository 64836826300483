import LibraryAxios, { AxiosInstance } from 'axios';
import { isProd } from '../constants';
import { log } from './logger';
import { store } from '../index';
import { deleteApiRequest, setApiRequestStatus } from '../actions/apiRequests.actions';
import { ApiRequestMethodType, RequestStatusType } from '../types/types-enums-interfaces';
import { generateRandomAlphaNumericText } from './mockDataGenerator';

class Request {
  APIUrl: string;
  authToken = '';
  axios: AxiosInstance;
  urlsRequested: { url: string; method: string }[];
  DEBOUNCE_MS = 200;
  source: any;

  constructor(APIUrl: string) {
    this.APIUrl = APIUrl;
    this.source = LibraryAxios.CancelToken.source();
    this.axios = LibraryAxios.create({
      baseURL: APIUrl,
      timeout: 100000,
      cancelToken: this.source.token,
    });
    this.urlsRequested = [];
  }

  async sendRequest(
    url: string,
    body: object,
    method: ApiRequestMethodType,
    params: object,
    configs: object = {},
  ) {
    let requestId = '';

    try {
      // debounce
      requestId = await this.debounce(url, method);

      // logging request
      let requestConfig;
      if (!isProd) {
        // to log request
        this.axios.interceptors.request.use(config => {
          // assign to variable and log variable below
          if (config.url === url) {
            requestConfig = { ...config, date: new Date().toUTCString() };
          }
          return config;
        }, (error: any) => Promise.reject(error)); // Do something with request error
      }

      this.axios.interceptors.request.use(config => (
        { ...config, ...configs }),
      (error: any) => Promise.reject(error));

      const response = await this.axios({
        method, url, data: body, params,
      });

      if (!isProd) {
        // timeout for dev mode to simulate delayed RESPONSES in production
        await Request.delay();

        /* eslint-disable no-console */
        console.groupCollapsed(`request to: ${url}`);
        console.log('Status: ', response.status);
        console.log('Request: ', requestConfig);
        console.log('Response: ', response);
        console.groupCollapsed('Response.data');
        log('', response.data);
        console.groupEnd();
        console.groupEnd();
        /* eslint-enable */
      }

      //  change request status to success
      Request.dispatchApiRequestStatus(method, requestId, 'success');
      return response.data;
    } catch (e) {
      switch (e.name) {
        case 'DEBOUNCED_REQUEST_ERROR':
          break;
        default:
          Request.dispatchApiRequestStatus(method, requestId, 'fail');
          log('request error: ', e);
      }
      // always throw back the error (insure requesters all fall inside try/catch blocks!)
      throw e;
    }
  }

  async get(url: string, queryParams: object = {}) {
    return this.sendRequest(url, {}, 'GET', queryParams);
  }

  async post(url: string, body: object, queryParams: object = {}) {
    return this.sendRequest(url, body, 'POST', queryParams);
  }

  async put(url: string, body: object, queryParams: object = {}) {
    return this.sendRequest(url, body, 'PUT', queryParams);
  }

  async patch(url: string, body: object, queryParams: object = {}) {
    return this.sendRequest(url, body, 'PATCH', queryParams);
  }

  async delete(url: string, body: object, queryParams: object = {}) {
    return this.sendRequest(url, body, 'DELETE', queryParams);
  }

  async upload(url: string, body: object, queryParams: object = {}, config = {}) {
    return this.sendRequest(url, body, 'POST', queryParams, config);
  }

  static async delay() {
    return new Promise<any>(resolve => {
      const timeOutMs = 100 + Math.round(Math.random() * 1000);
      setTimeout(() => {
        resolve();
      }, timeOutMs);
    });
  }

  async debounce(url: string, method: ApiRequestMethodType): Promise<string> {
    const nextUrlIndex = this.urlsRequested.push({ url, method });

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        for (let i = nextUrlIndex; i < this.urlsRequested.length; i++) {
          if (
            this.urlsRequested[i].url === url
            && this.urlsRequested[i].method === method
          ) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return reject({
              name: 'DEBOUNCED_REQUEST_ERROR',
              message: `Multiple Requests To Same Url Debounced. Sent Most Recent: ${url}`,
            });
          }
        }

        // give special key to identify request
        // by (can't use url since it repeats with different body)
        const requestId = generateRandomAlphaNumericText(6);
        Request.dispatchApiRequestStatus(method, requestId, 'pending');

        resolve(requestId);
      }, this.DEBOUNCE_MS);
    });
  }

  static async dispatchApiRequestStatus(method: ApiRequestMethodType,
    requestId: string, status: RequestStatusType) {
    const { dispatch } = store;
    dispatch(setApiRequestStatus(method, requestId, status));

    // delete request from store log
    if (['success', 'fail'].includes(status)) {
      const timeoutinMs = status === 'fail' ? 4500 : 750;
      setTimeout(() => {
        dispatch(deleteApiRequest(method, requestId));
      }, timeoutinMs);
    }
  }
}

const req = new Request('');

export default req;
