import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { NavLink as Link } from 'react-router-dom';
import { addDays, differenceInHours, format } from 'date-fns';
import * as esLocale from 'date-fns/locale/es';

import { ReactComponent as DownloadIcon } from '../../../../assets/icons/download.svg';

import getCurrentDistCenter from '../../../../utils/getCurrentDistCenter';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import {
  getSortersSelectionDetail,
  updateSortingGoalForHour,
} from '../../../../actions/sortingDashboard.actions';
import {
  selectHomeCurrentDistCenter,
  selectSortingDashboardSortersSelectionDetail,
  selectSortingDashboardsortingHourlyGoals,
} from '../../../../selectors';
import { formatTimeLabel, getStartOfShift } from '../../../../utils/dateHelpers';

class SortersSelectionDetail extends Component <Props, any> {
  static downloadManagersSpreadhsheet() {
    // eslint-disable-next-line no-undef
    window.location.assign(`${getCurrentDistCenter()}/workbook`);
  }
  static createTableHeader(startMs: number, endMs: number, timeUnit: string) {
    /** timeUnit: 'hour' | 'day' | 'week' | 'month' ... */
    const startDate = new Date(startMs);
    const endDate = new Date(endMs);

    const hourFormat = startDate.getMinutes() ? 'HH[:]mm' : 'H';
    const dayFormat = 'dddd D';
    const weekFormat = 'MMM D';
    const monthFormat = 'DD[/]M';

    switch (timeUnit) {
      case 'hour':
        return `${format(startDate, hourFormat, { locale: esLocale })}-${format(endDate,
          hourFormat, { locale: esLocale })}`;
      case 'day':
        const oneliner = format(startDate, dayFormat, { locale: esLocale }).split(' ');
        return (
          <div><p>{oneliner[0]}</p>
            <p
              style={{ color: '#3B76F2', fontWeight: 'bold' }}
            >{oneliner[1]}
            </p>
          </div>
        );
      case 'week':
        return `${format(startDate, weekFormat, { locale: esLocale })}-${format(endDate,
          weekFormat,
          { locale: esLocale })}`;
      case 'month':
        // when we have 1 full month
        if ((addDays(startDate, 1).getDate() === 1 || startDate.getDate() === 1)
          && (addDays(endDate, 1).getDate() === 1 || endDate.getDate() === 1)) {
          return format(addDays(startDate, 1), 'MMM', { locale: esLocale });
        }
        return `${format(startDate, monthFormat, { locale: esLocale })} - ${format(endDate,
          monthFormat, { locale: esLocale })}`;
      default:
        return `${startDate.toLocaleString()} - ${endDate.toLocaleString()}`;
    }
  }
  constructor(props: Props) {
    super(props);
    this.updateSortersSelectionDetail = this.updateSortersSelectionDetail.bind(this);
    this.updateSortingGoalForHour = this.updateSortingGoalForHour.bind(this);
    this.getTableFooter = this.getTableFooter.bind(this);
    this.getTableColumns = this.getTableColumns.bind(this);
  }

  componentDidMount() {
    if (this.props.timeFilter) return this.updateSortersSelectionDetail();
    this.props.dispatch(getSortersSelectionDetail());
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.timeFilter && prevProps.timeFilter
      !== this.props.timeFilter) this.updateSortersSelectionDetail();
    if (prevProps.currentDistCenter !== this.props.currentDistCenter) {
      this.updateSortersSelectionDetail();
    }
  }

  getTableFooter(columnIndex: number, startTimeMs?: number) {
    const { sortingInfo, sortingHourlyGoals } = this.props;

    const timeUnit = sortingInfo.get('slotsType');

    const sortTimes = sortingInfo.get('sortTimes');

    const mappedUsers = sortingInfo.get('users').map((u: any) => u.getIn(['sortBoxes', columnIndex]));

    const totalSelection = mappedUsers.toArray().reduce((a: any, b: any) => a + b, 0) || 0;

    // PI (Productivity Indicator)
    let sorterDailyGoal = sortingHourlyGoals.get(columnIndex);
    // when calendar choice is last 60 minutes
    if (timeUnit === 'hour' && sortTimes.count() < 8 && startTimeMs) {
      const shiftHourIndex = differenceInHours(new Date(startTimeMs), getStartOfShift());
      sorterDailyGoal = sortingHourlyGoals.get(shiftHourIndex);
    }
    if (Number.isNaN(sorterDailyGoal)) sorterDailyGoal = 0;

    // KPI Almacen (warehouse productivity goal for hour)
    const sorterUsers = sortingInfo.get('users').reduce((a: any, c: any) => (c.get('type') === 'Sorter' ? a + 1 : a), 0);
    const hourlyDCSortingGoal = sorterDailyGoal * (sorterUsers || 0);

    // GAP for hour
    const sortingGap = totalSelection - hourlyDCSortingGoal;
    const gapColor = sortingGap === 0 ? 'initial' : (sortingGap > 0 ? '#70A5FF' : '#EF5681');

    /* tslint:disable */
    // Line 43 causes linting errors and we can't write JS comments in JSX to disable it
    return (
      <div className="footer-values">
        {timeUnit === 'hour'
          ? (
            <div className="pi-input-container">
              <input
                type="number"
                placeholder="PI"
                className="pi-input"
                value={`${sorterDailyGoal}`}
                onChange={event => this.updateSortingGoalForHour(event, columnIndex)}
              />
            </div>
          ) : null}
        {timeUnit === 'hour' ? <div><p>{hourlyDCSortingGoal.toLocaleString()}</p></div> : null}
        <div><p>{totalSelection.toLocaleString()}</p></div>
        {timeUnit === 'hour'
          ? <div><p style={{ color: gapColor }}>{Math.abs(sortingGap).toLocaleString()}</p></div>
          : null}
      </div>
    );
    /* tslint:enable */
  }

  getTableColumns(sortingInfo: any, productUnit?: string, timeUnit?: any) {
    const columns = [];
    const unitsSortedAccessor = productUnit === 'boxes' ? 'sortBoxes' : 'sortPallets';
    // Add row names column
    columns.push({
      id: 'name',
      Header: '',
      headerClassName: 'hours-1st-header',
      accessor: (dataRow: any) => ({
        name: dataRow.get('name'),
        slug: dataRow.get('slug'),
      }),
      Cell: (row: any) => (
        <Link to={
        `/distribution-centers/${this.props.distCenterSlug}/users/${row.value.slug}`
      }
        >{row.value.name}
        </Link>
      ),
      className: 'name-column',
      minWidth: 160,
      getFooterProps: () => ({ className: 'footer-values-container' }),
      Footer: (
        <div className="footer-descriptions footer-values">
          {timeUnit === 'hour'
            ? (
              <p
                className="row-labels"
              >PI
              </p>
            ) : null}
          {timeUnit === 'hour' ? <p className="row-labels">KPI Almacén</p> : null}
          <p className="row-labels">Total Seleccionado</p>
          {timeUnit === 'hour' ? <p className="row-labels">GAP</p> : null}
        </div>
      ),
    });

    // Add hours && #-of-sorted-products columns
    const timeHeaders = formatTimeLabel(
      sortingInfo.get('sortTimes'),
      sortingInfo.get('slotsType'),
      sortingInfo.get('timeOffsetForDistCenter'),
      true,
    );
    sortingInfo.get('sortTimes').forEach((_: any, i: number) => {
      let timeHeader = timeHeaders[i];
      // style headers
      const timeUnitForSlot = sortingInfo.get('slotsType');
      if (timeUnitForSlot === 'day') {
        timeHeader = (
          <div>
            <p>{timeHeader[0]}</p>
            <p
              style={{ color: '#3B76F2', fontWeight: 'bold' }}
            >
              {timeHeader[1]}
            </p>
          </div>
        );
      }

      columns.push({
        id: sortingInfo.getIn(['sortTimes', i, 'start']),
        Header: () => <div style={{ fontSize: 15 }}>{timeHeader}</div>,
        headerClassName: (i === (sortingInfo.get('sortTimes').size - 1)
          ? 'header-before-total' : ''),
        accessor: (dataRow: any) => dataRow.getIn([unitsSortedAccessor, i]),
        Cell: (row: any) => (
          <p
            className="units-selected"
          >{row.value && row.value.toLocaleString()}
          </p>
        ),
        Footer: this.getTableFooter(i, sortingInfo.getIn(['sortTimes', i, 'start'])),
        getFooterProps: () => ({ className: 'footer-values-container' }),
      });
    });

    // Add table totals column
    columns.push({
      id: 'total',
      Header: <p className="text-light">Total</p>,
      headerClassName: 'total-header',
      accessor: (dataRow: any) => dataRow.get('sortBoxes').reduce((a: any, b: any) => a + b, 0),
      Cell: (row: any) => <p className="units-selected">{row.value.toLocaleString()}</p>,
      minWidth: 80,
      style: { borderRightWidth: '0px' },
      getFooterProps: () => ({ style: { borderRightWidth: '0px' }, className: 'footer-values-container' }),
      Footer: () => {
        const { sortingHourlyGoals } = this.props;
        let dailyTotalSelection = 0;
        let sortingGap = 0;
        let totalHourlyGoals = 0;
        let totalPI = 0;
        sortingInfo.get('sortTimes').forEach((timeSlot: any, i: number, sortTimes: any) => {
          const startTimeMs = timeSlot.get('start');

          const mappedUsers = sortingInfo.get('users').map((u: any) => u.getIn(['sortBoxes', i]));

          const totalSelection = mappedUsers.toArray().reduce((a: any, b: any) => a + b, 0);

          // PI (Productivity Indicator)
          let sorterDailyGoal = sortingHourlyGoals.get(i);
          // when calendar choice is last 60 minutes
          if (timeUnit === 'hour' && sortTimes.count() < 8 && startTimeMs) {
            const shiftHourIndex = differenceInHours(new Date(startTimeMs), getStartOfShift());
            sorterDailyGoal = sortingHourlyGoals.get(shiftHourIndex);
          }
          if (Number.isNaN(sorterDailyGoal)) sorterDailyGoal = 0;

          // KPI Almacen (warehouse productivity goal for hour)
          const sorterUsers = sortingInfo.get('users').reduce((a: any, c: any) => (c.get('type') === 'Sorter' ? a + 1 : a), 0);
          const hourlyDCSortingGoal = sorterDailyGoal * (sorterUsers || 0);
          totalHourlyGoals += hourlyDCSortingGoal;
          sortingGap += totalSelection - hourlyDCSortingGoal;
          totalPI += sorterDailyGoal;
          dailyTotalSelection += totalSelection;
        });
        return (
          <div className="footer-values">
            {timeUnit === 'hour'
              ? (
                <div className="pi-input-name-column">{totalPI.toLocaleString()}
                </div>
              ) : null}
            {timeUnit === 'hour' ? <div><p>{totalHourlyGoals.toLocaleString()}</p></div> : null}
            <div><p className="total-sorted">{dailyTotalSelection.toLocaleString()}</p></div>
            {timeUnit === 'hour' ? <div><p>{sortingGap.toLocaleString()}</p></div> : null}
          </div>
        );
      },
    });
    return columns;
  }

  updateSortersSelectionDetail() {
    const { timeFilter } = this.props;
    this.props.dispatch(getSortersSelectionDetail(timeFilter.get('startDate'),
      timeFilter.get('endDate')));
  }

  updateSortingGoalForHour(inputEvent: any, hourIndex: number) {
    this.props.dispatch(updateSortingGoalForHour(parseInt(inputEvent.target.value, 10), hourIndex));
  }

  render() {
    const { sortingInfo, sortingHourlyGoals, productUnit } = this.props;
    const timeUnit = sortingInfo && sortingInfo.get('slotsType');
    const columns = sortingInfo && this.getTableColumns(sortingInfo, productUnit, timeUnit);
    const numberOfSorters = sortingInfo && sortingInfo.get('users').count();
    const dailyGoal = sortingHourlyGoals && (
      sortingHourlyGoals.reduce((a: number, el: number) => a + el, 0) * numberOfSorters
    );

    return (
      <div className="card">
        {columns
          ? (
            <div className="sorters-table">
              <div className="row card-header-row" style={{ marginTop: 11, paddingBottom: 26 }}>
                {/* daily goal */}
                <div className="col text-light">
                  {timeUnit === 'hour'
                    ? (
                      <p>Meta del día:&nbsp;
                        <span
                          className="font-bold"
                        >
                          {dailyGoal && dailyGoal.toLocaleString()} cajas
                        </span>
                      </p>
                    )
                    : null}
                </div>
                {/* # of users */}
                <div className="col text-light">
                  <p>Seleccionadores:&nbsp;
                    <span className="font-bold">
                      {numberOfSorters}
                    </span>
                  </p>
                </div>
                {/* download */}
                <div className="col text-right text-light text-icon-container">
                  <div
                    role="button"
                    onClick={SortersSelectionDetail.downloadManagersSpreadhsheet}
                    className="clickable"
                  >
                    <DownloadIcon style={{ height: 20, width: 20 }} />
                  </div>
                  <p>Día de hoy</p>
                </div>
              </div>
              <ReactTable
                data={{ sortingInfo, sortingHourlyGoals } as any}
                resolveData={(data: any) => data.sortingInfo.get('users')}
                columns={columns}
                className="-highlight"
                pageSize={sortingInfo.get('users').count()}
                showPagination={false}
                NoDataComponent={() => null}
              />
            </div>
          )
          : <LoadingSpinner height={600} />}
      </div>
    );
  }
}

type Props = {
  dispatch: any;
  distCenterSlug: string;
  timeFilter: any;
  productUnit: string;
  // store
  sortingInfo: any;
  sortingHourlyGoals: any;
  currentDistCenter: string;
};

function mapStateToProps(state: any) {
  return {
    sortingInfo: selectSortingDashboardSortersSelectionDetail(state),
    sortingHourlyGoals: selectSortingDashboardsortingHourlyGoals(state),
    currentDistCenter: selectHomeCurrentDistCenter(state),
  };
}

export default connect(mapStateToProps)(SortersSelectionDetail);
