import debounce from 'lodash.debounce';
import request from '../utils/request';
import getCurrentDistCenter from '../utils/getCurrentDistCenter';
import { log } from '../utils/logger';
import { WorkersCompensations } from '../types/types-enums-interfaces';

export const SET_WORKERS_CONFIGURATION = 'config/set-workers-configuration';
export const SET_WORKERS_COMPENSATION = 'config/set-workers-compensation';
export const UPDATE_WORKERS_COMPENSATION = 'config/update-workers-compensation';
export const SET_WORKER_ACTIVITY = 'config/change-worker-activity';
export const DELETE_USER_FROM_WORKERS_CONFIGURATION = 'config/delete-user-from-workers-configuration';

export function setWorkersConfiguration(workers: any) {
  return { type: SET_WORKERS_CONFIGURATION, workers };
}

export function setWorkersCompensation(presentationCompensations: WorkersCompensations) {
  return { type: SET_WORKERS_COMPENSATION, presentationCompensations };
}

export function updateWorkersCompensation(presentationSlug: string, newFactorPerBox: string) {
  return {
    type: UPDATE_WORKERS_COMPENSATION,
    presentationCompensation: { presentationSlug, newFactorPerBox },
  };
}

export function setWorkerActivity(employeeNumber: string, newActivityId: string) {
  return { type: SET_WORKER_ACTIVITY, employeeNumber, newActivityId };
}

export function deleteUserFromWorkersConfiguration(userSlug: string) {
  return { type: DELETE_USER_FROM_WORKERS_CONFIGURATION, userSlug };
}

export function getWorkersConfiguration() {
  return async (dispatch: any) => {
    try {
      const url = `${getCurrentDistCenter()}/staff/sorters`;

      const response = await request.get(url);
      dispatch(setWorkersConfiguration(response));
    } catch (e) {
      log('e getWorkersConfiguration()', e.message || e);
    }
  };
}

export function getWorkersCompensation() {
  return async (dispatch: any) => {
    try {
      const url = `${getCurrentDistCenter()}/config/variable-compensation`;

      const response = await request.get(url);
      dispatch(setWorkersCompensation(response));
    } catch (e) {
      log('e getWorkersCompensation()', e.message || e);
    }
  };
}

const debouncePostWorkersCompensationRequest = debounce(
  async (dispatch: any, newFactorPerBox: string, presentationSlug: string) => {
    try {
      const url = `${getCurrentDistCenter()}/config/variable-compensation`;

      // ensuring against when they type something like "116." before figuring out the rest
      if (isNaN(+newFactorPerBox)) return;

      await request.post(url, {
        presentation: presentationSlug,
        factorPerBox: +newFactorPerBox,
      });
    } catch (e) {
      // we revert to server data in case of an issue
      if (e.name !== 'DEBOUNCED_REQUEST_ERROR') dispatch(getWorkersCompensation());
      log('e debouncePostWorkersCompensationRequest()', e.message || e);
    }
  },
  500,
);

export function postWorkersCompensation(newFactorPerBox: string, presentationSlug: string) {
  return async (dispatch: any) => {
    try {
      dispatch(updateWorkersCompensation(presentationSlug, newFactorPerBox));

      debouncePostWorkersCompensationRequest(dispatch, newFactorPerBox, presentationSlug);
    } catch (e) {
      dispatch(getWorkersCompensation());
      log('e getWorkersCompensation()', e.message || e);
    }
  };
}

export function changeWorkerActivity(employeeNumber: string,
  newActivityId: string, oldActivityId: string) {
  return async (dispatch: any) => {
    try {
      const url = `${getCurrentDistCenter()}/staff/adjust-worker-activity`;

      const body = {
        employeeId: employeeNumber,
        roleId: newActivityId,
      };

      // change locally
      dispatch(setWorkerActivity(employeeNumber, newActivityId));

      await request.post(url, body);
    } catch (e) {
      // reverse local change in case of failure
      dispatch(setWorkerActivity(employeeNumber, oldActivityId));
    }
  };
}


export function disableUser(userSlug: string) {
  return async (dispatch: any) => {
    try {
      const url = `${getCurrentDistCenter()}/users/${userSlug}/disable`;
      // change locally
      dispatch(deleteUserFromWorkersConfiguration(userSlug));

      await request.post(url, { userSlug });
    } catch (e) {
      dispatch(getWorkersConfiguration());
      log('e deleteUser()', e.message || e);
    }
  };
}
