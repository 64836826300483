import { fromJS } from 'immutable';
import { SET_ZONES_TOTALS } from '../actions/corporateAgencies.actions';

const INITIAL_STATE = fromJS({});

const corporateAgenciesReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_ZONES_TOTALS:
      return state.update('zonesTotals', () => fromJS(action.zonesTotals));
    default:
      return state;
  }
};

export default corporateAgenciesReducer;
