import React, { Component } from 'react';
import { connect } from 'react-redux';

import LoadingSpinner from '../../../../components/LoadingSpinner';
import Toggle from '../../../../components/Button/Toggle';
import HourlySelectionLineChart from './HourlySelectionLineChart';

import { getSortersSelectionDetail } from '../../../../actions/sortingDashboard.actions';
import {
  selectHomeCurrentDistCenter,
  selectSortingDashboardSortersSelectionDetail,
  selectSortingDashboardsortingHourlyGoals,
} from '../../../../selectors';

class SortersHourlySelectionChart extends Component <Props, any> {
  hourlySelectionChartContent = {
    totals: 'totals',
    perPerson: 'perPerson',
  };

  constructor(props: any) {
    super(props);
    this.state = { hourlySelectionChartContent: this.hourlySelectionChartContent.totals };
    this.updateSortersSelectionDetail = this.updateSortersSelectionDetail.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.timeFilter && prevProps.timeFilter
      !== this.props.timeFilter) this.updateSortersSelectionDetail();
    if (prevProps.currentDistCenter !== this.props.currentDistCenter) {
      this.updateSortersSelectionDetail();
    }
  }

  updateSortersSelectionDetail() {
    const { timeFilter } = this.props;
    this.props.dispatch(getSortersSelectionDetail(timeFilter.get('startDate'),
      timeFilter.get('endDate')));
  }

  render() {
    const { sortingInfo, sortingHourlyGoals, productUnit } = this.props;
    const { hourlySelectionChartContent } = this.state;
    const timeUnit = sortingInfo && sortingInfo.get('slotsType');
    const numberOfSorters = sortingInfo && sortingInfo.get('users').count();
    const dailyGoal = sortingHourlyGoals && (
      sortingHourlyGoals.reduce((a: number, el: number) => a + el, 0) * numberOfSorters
    );

    return (
      <div className="card">
        {sortingInfo
          ? (
            <div className="sorters-table">
              {/* header */}
              <div className="row card-header-row" style={{ alignItems: 'baseline' }}>
                {/* daily goal */}
                <div className="col text-light">
                  {timeUnit === 'hour'
                    ? (
                      <p>Meta del día:&nbsp;
                        <span
                          className="font-bold"
                        >
                          {dailyGoal && dailyGoal.toLocaleString()} cajas
                        </span>
                      </p>
                    )
                    : null}
                </div>
                {/* # of users */}
                <div className="col text-light">
                  <p>Seleccionadores:&nbsp;
                    <span className="font-bold">
                      {sortingInfo.get('users').size}
                    </span>
                  </p>
                </div>
                {/* all vs by-individual switch */}
                <div className="col">
                  <Toggle
                    options={[
                      { text: 'Totales', key: 'totals' },
                      { text: 'Por persona', key: 'perPerson' },
                    ]}
                    onOptionSelect={(key: string) => this.setState(
                      { hourlySelectionChartContent: key },
                    )}
                    selectedOption={this.state.hourlySelectionChartContent}
                  />
                </div>
              </div>
              <HourlySelectionLineChart
                detail={sortingInfo}
                type={hourlySelectionChartContent}
                unit={productUnit === 'boxes' ? 'cajas' : 'tarimas'}
              />
            </div>
          )
          : (
            <LoadingSpinner height={600} />
          )}
      </div>
    );
  }
}

type Props = {
  sortingInfo: any;
  sortingHourlyGoals: any;
  dispatch: any;
  timeFilter: any;
  productUnit: any;
  currentDistCenter: string;
};

function mapStateToProps(state: any) {
  return {
    sortingInfo: selectSortingDashboardSortersSelectionDetail(state),
    sortingHourlyGoals: selectSortingDashboardsortingHourlyGoals(state),
    currentDistCenter: selectHomeCurrentDistCenter(state),
  };
}

export default connect(mapStateToProps)(SortersHourlySelectionChart);
