import React, { Component } from 'react';
import { connect } from 'react-redux';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { ReactComponent as PalletIcon } from '../../../../assets/icons/pallet.svg';
import { ReactComponent as BoxIcon } from '../../../../assets/icons/box.svg';

import { getSortedProductsTimeChart } from '../../../../actions/homeDashboard.actions';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { formatTimeLabel } from '../../../../utils/dateHelpers';
import {
  selectHomeCurrentDistCenter,
  selectHomeTimeChart,
  selectTimeFilterForLocation,
} from '../../../../selectors';

class SortedProductsTimeChart extends Component<Props, State> {
  selectedProductsGraph: any;
  private maxYAxisValue = 1;

  constructor(props: any) {
    super(props);
    this.state = {
      selectedProductIndex: 0,
      fetchingData: false,
    };
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    if (this.props.sortingInfo) return this.fetchData(false);
    this.fetchData();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    // get data
    if (prevProps.timeFilter !== this.props.timeFilter) {
      this.fetchData();
    }
    if (prevProps.currentDistCenter !== this.props.currentDistCenter) {
      this.fetchData();
    }

    if (prevProps.selectedShift !== this.props.selectedShift) {
      this.fetchData();
    }

    // render (initial render+ every time we fetch data)
    if (this.props.sortingInfo && this.props.sortingInfo !== prevProps.sortingInfo) {
      return setTimeout(() => {
        this.renderChart();
      }, 0);
    }
    // update chart on change of presentation choice
    if ((this.state.selectedProductIndex !== prevState.selectedProductIndex)
    && this.props.sortingInfo) {
      this.updateChart(this.state.selectedProductIndex);
    }

    // adjusting the maximum value on the Y axis
    if (this.props.sortingInfo) {
      const maxTotalDuringPeriod = this.props.sortingInfo.get('presentations')
        .find((v: any) => v.get('name') === 'Total')
        .get('boxesByTime')
        .max();

      if (this.selectedProductsGraph && this.maxYAxisValue !== maxTotalDuringPeriod + 100) {
        this.maxYAxisValue = maxTotalDuringPeriod + 100;
        this.selectedProductsGraph.options.scales.yAxes[0].ticks.suggestedMax = this.maxYAxisValue;
        this.selectedProductsGraph.update();
      }
    }
  }

  updateSelectedProductIndex(index: number) {
    this.setState({ selectedProductIndex: index });
    this.updateChart(index);
  }

  updateChart(index: number) {
    // data
    const dataset = this.selectedProductsGraph.data.datasets[0];
    dataset.data = this.props.sortingInfo.getIn(['presentations', index, 'boxesByTime']).toArray();
    this.selectedProductsGraph.update();
  }

  fetchData(showSpinner = true) {
    if (showSpinner) this.setState({ fetchingData: true });

    this.props.dispatch(getSortedProductsTimeChart())
      .then(() => this.setState({ fetchingData: false }));
  }

  renderChart() {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    const ctx = document.getElementById('selected-products-time-chart').getContext('2d');

    const barGradient = ctx.createLinearGradient(0, 0, 0, 350);
    barGradient.addColorStop(1, '#EBF3FF');
    barGradient.addColorStop(0, '#83adf5');
    this.selectedProductsGraph = new Chart(ctx, {
      type: 'line',
      data: {
        // TODO: fix when server fixes request
        labels: formatTimeLabel(this.props.sortingInfo.get('times'),
          this.props.sortingInfo.get('slotsType'),
          this.props.sortingInfo.get('timeOffsetForDistCenter')),
        datasets: [{
          data: this.props.sortingInfo.getIn(['presentations', 0, 'boxesByTime']).toArray(),
          backgroundColor: barGradient,
          pointStyle: 'circle',
          pointRadius: 5,
          pointHoverRadius: 8,
          pointBackgroundColor: 'white',
          pointHoverBackgroundColor: 'white',
          borderColor: '#2574FB',
          borderWidth: 1,
        }],
      },
      options: {
        layout: {
          padding: {
            top: 70,
            left: 10,
          },
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [{
            maxBarThickness: 35,
            gridLines: {
              color: 'rgba(0, 0, 0, 0)',
              drawBorder: false,
            },
            ticks: {
              fontStyle: 'bold',
              fontColor: 'black',
            },
          }],
          yAxes: [{
            gridLines: {
              borderDash: [6, 8],
              color: 'rgba(230, 234, 241, 1)',
              drawBorder: false,
            },
            ticks: {
              fontColor: 'black',
              min: 0,
              suggestedMax: this.maxYAxisValue,
            },
          }],
        },
        plugins: {
          datalabels: {
            color: '#2574FB',
            anchor: 'end',
            align: 'top',
            // clamp: true,
          },
        },
        events: undefined,
      },
      plugins: [ChartDataLabels],
    });
  }

  render() {
    const { selectedProductIndex, fetchingData } = this.state;
    const { sortingInfo } = this.props;

    return (
      <div className="card selected-products-time-graph">
        {sortingInfo && !fetchingData
          ? (
            <div className="row">
              {/* sidebar */}
              <div className="col-sm-3 left-section">
                <p className="font-bold" style={{ marginBottom: 10, paddingLeft: 30 }}>
                Envase Seleccionado
                </p>
                {sortingInfo.get('presentations')
                  .map((presentation: any, i: number) => (
                    <div
                      role="button"
                      className={
                    `pointer left-section-padding ${
                      i === selectedProductIndex ? 'left-section-highlight' : ''
                    }`
                  }
                      onClick={() => this.updateSelectedProductIndex(i)}
                      key={presentation.get('name')}
                    >
                      <div className="product-title">{presentation.get('name')}</div>
                      <div
                        className={
                      `row card-section no-gutters no-margin ${
                        (sortingInfo.get('presentations').size - 1 === i)
                          ? 'card-last-section'
                          : ' '
                      }`
                    }
                        style={{ paddingBottom: '5px' }}
                      >
                        <div className="col">
                          <div className="row no-gutters">
                            <div className="col-auto">
                              <BoxIcon style={{ width: 20, height: 20 }} className="card-image" />
                            </div>
                            <div className="col-auto">
                              <p>Cajas</p>
                              <p className="font-bold">
                                {presentation.get('totalBoxes').toLocaleString()}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="row no-gutters">
                            <div className="col-auto">
                              <PalletIcon style={{ width: 20, height: 20 }} className="card-image" />
                            </div>

                            <div className="col-auto">
                              <p>Tarimas</p>
                              <p className="font-bold">
                                {presentation.get('totalPallets').toLocaleString()}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  ))}
              </div>
              {/* Chart */}
              <div className="col-sm-9 chart-container">
                {/* presentation options */}
                <div className="row">
                  <div className="col-2">
                    <p className="font-bold">cajas</p>
                  </div>
                  <div className="col-10 product-graph-options">
                    {sortingInfo
                      .get('presentations')
                      .map((presentation: any, i: number) => (
                        <button
                          type="button"
                          className={
                        `product-graph-button ${
                          (selectedProductIndex === i ? 'button-on' : 'button-off')
                        }`
                      }
                          onClick={() => this.updateSelectedProductIndex(i)}
                          key={presentation.get('name')}
                        >
                          {presentation.get('name')}
                        </button>
                      ))}
                  </div>
                </div>

                {/* Do NOT remove the maxHeight or it will keep expanding below if you begin on a
              different page then navigate to HomeDashboard */}
                <canvas id="selected-products-time-chart" height="170" style={{ maxHeight: 450 }} />
              </div>
            </div>
          )
          : <LoadingSpinner height={300} />}
      </div>
    );
  }
}

type Props = {
  dispatch: any;
  // store
  sortingInfo?: any;
  timeFilter?: any;
  currentDistCenter?: string;
  selectedShift: any;
};

type State = {
  selectedProductIndex: number;
  fetchingData: boolean;
};

function mapStateToProps(state: any) {
  return {
    sortingInfo: selectHomeTimeChart(state),
    timeFilter: selectTimeFilterForLocation(state, 'homeAndSortingDashboard'),
    currentDistCenter: selectHomeCurrentDistCenter(state),
    selectedShift: state.getIn(['dataFilter', 'selectedShift']),
  };
}

export default connect(mapStateToProps)(SortedProductsTimeChart);
