import request from '../utils/request';
import getCurrentDistCenter from '../utils/getCurrentDistCenter';

import { getWorkersConfiguration, setWorkersConfiguration } from './configuration.actions';

export default function createAppUser(userInfo: any) {
  return async (dispatch: any) => {
    const url = `${getCurrentDistCenter()}/users/app`;
    await request.post(url, userInfo);
    dispatch(setWorkersConfiguration(undefined));
    dispatch(getWorkersConfiguration());
  };
}
